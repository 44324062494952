import api from 'redux/api-common';
import { camelCaseObject } from 'utils';

const createContact = data => api
  .post('contacts', data)
  .then(response => camelCaseObject(response.data));

export default {
  createContact,
};
