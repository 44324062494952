import { css } from '@emotion/css';

export const COLORS = {
  abbey: '#545a60',
  black: '#000000',
  danube: '#7289d7',
  grayScorpion: '#5e5e5e',
  platinum: '#e7e7e7',
  silver: '#c0c0c0',
  silver2: '#c4c4c4',
  silver3: '#cdcdcd',
  silver4: '#CCCCCC',
  jellyBean: '#dc5753',
  white: '#ffffff',
  cornflowerBlue: '#2F88F1',
  grayStantas: '#9f9fa0',
  aliceBlue: '#fcfeff',
  malibu: '#74b9ff',
  athensGray: '#f5f6f8',
  athensGray2: '#a7a7a7',
  geyser: '#dee2e6',
  paleSky: '#666f86',
  alabaster: '#f8f8f8',
  bahamaBlue: '#05728f',
  tunDora: '#464646',
  dustyGray: '#989898',
  dustyGray2: '#999999',
  gallery: '#ebebeb',
  boulder: '#747474',
  doveGray: '#646464',
  blueBayOux: '#53667a',
  bayOfMany: '#2a4482',
  linkWater: '#E6EAF8',
  linkWater2: '#d3dbf2',
  spunPearl: '#afaeb3',
  mineShaft: '#282828',
  mineShaft2: '#292929',
  manatee: '#222',
  mantis: '#61C354',
  onahau: '#cae7ff',
  trout: '#555A64',
  catskillWhite: '#e2e8f0',
  silverChalice: '#a7a7a7',
  mercury: '#e1e1e1',
  dodgerBlue: '#2f88f1',
  dodgerBlue2: '#2089EE',
  cinnabar: '#e34a4a',
  wildSand: '#F4F4F4',
  wildSand2: '#F5F5F5',
  blueGrey: '#575C69',
  japaneseLaurel: '#009900',
  denim: '#1571DA',
  creamCan: '#F0C26A',
  coral: '#FF914D',
  crusta: '#F67C30',
  selago: '#E8F1FB',
  frenchPass: '#BADDFF',
  flushOrange: '#FF7F00',
  tundora: '#424242',
};

export const defaultCurrentPage = 1;

export const defaultPerPage = 20;

export const truncateMultiline = line => css`
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const messageTimeFormat = 'LT | DD/MM';

export const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
export const SUPPORTED_IMAGE_VIDEO_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'video/*'];

export const acceptedFileTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
];
