import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Switch } from 'antd';

export const SwitchForm = ({
  name, className, ...rest
}) => {
  const { control } = useFormContext();

  return (
    <div className={className}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Switch
            {...field}
            {...rest}
            checked={field.value}
          />
        )}
      />
    </div>
  );
};

export default SwitchForm;
