import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { styles } from './styles';

export const InputForm = ({
  label, labelClass, rules = {}, name, defaultValue,
  required, hidden,
  className, inputClassName, addMoreClassName = '',
  errorClassName = 'text-sm mt-2 text-red-500',
  Component = Input,
  isDisplayRequiredLabel = true, ...rest
}) => {
  const [t] = useTranslation();
  const { control } = useFormContext();
  if (hidden) { return null; }

  return (
    <div className={`input-form ${addMoreClassName} ${className || styles.inputForm}`}>
      {label && (
        <label className={labelClass || 'label-form'}>
          <span>{label}</span>
          {isDisplayRequiredLabel
            && (rules.required || required)
            && (<span className='text-red-600'>{t('common.required_text')}</span>)}
        </label>
      )}

      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (
          <>
            <Component
              className={inputClassName || 'input-field-form'}
              {...field}
              {...rest}
            />
            {error?.message && (
              <div className={errorClassName}>{error.message}</div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InputForm;
