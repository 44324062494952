import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.min.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'locales/ant/en_US';
import jaJA from 'locales/ant/ja_JP';
import { useTranslation } from 'react-i18next';
import './App.css';
import Router from './Router';
import './i18n';

const App = () => {
  const [, i18nObject] = useTranslation();

  return (
    <ConfigProvider locale={i18nObject.language === 'en' ? enUS : jaJA}>
      <BrowserRouter>
        <div className='section-container'>
          <Router />
        </div>
        <ToastContainer />
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
