import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChatSvg } from 'icons/Chat.svg';
import { ReactComponent as VideoCamSvg } from 'icons/VideoCam.svg';
import { ReactComponent as TeamSvg } from 'icons/Team.svg';

export const sidebarItems = (currentUser, organizationKey) => {
  const { organizations = [] } = currentUser;
  const items = [
    {
      page: 'Chat',
      link: `/organizations/${organizationKey || organizations[0]?.key}/chat`,
      icon: <ChatSvg />,
    },
  ];

  if (['admin', 'system_admin', 'employee'].includes(currentUser.role)) {
    items.push(
      {
        page: 'Meetings',
        link: `/organizations/${organizationKey || organizations[0]?.key}/meetings`,
        icon: <VideoCamSvg />,
      },
    );
  }

  if (['admin', 'system_admin'].includes(currentUser.role)) {
    items.push(
      {
        page: 'Users',
        link: `/organizations/${organizationKey || organizations[0]?.key}/users`,
        icon: <TeamSvg />,
      },
    );
  }
  return items;
};

export const dropdownSidebarItems = ({
  t, changeLanguage, handleLogout, newLocale,
  currentUser, page,
}) => {
  const items = [
    {
      label: <Link to='/profile'>{t('pages.profile')}</Link>,
      key: 'profile',
    },
  ];

  if (currentUser?.role === 'system_admin' && page !== 'organizations') {
    items.push(
      {
        label: <Link to='/organizations'>{t('pages.organization')}</Link>,
        key: 'organization',
      },
    );
  }

  items.push(
    {
      label: <div onClick={() => changeLanguage(newLocale)}>{t(`languages.${newLocale}`)}</div>,
      key: 'languages',
    },
  );

  items.push(
    {
      label: <div onClick={handleLogout}>{t('logout')}</div>,
      key: 'logout',
    },
  );

  return items;
};
