import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import UserAvatar from 'components/UserAvatar';

const ListView = ({
  users = [],
  handleSelectedUser,
}) => {
  const [t] = useTranslation();
  const columns = [
    {
      title: t('users.form.name'),
      dataIndex: 'name',
      key: 'name',
      width: 240,
      render: (_, user) => (
        <div className='flex items-center'>
          <UserAvatar size={40} url={user?.image} alt={user.name} />
          <span className='info-name'>{user.name}</span>
        </div>
      ),
    },
    {
      title: t('users.form.email'),
      dataIndex: 'email',
      key: 'email',
      width: 240,
    },
    {
      title: t('users.form.username'),
      dataIndex: 'username',
      key: 'username',
      width: 240,
    },
    {
      title: t('users.form.introduction'),
      dataIndex: 'introduction',
      key: 'introduction',
    },
  ];

  return (
    <div className='list-users list-view'>
      <Table
        columns={columns}
        dataSource={users}
        rowKey='id'
        pagination={false}
        onRow={user => ({
          onClick: () => handleSelectedUser(user.id),
        })}
      />
    </div>
  );
};

export default ListView;
