import React, {
  useRef, useState, useEffect, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, Input, Switch,
} from 'antd';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import debounce from 'lodash/debounce';
import EmojiPicker from 'emoji-picker-react';
import Loading from 'components/Loading';
import UserAvatar from 'components/UserAvatar';
import { useOutsideClick } from 'utils/useOutsideClick';
import { InputForm } from 'components/forms/InputForm';
import FileForm from 'components/forms/FileForm';
import confirm from 'components/Confirm';
import { toast } from 'react-toastify';
import { isPresent } from 'utils';
import { SUPPORTED_IMAGE_VIDEO_FORMATS } from 'utils/constants';
import { ReactComponent as ThreeDotsSvg } from 'icons/ThreeDots.svg';
import { ReactComponent as AttachSvg } from 'icons/Attach.svg';
import { ReactComponent as EmojiSvg } from 'icons/Emoji.svg';
import { ReactComponent as PhoneSvg } from 'icons/Phone.svg';
import { ReactComponent as SendSvg } from 'icons/Send.svg';
import { createMeeting } from 'redux/slices/meetings';
import { updateChatRoom } from 'redux/slices/ChatRooms';
import { useParams } from 'react-router-dom';
import PreviewFiles from './PreviewFiles';
import MessageList from './MessageList';
import { styles, dropdownActions } from './styles';
import { chatRoomHeaderUser } from './utils';

const schema = t => yup.object({
  body: yup.string().max(10000, t('common.error.max_length', { max: 10000 })),
}).required();

const Messages = React.memo(({
  messages = [],
  pagination,
  handleSaveMessage,
  fetching,
  chatRoom,
  handleLoadMoreMessage,
  newMessageIndex,
  handleMoveToAuto,
  canChangeAssign,
  currentUser,
  messageFuncRef,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    organizationKey,
  } = useParams();
  const previousMessageListHeight = useRef();
  const messageFormRef = useRef();
  const pickerRef = useRef();
  const messageListRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const headerUser = useMemo(() => chatRoomHeaderUser({ chatRoom, currentUser }), [chatRoom, currentUser]);

  const methods = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues: { body: '', files: null },
  });

  const {
    handleSubmit, reset, setValue, getValues,
    watch, clearErrors, formState: { isSubmitting },
  } = methods;

  const files = watch('files');
  const messageBody = watch('body');

  const resetForm = useCallback(() => {
    reset();
    const fileInput = document.querySelector(".image-main.attachment-file input[name='files']");
    if (fileInput) {
      fileInput.value = null;
    }
  }, [reset]);

  const saveMessage = useCallback(formValues => {
    if (formValues.body !== '' || formValues.files?.length > 0) {
      handleSaveMessage(formValues, chatRoom.id);
      resetForm();
    }
  }, [chatRoom.id, handleSaveMessage, resetForm]);

  const onChatKeyPress = useCallback(e => {
    if (e.which === 13 && e.shiftKey) {
      handleSubmit(saveMessage)();
      e.preventDefault();
    }
  }, [handleSubmit, saveMessage]);

  useOutsideClick(pickerRef, () => setShowPicker(false));

  const onEmojiClick = (event, emojiObject) => {
    setValue('body', getValues('body') + emojiObject.emoji);
  };

  const scrollToBottom = useCallback(() => {
    const { scrollHeight } = messageListRef.current;
    messageListRef.current?.scroll({ top: scrollHeight });
  }, []);

  messageFuncRef.current.scrollToBottom = scrollToBottom;

  useEffect(() => {
    setTimeout(() => {
      if (messageListRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = messageListRef.current;
        if ((scrollTop === 0) || (scrollHeight - (scrollTop + clientHeight) < 300)) {
          scrollToBottom();
        }
      }
    }, 300);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoom.id, newMessageIndex]);

  useEffect(() => {
    const handleScrollMessage = () => {
      const { scrollTop, scrollHeight } = messageListRef.current;

      if (scrollTop > 0 && scrollTop < 100) {
        if (pagination.currentPage < pagination.totalPages) {
          const currentScrollHeight = scrollHeight - scrollTop;
          messageListRef.current.scroll({ top: 200 });
          handleLoadMoreMessage(chatRoom.id, pagination.currentPage + 1, () => {
            messageListRef.current.scroll({ top: messageListRef.current.scrollHeight - currentScrollHeight });
          });
        }
      }
    };
    messageListRef.current?.addEventListener('scroll', handleScrollMessage);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      messageListRef.current?.removeEventListener('scroll', handleScrollMessage);
    };
  }, [handleLoadMoreMessage, chatRoom.id, pagination]);

  useEffect(() => {
    resetForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoom.id]);

  useEffect(() => {
    setTimeout(() => {
      if (isPresent(chatRoom) && messageFormRef.current) {
        previousMessageListHeight.current = messageListRef.current.clientHeight;
        const observer = new ResizeObserver(entries => {
          entries.forEach(entry => {
            if (entry.target === messageFormRef.current && messageListRef.current.scrollTop + previousMessageListHeight.current === messageListRef.current.scrollHeight) {
              setTimeout(() => {
                messageListRef.current.scroll({ top: messageListRef.current.scrollHeight });
              }, 1);
            }
            previousMessageListHeight.current = messageListRef.current?.clientHeight;
          });
        });

        observer.observe(messageFormRef.current);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent(chatRoom)]);

  const moveConversationToAuto = useCallback(async () => {
    const result = await confirm({
      title: t('chat_rooms.modals.move_to_auto.title'),
      body: <div className='description'>{t('chat_rooms.modals.move_to_auto.unassign_all_content')}</div>,
      submitText: t('chat_rooms.modals.move_to_auto.move_to_auto_btn'),
    });
    if (result) {
      handleMoveToAuto(chatRoom.id, { move_to_auto: true });
    }
  }, [chatRoom.id, handleMoveToAuto, t]);

  const onChangeFile = useCallback(e => {
    if (e.target.files.length > 0) {
      clearErrors('files');
      const incorrectFIles = [];
      const correctFiles = Array.from(e.target.files).filter(file => {
        if (SUPPORTED_IMAGE_VIDEO_FORMATS.includes(file.type) || file.type.match(/video\//)) {
          return true;
        }
        incorrectFIles.push(file);
        return false;
      });

      if (incorrectFIles.length > 0) {
        toast.error(t('chat_rooms.messages.file_allow_types'));
      }
      setValue('files', correctFiles);
    }
  }, [clearErrors, setValue, t]);

  const handleAttachedFile = () => {
    document.querySelector('.attachment-file input').click();
  };

  const removeSelectedFile = useCallback(() => {
    setValue('files', null);
  }, [setValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickCall = useCallback(debounce(() => {
    dispatch(createMeeting()).unwrap().then(data => {
      if (data.code) {
        handleSaveMessage({
          body: t('chat_rooms.messages.message_with_meeting_url', {
            url: `${process.env.REACT_APP_URL}/organizations/${organizationKey}/meetings/${data.code}`,
          }),
        }, chatRoom.id);
      }
    });
  }, 500), [t, organizationKey, chatRoom.id]);

  const handleChangeStatus = useCallback(() => {
    dispatch(updateChatRoom({
      params: {
        id: chatRoom.id,
        status: chatRoom.status === 'open' ? 'close' : 'open',
      },
      showNotice: false,
    })).unwrap().then(() => {});
  }, [chatRoom.id, chatRoom.status, dispatch]);

  const previewFileContent = useMemo(() => {
    if (!files) { return; }

    return (
      <PreviewFiles
        attachedFiles={[...files]}
        removeSelectedFile={removeSelectedFile}
      />
    );
  }, [files, removeSelectedFile]);

  return (
    <div className={styles.messages}>
      <div className='header'>
        <div className='left-header'>
          <UserAvatar
            size={40}
            url={get(headerUser, 'image')}
            alt={get(headerUser, 'name')}
          />
          <div className='name'>{get(headerUser, 'name')}</div>
        </div>
        {chatRoom.id && (
          <div className='right-header'>
            {currentUser.role !== 'customer' && (
              <button onClick={handleClickCall}>
                <PhoneSvg />
              </button>
            )}
            {canChangeAssign && chatRoom.chatType === 'manual' && (
              <Dropdown
                menu={{
                  items: [{
                    label: (
                      <div className='item-content' onClick={moveConversationToAuto}>
                        <div className='text'>{t('chat_rooms.switch_auto')}</div>
                      </div>),
                    key: 'unassign',
                  }],
                }}
                overlayClassName={dropdownActions}
                placement='bottomRight'
                trigger={['click']}
                getPopupContainer={trigger => trigger.parentNode}
              >
                <ThreeDotsSvg className='dots-icon' />
              </Dropdown>
            )}
            <div className='ml-2'>
              <Switch
                checked={chatRoom.status === 'open'}
                onChange={handleChangeStatus}
              />
            </div>
          </div>
        )}
      </div>
      <div className='message-container'>
        <div className='message-list' ref={messageListRef}>
          {fetching && <Loading className='h-14 mt-2 flex justify-center' />}
          <MessageList messages={messages} />
        </div>
        {isPresent(chatRoom) && (
          <div className='message-form' ref={messageFormRef}>
            {previewFileContent}
            <FormProvider {...methods}>
              <div className='flex'>
                <div className='input-group'>
                  <div className='emoji-picker' ref={pickerRef}>
                    {showPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
                  </div>
                  <button className='add-file-btn' onClick={handleAttachedFile}>
                    <AttachSvg />
                  </button>
                  <button className='add-emoji-btn' onClick={() => setShowPicker(val => !val)}>
                    <EmojiSvg />
                  </button>
                  <FileForm
                    multiple
                    name='files'
                    className='attachment-file'
                    accept={SUPPORTED_IMAGE_VIDEO_FORMATS}
                    onChange={onChangeFile}
                  />
                  <InputForm
                    name='body'
                    className='message-input'
                    placeholder={t('chat_rooms.placeholder_type_message')}
                    onKeyPress={onChatKeyPress}
                    Component={Input.TextArea}
                    autoSize
                  />
                </div>
                <Button
                  className='send-btn'
                  disabled={isSubmitting || (!trim(messageBody) && !files)}
                  icon={<SendSvg />}
                  onClick={handleSubmit(saveMessage)}
                />
              </div>
              <div className='mobile-chat-buttons'>
                <button className='add-file-btn' onClick={handleAttachedFile}>
                  <AttachSvg />
                </button>
                <button className='add-emoji-btn' onClick={() => setShowPicker(val => !val)}>
                  <EmojiSvg />
                </button>
                <Button
                  className='send-btn'
                  disabled={isSubmitting || (!trim(messageBody) && !files)}
                  icon={<SendSvg />}
                  onClick={handleSubmit(saveMessage)}
                />
              </div>
            </FormProvider>
          </div>
        )}
      </div>
    </div>
  );
});

export default Messages;
