import React, { useCallback } from 'react';
import { Input, Popover, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const Filter = ({
  fetchDataChatRooms, filterStatus, filterType, chatType, curentSearchTerm,
}) => {
  const [t] = useTranslation();
  const onClickHandler = ({ key }) => {
    if (key.match(/filter_status/)) {
      return fetchDataChatRooms({ filterStatus: key });
    }

    if (key.match(/filter/)) {
      return fetchDataChatRooms({ filterType: key });
    }
  };

  const onChangeHandler = useCallback(debounce(e => {
    fetchDataChatRooms({ searchTerm: e.target.value });
  }, 500), [fetchDataChatRooms]);

  return (
    <div className='filter-btn'>
      <Popover
        getPopupContainer={triggerNode => triggerNode.parentNode}
        overlayClassName='filter-popover'
        showArrow={false}
        content={(
          <div className='filter'>
            <div className='leading-[22px] text-[14px] py-[5px] px-[12px]'>
              <Input type='text' placeholder={t('chat_rooms.search')} defaultValue={curentSearchTerm} onChange={onChangeHandler} />
            </div>
            <div className='border w-full' />
            {chatType === 'manual' && (
              <>
                <div
                  className={`${filterType === 'filter_all' ? 'text-white bg-blue-500' : ''} flex items-center gap-[4px] cursor-pointer leading-[22px] text-[14px] py-[5px] px-[12px] hover:text-white hover:bg-blue-500`}
                  onClick={() => onClickHandler({ key: 'filter_all' })}
                >
                  {filterType === 'filter_all' ? (
                    <FontAwesomeIcon className='check-icon' icon={faCheck} />
                  ) : (
                    <div className='w-[14px] h-[14px]' />
                  )}
                  <div>{t('chat_rooms.filter_all')}</div>
                </div>
                <div
                  className={`${filterType === 'filter_assigned' ? 'text-white bg-blue-500' : ''} flex items-center gap-[4px] cursor-pointer leading-[22px] text-[14px] py-[5px] px-[12px] hover:text-white hover:bg-blue-500`}
                  onClick={() => onClickHandler({ key: 'filter_assigned' })}
                >
                  {filterType === 'filter_assigned' ? (
                    <FontAwesomeIcon className='check-icon' icon={faCheck} />
                  ) : (
                    <div className='w-[14px] h-[14px]' />
                  )}
                  <div>{t('chat_rooms.filter_assigned')}</div>
                </div>
                <div className='border w-full' />
              </>
            )}
            <div
              className={`${filterStatus === 'filter_status_all' ? 'text-white bg-blue-500' : ''} flex items-center gap-[4px] cursor-pointer leading-[22px] text-[14px] py-[5px] px-[12px] hover:text-white hover:bg-blue-500`}
              onClick={() => onClickHandler({ key: 'filter_status_all' })}
            >
              {filterStatus === 'filter_status_all' ? (
                <FontAwesomeIcon className='check-icon' icon={faCheck} />
              ) : (
                <div className='w-[14px] h-[14px]' />
              )}
              <div>{t('chat_rooms.filter_all')}</div>
            </div>
            <div
              className={`${filterStatus === 'filter_status_open' ? 'text-white bg-blue-500' : ''} flex items-center gap-[4px] cursor-pointer leading-[22px] text-[14px] py-[5px] px-[12px] hover:text-white hover:bg-blue-500`}
              onClick={() => onClickHandler({ key: 'filter_status_open' })}
            >
              {filterStatus === 'filter_status_open' ? (
                <FontAwesomeIcon className='check-icon' icon={faCheck} />
              ) : (
                <div className='w-[14px] h-[14px]' />
              )}
              <div>{t('chat_rooms.filter_status_open')}</div>
            </div>
            <div
              className={`${filterStatus === 'filter_status_close' ? 'text-white bg-blue-500' : ''} flex items-center gap-[4px] cursor-pointer leading-[22px] text-[14px] py-[5px] px-[12px] hover:text-white hover:bg-blue-500`}
              onClick={() => onClickHandler({ key: 'filter_status_close' })}
            >
              {filterStatus === 'filter_status_close' ? (
                <FontAwesomeIcon className='check-icon' icon={faCheck} />
              ) : (
                <div className='w-[14px] h-[14px]' />
              )}
              <div>{t('chat_rooms.filter_status_close')}</div>
            </div>
          </div>
      )}
      >
        <Space>
          <FontAwesomeIcon className='filter-icon' icon={faFilter} size='xl' />
        </Space>
      </Popover>
    </div>
  );
};

export default Filter;
