import React from 'react';

const TopHeader = () => (
  <div className='top-header'>
    <div className='header-text'>
      <div>
        <div className='group'>
          <span className='text-1'>誰もが</span>
          <span className='text-2'>繋がれる、</span>
        </div>
        <div className='group'>
          <span className='text-3'>安心チャット</span>
          <span className='text-4'>ツール。</span>
        </div>
      </div>
    </div>
  </div>
);

export default TopHeader;
