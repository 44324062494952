import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';
import { commonStyles } from 'components/styles';

export const styles = {
  index: css`
    .header-page {
      .content-header {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid ${COLORS.mercury};

        .title {
          border: none;
          margin-left: 0;
          padding: 0;
        }
  
        .content-header-right {
          display: flex;
          align-items: center;
          column-gap: 22px;
          margin-left: auto;
        }
      }
    }

    .sidebar {
      width: 56px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid ${COLORS.mercury};
      justify-content: space-between;
      position: fixed;
      left: 0;
      z-index: 1;
  
      .menu-items {
        width: 100%;
  
        .menu-item {
          width: 100%;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 25px;
          cursor: pointer;
  
          svg {
            color: ${COLORS.mineShaft};
          }
  
          &.active {
            border-left: 3px solid ${COLORS.dodgerBlue};
  
            svg {
              color: ${COLORS.dodgerBlue};
              margin-right: 3px;
            }
          }
        }
      }

      .user-menu {
        margin-bottom: 24px;
      }
    }

    .title {
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
      margin-left: 60px;
      padding: 20px;
      border-bottom: 1px solid ${COLORS.mercury};
    }

    .profile {
      margin-top: 50px;
      padding: 30px;
      width: 700px;

      .image-content {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
  
        .image-main, .image-field {
          border-radius: 50%;
        }
  
        .upload-btn {
          right: 0;
        }
      }

      .form-container {
        .profile-info-row {
          width: 100%;
          align-items: center;
          margin-bottom: 20px;

          label {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
            text-align: left;
          }

          .input-form {
            flex: 1 1 auto!important;

            input {
              width: 100%;
            }
          }
        }
      }

      .submit-btn {
        height: unset;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        text-align: center;
        padding: 10px 24px;
        border-radius: 100px;
      }

      .deactivate-btn {
        display: flex;
        color: ${COLORS.cinnabar};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        text-align: left;
        cursor: pointer;

        svg {
          margin-right: 10px;
        }
      }
    }

    ${commonStyles.navbarMobile}

    @media (max-width: 1200px) {
      .title {
        display: none;
      }
    }
  `,
  deactivateModal: css`
  .ant-modal-content {
    padding: 24px;
    margin: 10px;

    .ant-modal-header {
      text-align: center;
      margin-bottom: 20px;
    }

    .ant-modal-body {
      display: none;
    }

    .ant-modal-footer{
      display: flex;
      justify-content: center;

      .save-btn {
        margin-right: 8px;
        background-color: ${COLORS.cinnabar};
        border: none;
        min-width: 120px;
      }
      .cancel-btn {
        margin-left: 8px;
        background-color: ${COLORS.athensGray2};
        color: ${COLORS.white};
        border: none;
        min-width: 120px;
      }
    }
  }
  `,
};
