import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';

export const commonStyles = {
  navbarMobile: css`
    .navbar-mobile {
      display: none;
      height: 76px;
      border-bottom: 1px solid ${COLORS.mercury};
      background-color: ${COLORS.athensGray};

      .content-header {
        padding-top: 22px;
        padding-bottom: 22px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;

        .navbar-title {
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0px;
          text-align: left;
          margin-left: 10px;
        }

        .three-dot-icon {
          display: flex;
          align-items: center;
          column-gap: 22px;

          .dots-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }

        .back-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

        .member-info {
          display: flex;
          margin-left: 10px;

          .member-name {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
            text-align: left;
            margin: auto 20px;
          }
        }
      }

      &.profile-navbar {
        .content-header {
          .three-dot-icon {
            display: none;
          }
          .back-icon {
            display: block;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      .sidebar, .organizationbar {
        display: none;
      }

      .team-wrapper {
        width: 100%;
        height: 100%;
        margin-left: 0px;
      }

      .member-content {
        display: none;
      }

      .message-container {
        .message-form {
          width: calc(100vw - 380px);
        }
      }

      .navbar-mobile {
        display: block;
        .content-header {
          .back-icon, .member-info {
            display: none;
          }
        }
      }
    }

    @media (max-width: 767px) {
      .navbar-mobile {
        display: block;

        .content-header {
          .back-icon {
            display: block;
          }
        }

        .display-messages {
          .member-info {
            display: flex;
          }
          .three-dot-icon, .navbar-title {
            display: none;
          }
        }
      }
    }
  `,
  pagination: css`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 60px;

    .ant-pagination-item-link {
      display: flex;
      align-items: center;
    }
  `,
  loadingAllScreen: css`
    position: fixed;
    display: flex;
    z-index: 99999999;
    min-width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    background-color: rgb(245 246 250 / 50%) !important;
    height: 100vh !important;
  `,
  loading: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgb(245 246 250 / 50%) !important;
  `,
  userAvatar: css`
    width: 48px;
    height: 48px;
    border-radius: 50%;

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .icon-user {
      width: 100%;
      height: 100%;
    }
  `,
  modal: css`
    width: 740px !important;

    .ant-modal-content {
      border-radius: 12px;

      .ant-modal-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: ${COLORS.black};
      }

      .ant-modal-header, .ant-modal-body {
        border-radius: 12px 12px 0 0;
        padding: 16px 16px 0 16px;
      }

      .ant-modal-header, .ant-modal-footer {
        border-top: none;
        border-bottom: none;
      }

      .ant-modal-footer {
        padding-top: 0;
        padding: 0 16px 16px 16px;

        .save-btn, .cancel-btn, .remove-btn, .create-btn {
          min-width: 80px;
          height: 40px;
          border-radius: 100px;
        }

        .create-btn {
          color: white;
          border-color: ${COLORS.silverChalice};
          background: ${COLORS.silverChalice};
        }

        .cancel-btn {
          margin-right: 8px;
        }

        .remove-btn {
          color: white;
          border-color: ${COLORS.cinnabar};
          background: ${COLORS.cinnabar};
        }

        .ant-btn[disabled] {
          opacity: 0.8;
        }
      }
    }
  `,
};

export const teamLayout = css`
  display: flex;
  position: relative;
  height: 100%;

  .organizationbar {
    width: 56px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid ${COLORS.mercury};
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 1;

    .menu-items {
      width: 100%;
      padding: 8px 4px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .menu-item {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        color: ${COLORS.black};
        background-color: ${COLORS.grayStantas};
        border-radius: 4px;

        .name {
          text-align: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 4px;
          font-size: 12px;
        }

        img {
          width: 100%;
          height: 100%;
        }

        &.active {
          border: 2px solid ${COLORS.dodgerBlue};
        }
      }
    }
  }

  .sidebar {
    width: 56px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid ${COLORS.mercury};
    justify-content: space-between;
    position: fixed;
    left: calc((var(--level) - 1) * 56px);
    z-index: 1;

    .menu-items {
      width: 100%;

      .menu-item {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;

        svg {
          cursor: pointer;
          color: ${COLORS.mineShaft};
        }

        &.active {
          border-left: 3px solid ${COLORS.dodgerBlue};

          svg {
            color: ${COLORS.dodgerBlue};
            margin-right: 3px;
          }
        }
      }
    }
  }

  .user-menu {
    margin-bottom: 24px;
  }

  .team-wrapper {
    width: calc(100% - 56px * 2);
    margin-left: calc(56px * 2);
    position: relative;
  }

  ${commonStyles.navbarMobile}
`;

export const dropdownActions = css`
  .ant-dropdown-menu {
    border-radius: 12px;
    padding: 0;

    .ant-dropdown-menu-item {

      .ant-dropdown-menu-title-content {
        display: inline-block;
        width: 200px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: ${COLORS.mineShaft};
      }

      .ant-dropdown-menu-title-content div, a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .edit-btn {
        display: flex;
        cursor: pointer;
        color: ${COLORS.dodgerBlue};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        text-align: left;
        width: 100%;
        height: 100%;

        path {
          stroke: ${COLORS.dodgerBlue};
        }

        .text {
          margin-left: 14px;
        }
      }

      .remove-btn {
        width: fit-content;
        display: flex;
        align-items: center;
        padding-top: 6px;
        color: ${COLORS.cinnabar};
        cursor: pointer;
        width: 100%;
        height: 100%;

        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          margin-left: 14px;
        }
      }
    }
  }
`;

export const confirmationModal = css`
  .ant-modal-content {
    .ant-modal-body {
      margin-bottom: 24px;

      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${COLORS.black};
      }
    }
  }
`;

export const joinMeetingModal = css`
  .ant-modal-content .ant-modal-body {
    padding: 24px 16px;
  }
`;

export const dropdownNavBarActions = css`
  min-width: 135px;

  .ant-dropdown-menu {
    box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
    border-radius: 12px;
    padding: 0;

    .active {
      color: ${COLORS.cornflowerBlue};
    }

    .menu-item {
      padding: 18px 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-align: left;

      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;


        a {
          .text {
            margin-left: 10px;
          }
        }
      }
    }


  }
`;
