import { isMobile } from 'react-device-detect';
import background1 from 'images/virtual-background/background-1.jpg';
import background2 from 'images/virtual-background/background-2.jpg';
import background3 from 'images/virtual-background/background-3.jpg';
import background4 from 'images/virtual-background/background-4.jpg';
import background5 from 'images/virtual-background/background-5.jpg';
import background6 from 'images/virtual-background/background-6.jpg';
import background7 from 'images/virtual-background/background-7.jpg';

export const backgrounds = [
  background1,
  background2,
  background3,
  background4,
  background5,
  background6,
  background7,
];

export const connectionOptions = () => {
  const domain = process.env.REACT_APP_API_MEETING_URL;

  return {
    hosts: {
      domain,
      muc: `conference.${domain}`,
      focus: `focus.${domain}`,
    },
    websocket: `wss://${domain}/xmpp-websocket`,
    serviceUrl: `https://${domain}/http-bind`,
  };
};

export const conferenceOptions = {
  enableLayerSuspension: true,
  p2p: {
    enabled: false,
  },
};

export const bodyPixOptions = {
  architecture: 'MobileNetV1',
  outputStride: 16, // Output stride (16 or 32). 16 is faster, 32 is more accurate.
  multiplier: 0.5, // The model's depth multiplier. Options: 0.50, 0.75, or 1.0.
  quantBytes: 1, // The number of bytes to use for quantization (1, 2, 4).
};

export const virtualBackgroundOptions = {
  videoWidth: isMobile ? 320 : 550,
  videoHeight: isMobile ? 550 : 320,
};
