import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputForm } from 'components/forms/InputForm';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { commonStyles } from 'components/styles';
import { joinMeetingModal } from './styles';

const schema = t => yup.object({
  code: yup.string().required(t('common.error.required')).max(100),
});

const JoinMeetingModal = ({ toggle }) => {
  const [t] = useTranslation();
  const {
    organizationKey,
  } = useParams();
  const methods = useForm({
    resolver: yupResolver(schema(t)),
  });
  const { handleSubmit } = methods;

  const popupWindow = useCallback(({
    url, title, w = 900, h = 465,
  }) => {
    const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
    return window.open(
      url,
      title,
      `
        toolbar=no,
        location=no,
        directories=no,
        status=no,
        menubar=no,
        scrollbars=no,
        resizable=no,
        copyhistory=no,
        width=${w},
        height=${h},
        top=${y},
        left=${x}
      `,
    );
  }, []);

  const handleJoinMeeting = useCallback(values => {
    const { code } = values;
    popupWindow({ url: `/organizations/${organizationKey}/meetings/${code}`, title: 'Meeting' });
    toggle();
  }, [organizationKey, popupWindow, toggle]);

  return (
    <Modal
      title={t('meetings.modals.join_meeting.title')}
      open
      centered
      onCancel={toggle}
      className={`${commonStyles.modal} ${joinMeetingModal}`}
      footer={[
        <Button
          key='submit'
          className='save-btn'
          type='primary'
          onClick={handleSubmit(handleJoinMeeting)}
        >
          {t('meetings.modals.join_meeting.submit')}
        </Button>,
      ]}
    >
      <FormProvider {...methods}>
        <InputForm
          name='code'
          placeholder={t('meetings.modals.join_meeting.code_placeholder')}
        />
      </FormProvider>
    </Modal>
  );
};

export default JoinMeetingModal;
