import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as VideoCamSvg } from 'icons/VideoCam.svg';
import { ReactComponent as VideoCamOffSvg } from 'icons/VideoCamOff.svg';
import { ReactComponent as MicSvg } from 'icons/Mic.svg';
import { ReactComponent as MicOffSvg } from 'icons/MicOff.svg';
import { prejoinMeeting } from './styles';
import { virtualBackgroundOptions } from './config';

const { videoWidth, videoHeight } = virtualBackgroundOptions;

const PrejoinMeeting = ({
  startMeeting,
  currentUser,
  localTracks,
  initJitsi,
  handleChangeLocalTrack,
  handleEndCall,
}) => {
  const [t] = useTranslation();

  const renderPreviewVideo = useMemo(() => (
    <div className='video-wrapper'>
      {localTracks.video ? (
        <video
          ref={ref => {
            if (ref) { ref.srcObject = localTracks.video.stream; }
          }}
          autoPlay
          muted
          playsInline
          width={videoWidth}
          height={videoHeight}
        />
      ) : <UserAvatar size={180} url={currentUser?.image} alt={currentUser?.name} />}
    </div>
  ), [currentUser?.image, currentUser?.name, localTracks.video]);

  return (
    <div className={prejoinMeeting}>
      <div className='premeeting-screen'>
        <div className='left-content'>
          <div className='header-title'>{t('meetings.show.join_meeting')}</div>
          <UserAvatar size={120} url={currentUser?.image} alt={currentUser?.name} />
          <Button
            type='primary'
            disabled={!initJitsi}
            className='join-meeting-btn'
            onClick={startMeeting}
          >
            {t('meetings.show.join_meeting_button')}
          </Button>
          <div className='group-btns'>
            <button className='settings-button' onClick={() => handleChangeLocalTrack('audio', !localTracks.audio)}>
              {localTracks.audio ? <MicSvg /> : <MicOffSvg />}
            </button>
            <button className='settings-button' onClick={() => handleChangeLocalTrack('video', !localTracks.video)}>
              {localTracks.video ? <VideoCamSvg /> : <VideoCamOffSvg />}
            </button>
            <button className='settings-button' onClick={() => handleEndCall('end_call')}>
              <FontAwesomeIcon icon={faSignOut} />
            </button>
          </div>
        </div>
        <div className='right-content'>
          {renderPreviewVideo}
        </div>
      </div>
    </div>
  );
};

export default PrejoinMeeting;
