import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { InputForm } from 'components/forms/InputForm';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { resetPassword } from 'redux/slices/auth';
import { styles } from './styles';

const schema = t => yup.object({
  password: yup.string().required(t('common.error.required'))
    .min(6, t('common.error.min_length', { min: 6 }))
    .max(100, t('common.error.max_length', { max: 100 })),
  confirmPassword: yup.string()
    .required(t('common.error.required'))
    .oneOf([yup.ref('password'), null], t('common.error.password_must_match'))
    .min(6, t('common.error.min_length', { min: 6 }))
    .max(100, t('common.error.max_length', { max: 100 })),
}).required();

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  });
  const { handleSubmit, formState: { isSubmitting } } = methods;

  useEffect(() => {
    const { token: tokenStr } = queryString.parse(location.search);
    if (tokenStr) {
      setToken(tokenStr);
    }
    // remove token from url to prevent http referer leakage
    // history.replace(location.pathname);
  }, []);
  const handleResetPassword = useCallback(({ password }) => {
    dispatch(resetPassword({ token, password }))
      .unwrap()
      .then(() => {
        toast.success('Password reset successful, you can now login');
        navigate('/login');
      });
  }, [dispatch, navigate, token]);

  return (
    <div className={styles.login}>
      {isSubmitting && (<LoadingAllScreen />)}
      <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full max-w-md space-y-8'>
          <div>
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              {t('forgot.reset_password')}
            </h2>
          </div>
          <FormProvider {...methods}>
            <div className='mt-8 space-y-6'>
              <input type='hidden' name='remember' defaultValue='true' />
              <div className='-space-y-px rounded-md shadow-sm'>
                <div className='mb-4'>
                  <InputForm
                    label={t('forgot.form.password')}
                    name='password'
                    type='password'
                    className='input-field'
                  />
                </div>
                <div className='mb-4'>
                  <InputForm
                    label={t('forgot.form.confirm_password')}
                    name='confirmPassword'
                    type='password'
                    className='input-field'
                  />
                </div>
              </div>
              <div className='flex items-center justify-between gap-x-2'>
                <button
                  type='submit'
                  className='submit-btn ant-btn ant-btn-primary'
                  disabled={isSubmitting}
                  onClick={handleSubmit(handleResetPassword)}
                >
                  {t('forgot.reset_password')}
                </button>
                <Link
                  to='/login'
                  className='cancel-btn'
                >
                  {t('common.actions.cancel')}
                </Link>
              </div>
            </div>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
