import { useEffect, useRef } from 'react';

export const useOutsideClick = (ref, callback) => {
  const control = useRef({ stop: false });

  useEffect(() => {
    const handleClickOutside = event => {
      if (!control.current.stop) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback && callback();
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
  return control;
};
