import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { isBlank } from 'utils';
import { ReactComponent as ThreeDotsSvg } from 'icons/ThreeDots.svg';
import { ReactComponent as CloseSvg } from 'icons/CloseBold.svg';
import UserAvatar from 'components/UserAvatar';
import ModalScheduleMeeting from 'components/ModalScheduleMeeting';
import confirm from 'components/Confirm';
import { deleteMeeting } from 'redux/slices/meetings';
import { formatStartTime, timezoneConverter } from './utils';

const MeetingDetails = ({ meeting = {}, submit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editing, setEdit] = useState();

  const openEdit = useCallback(item => {
    const {
      id, name, passcode, isPublic, hostVideoOn, participantVideoOn, startTime, endTime,
    } = item;
    setEdit({
      id,
      name,
      passcode,
      date: timezoneConverter(startTime),
      start_time: timezoneConverter(startTime),
      end_time: timezoneConverter(endTime),
      timezone: timezoneConverter(startTime).format('ZZ'),
      old_timezone: timezoneConverter(startTime).format('ZZ'),
      waiting_room: !isPublic,
      host_video_on: hostVideoOn,
      participant_video_on: participantVideoOn,
      repeat_type: 'no_repeat',
    });
  }, []);

  const handleSave = (formValues => {
    submit(formValues, () => {
      setEdit();
    });
  });

  const handleDelete = useCallback(async () => {
    const result = await confirm({
      title: t('common.modal.delete_confirm_title'),
      submitText: t('common.modal.delete'),
      submitType: 'danger',
      cancelText: t('common.modal.cancel'),
    });
    if (result) {
      dispatch(deleteMeeting({ params: { id: meeting.id } }));
    }
  }, [dispatch, meeting.id, t]);

  return (
    <div className='meeting-details'>
      {!isBlank(meeting) && (
        <>
          <div className='header'>
            <div className='left-header'>
              <div className='meeting-name'>{meeting.name}</div>
              <div className='datetime'>
                {`${formatStartTime(meeting.startTime, 'ddd, MMM, DD, HH:mm')}-${formatStartTime(meeting.endTime, 'HH:mm')}`}
              </div>
            </div>
            <div className='right-header' onClick={() => openEdit(meeting)}>
              <ThreeDotsSvg className='dots-icon' />
            </div>
          </div>
          <div className='meeting-info'>
            <div className='label'>{t('meetings.host')}</div>
            <div className='user-group'>
              <UserAvatar size={32} url={meeting.user?.image} alt={meeting.user?.name} />
              <div className='description'>{meeting.user?.name}</div>
            </div>
            <div className='label'>{t('meetings.code')}</div>
            <div className='description'>{meeting.code}</div>
            <div className='label'>{t('meetings.url')}</div>
            <div className='description'>
              <Link className='user-profile' to={meeting.code}>
                {`${location.href}/${meeting.code}` }
              </Link>
            </div>
            <Link className='user-profile' to={meeting.code}>
              <Button type='primary' className='join-btn'>{t('meetings.join')}</Button>
            </Link>
            <div className='remove-btn' onClick={handleDelete}>
              <CloseSvg />
              <div className='text'>{t('meetings.delete_button')}</div>
            </div>
          </div>
          {editing && (
            <ModalScheduleMeeting
              toggle={() => setEdit()}
              submit={handleSave}
              defaultValues={editing}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MeetingDetails;
