import React, { useState, useCallback, useMemo } from 'react';
import { Button, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser } from 'redux/slices/users';
import { commonStyles } from 'components/styles';
import { removeUserModal } from './styles';

const RemoveUserModal = ({
  user: { id, username },
  handleRemoveDone,
  closeModal,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { submitting } = useSelector(state => state.users);
  const [step, setStep] = useState(1);
  const [confirmUsername, setConfirmUsername] = useState(false);

  const handleRemoveUser = useCallback(() => {
    if (step === 1) {
      setStep(2);
      return;
    }

    dispatch(deleteUser({ id }))
      .unwrap()
      .then(result => {
        if (result?.success) {
          setStep(3);
        } else {
          toast.error(result?.message);
        }
      });
  }, [dispatch, id, step]);

  const actions = useMemo(() => {
    if (step === 3) {
      return (
        <Button key='done' onClick={handleRemoveDone} className='cancel-btn'>
          {t('common.actions.done')}
        </Button>
      );
    }
    return (
      <>
        <Button
          key='submit'
          loading={submitting}
          onClick={handleRemoveUser}
          className='remove-btn'
          disabled={step === 2 && (confirmUsername !== username)}
        >
          {t('common.actions.remove')}
        </Button>
        <Button key='cancel' onClick={closeModal} className='cancel-btn'>
          {t('common.actions.cancel')}
        </Button>
      </>
    );
  }, [step, closeModal, t, submitting, handleRemoveUser, confirmUsername, username, handleRemoveDone]);

  const content = useMemo(() => {
    switch (step) {
      case 1: {
        return <div>{t('users.modals.remove_user.content_step1')}</div>;
      }
      case 2:
        return (
          <>
            <div dangerouslySetInnerHTML={{
              __html: t('users.modals.remove_user.content_step2', { username }),
            }}
            />
            <Input
              className='confirm-name-input'
              placeholder={t('users.modals.remove_user.placeholder')}
              onChange={e => setConfirmUsername(e.target.value)}
            />
          </>
        );
      case 3: {
        return <div>{t('users.modals.remove_user.content_step3')}</div>;
      }
    }
  }, [username, step, t]);

  return (
    <Modal
      open
      centered
      title={step === 3 ? '' : t('users.modals.remove_user.title')}
      onCancel={closeModal}
      className={`${commonStyles.modal} ${removeUserModal}`}
      footer={actions}
    >
      <div className='content'>{content}</div>
    </Modal>
  );
};

export default RemoveUserModal;
