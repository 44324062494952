import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { toast } from 'react-toastify';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { connectionOptions, conferenceOptions } from './config';

const ScreenShare = ({
  code, JitsiMeetJS, currentUser, isSharing, cancelSharing, handleDisplayScreenShare,
}) => {
  const [isJoined, setJoined] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const localTracks = useRef([]);
  const connection = useRef(null);
  const room = useRef(null);

  const handleUnload = useCallback(() => {
    cancelSharing();
    setLoading(false);
    if (!connection.current) return;
    localTracks.current?.forEach(trackEl => {
      trackEl.track.stop();
      trackEl.dispose();
    });
    localTracks.current = [];
    room.current?.leave();
    connection.current?.disconnect();
    connection.current = null;
    room.current = null;
  }, [localTracks, cancelSharing]);

  const handleTrackAdd = track => {
    if (track.isLocal()) {
      const participantId = track.getParticipantId();
      handleDisplayScreenShare(participantId);
    }
  };

  const onConferenceJoined = () => {
    localTracks.current.forEach(track => {
      track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, handleUnload);
      room.current.addTrack(track);
    });
    setLoading(false);
    toast.success('Your screen is being observed');
  };

  const onConnectionSuccess = () => {
    room.current = connection.current.initJitsiConference(code, conferenceOptions);
    room.current.setDisplayName(JSON.stringify({ ...currentUser, isSharing: true }));
    room.current.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
    room.current.on(JitsiMeetJS.events.conference.TRACK_ADDED, handleTrackAdd);
    room.current.join();
    room.current.setReceiverVideoConstraint(720);
    setJoined(true);
  };

  const startShare = () => {
    connection.current = new JitsiMeetJS.JitsiConnection(null, null, connectionOptions());
    connection.current.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      onConnectionSuccess,
    );
    connection.current.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      handleUnload,
    );
    connection.current.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      () => {
        toast.error('An error has occurred when screen sharing!');
        handleUnload();
      },
    );
    connection.current.connect();
  };

  const createScreenShare = async () => {
    await JitsiMeetJS.createLocalTracks({
      devices: ['desktop'],
    })
      .then(tracks => {
        if (tracks.length >= 1) {
          setLoading(true);
          localTracks.current = tracks;
          startShare();
        }
      })
      .catch(() => {
        handleUnload();
      });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    if (isSharing) {
      createScreenShare();
    } else if (isJoined) {
      handleUnload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSharing]);

  return <div>{isLoading && <LoadingAllScreen />}</div>;
};

export default ScreenShare;
