import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Dropdown } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThreeDotsSvg } from 'icons/ThreeDots.svg';
import { ReactComponent as ChevronLeftSvg } from 'icons/ChevronLeft.svg';
import UserAvatar from 'components/UserAvatar';
import { logout } from 'redux/slices/auth';
import { chatRoomHeaderUser } from 'pages/chat/utils';
import { dropdownNavBarActions } from './styles';

const NavBarMobile = ({
  currentChatRoom,
  swichToMessages,
  setSwitchToMessages,
  page,
}) => {
  const [t] = useTranslation();
  const { currentUser } = useSelector(state => state.auth);
  const {
    organizationKey,
  } = useParams();
  const dispatch = useDispatch();
  const headerUser = useMemo(() => chatRoomHeaderUser({ chatRoom: currentChatRoom, currentUser }), [currentChatRoom, currentUser]);
  const handleLogout = () => {
    dispatch(logout()).unwrap();
  };

  return (
    <div className={`navbar-mobile ${page}-navbar`}>
      <div className={classnames('content-header', { 'display-messages': swichToMessages })}>
        {page === 'profile' ? (
          <>
            <Link className='user-profile' to={currentUser.role === 'system_admin' ? '/organizations' : `/organizations/${organizationKey}/chat`}>
              <ChevronLeftSvg className='back-icon' />
            </Link>
            <span className='navbar-title'>{t('pages.profile')}</span>
          </>
        ) : (
          <>
            {swichToMessages && <ChevronLeftSvg className='back-icon' onClick={() => setSwitchToMessages(false)} />}
            <span className='navbar-title'>{t('chat_rooms.header_title')}</span>
          </>
        )}

        {headerUser && (
          <div className='member-info'>
            <UserAvatar size={40} url={headerUser.image} alt={headerUser.name} />
            <span className='member-name'>
              {headerUser.name}
            </span>
          </div>
        )}

        <div className='three-dot-icon ml-auto'>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Link to={`/organizations/${organizationKey}/chat`}>
                      <span className='text'>
                        {t('pages.chat')}
                      </span>
                    </Link>
                  ),
                  key: 'chat',
                  className: classnames('menu-item', { active: page === 'chat' }),
                },
                {
                  label: (
                    <Link to='/profile'>
                      <span className='text'>
                        {t('pages.profile')}
                      </span>
                    </Link>
                  ),
                  key: 'profile',
                  className: classnames('menu-item', { active: page === 'profile' }),
                },
                {
                  label: <div className='menu-item text' onClick={handleLogout}>{t('logout')}</div>,
                  key: 'logout',
                },
              ],
            }}
            overlayClassName={dropdownNavBarActions}
            placement='bottomRight'
            trigger={['click']}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <ThreeDotsSvg className='dots-icon' />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default NavBarMobile;
