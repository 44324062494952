import React, { useRef, useEffect } from 'react';
import { Controller, get as getError, useFormContext } from 'react-hook-form';
import { acceptedFileTypes } from 'utils/constants';
import { ReactComponent as PenToSquareSvg } from 'icons/PenToSquare.svg';
import { ReactComponent as PlusSvg } from 'icons/Plus.svg';
import { isBlank } from 'utils';
import { styles } from './styles';

const FileForm = ({
  name,
  imageSrc,
  className,
  btnUploadClassName,
  fileTypesAccept = acceptedFileTypes,
  errorClassName = 'text-red-600',
  ...rest
}) => {
  const {
    control, formState: { errors }, watch,
  } = useFormContext();
  const error = getError(errors, name);
  const currentFiles = watch(name);

  const fileInputRef = useRef();

  useEffect(() => {
    if (fileInputRef.current && isBlank(currentFiles)) {
      fileInputRef.current.value = null;
      fileInputRef.current.files = new DataTransfer().files;
    }
  }, [currentFiles]);

  return (
    <div className={`image-main ${className || styles.fileForm}`}>
      {imageSrc && <img className='image-field' src={imageSrc} width={120} height='auto' alt='upload' />}
      <Controller
        control={control}
        name={name}
        type='file'
        render={({ field }) => (
          <input
            type='file'
            className='hidden'
            ref={fileInputRef}
            accept={fileTypesAccept.join(',')}
            onChange={e => field.onChange(e.target.files)}
            {...rest}
          />
        )}
      />
      <div className={btnUploadClassName || 'upload-btn'} onClick={() => fileInputRef.current.click()}>
        {imageSrc ? <PenToSquareSvg /> : <PlusSvg />}
      </div>
      {error?.message && (
        <div className={errorClassName}>{error.message}</div>
      )}
    </div>
  );
};

export default FileForm;
