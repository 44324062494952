import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as bodyPix from '@tensorflow-models/body-pix';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { useTranslation } from 'react-i18next';
import defaultAvatar from 'images/avatar.png';
import { getImageSrc } from 'utils/fileHelper';
import { isBlank } from 'utils';
import confirm from 'components/Confirm';
import { addMessage } from 'redux/slices/meetings';
import { ReactComponent as MicDanger } from 'icons/MicDanger.svg';
import { useParams } from 'react-router-dom';
import ScreenShare from './ScreenShare';
import Toolbox from './Toolbox';
import ParticipantList from './ParticipantList';
import BackgroundPane from './BackgroundPane';
import Chat from './Chat';
import {
  connectionOptions, conferenceOptions, bodyPixOptions, virtualBackgroundOptions,
} from './config';
import { toggleLargeVideo, isDefaultTurnOnVideo } from './utils';
import PrejoinMeeting from './PrejoinMeeting';
import { styles } from './styles';

const { videoWidth, videoHeight } = virtualBackgroundOptions;

const JitsiComponent = ({
  currentUser,
  meeting: { code }, meeting,
  channelRef, receivedMessageRef,
  receivedRequestJoiningRef,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const {
    organizationKey,
  } = useParams();
  const { JitsiMeetJS } = window;
  const [isJoined, setJoined] = useState(false);
  const [videoLocalLoaded, setVideoLocalLoaded] = useState(false);
  const [localTracks, setLocalTracks] = useState({});
  const [participants, setParticipants] = useState([]);
  const [participantRequestList, setParticipantRequestList] = useState([]);
  const waitingUserIdRef = useRef([]);
  const videoConferenceContent = useRef(null);
  const connection = useRef(null);
  const room = useRef(null);
  const bodyPixNet = useRef(null);
  const localTracksRef = useRef(localTracks);

  const initialState = {
    isSharing: false,
    isLoading: false,
    initJitsi: false,
    modal: null,
    unreadCount: 0,
  };

  const [state, setState] = useState(initialState);
  const {
    isLoading, isSharing, initJitsi, modal, unreadCount,
  } = state;

  useEffect(() => {
    localTracksRef.current = localTracks;
  }, [localTracks]);

  receivedMessageRef.current = data => {
    if (code === data?.code) {
      const { message } = data;
      dispatch(addMessage(message));

      setState(prevState => {
        // Read messages when you open chat
        if (prevState.modal === 'chat') {
          return ({ ...prevState, unreadCount: 0 });
        }

        // not the current user sending the message
        if (currentUser.id !== message?.user.id) {
          return ({ ...prevState, unreadCount: prevState.unreadCount + 1 });
        }
        return prevState;
      });
    }
  };

  const handleApproveJoinning = useCallback((participant, isAccept) => {
    channelRef.current.send({
      code,
      command_type: isAccept ? 'approve_joining' : 'reject_joining',
      user: participant,
    });
    setParticipantRequestList(prevParticipant => prevParticipant.filter(user => user.id !== participant.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelRef, code]);

  receivedRequestJoiningRef.current = async data => {
    const userInfo = data.user;
    const existRequest = participantRequestList.find(participant => participant.id === userInfo.id);
    if (!existRequest) {
      setParticipantRequestList(prevParticipant => [...prevParticipant, userInfo]);
    }

    if (!waitingUserIdRef.current.includes(userInfo.id)) {
      waitingUserIdRef.current.push(userInfo.id);

      await confirm({
        title: t('meetings.show.modals.approve.title', { name: userInfo.name }),
        submitText: t('meetings.show.modals.approve.admit'),
        cancelText: t('meetings.show.modals.approve.view'),
        centered: false,
        closable: true,
        submitAction: () => {
          handleApproveJoinning(userInfo, true);
          waitingUserIdRef.current = waitingUserIdRef.current.filter(id => id !== userInfo.id);
        },
        cancelAction: () => {
          setState(prevState => ({ ...prevState, modal: 'participant' }));
        },
      });
    }
  };

  const displayLocalVideo = (track, isPlay) => {
    const localVideo = document.getElementById('localVideo');
    localVideo.hidden = !isPlay;

    if (!track?.stream) { return; }
    if (isPlay) {
      track.attach(localVideo);
    } else {
      !track.hasBackground && track.detach(localVideo);
    }
  };

  const removeLocalTrack = useCallback(type => {
    localTracks[type]?.dispose();
    if (room.current) {
      room.current.removeTrack(localTracks[type]);

      if (type === 'video') {
        displayLocalVideo(localTracks[type], false);
      }
    }
    setLocalTracks(prevTracks => ({ ...prevTracks, [type]: null }));
  }, [localTracks]);

  const onLocalTracks = useCallback(async devices => {
    // devices: ['video', 'audio']
    if (isBlank(devices)) {
      return;
    }
    await JitsiMeetJS.createLocalTracks({ devices })
      .then(tracks => {
        setLocalTracks(preState => {
          const newLocalTracks = {
            ...preState,
            ...tracks.reduce((result, track) => {
              result[track.getType()] = track;
              return result;
            }, {}),
          };

          localTracksRef.current = newLocalTracks;
          return newLocalTracks;
        });

        tracks.forEach(track => {
          if (room.current) {
            const audioTrack = room.current?.getLocalAudioTrack();
            const videoTrack = room.current?.getLocalVideoTrack();

            // add local tracks to Conference
            if (track.getType() === 'audio' && audioTrack === undefined) {
              room.current?.addTrack(track);
            }

            if (track.getType() === 'video') {
              setVideoLocalLoaded(false);
              displayLocalVideo(track, true);
              if (videoTrack === undefined) { room.current?.addTrack(track); }
            }
          }
        });
      })
      .catch(error => {
        if (error.name === 'gum.permission_denied') {
          const [typeDenied] = error.gum.devices;
          toast.error(t(`meetings.show.blocked_${typeDenied}`));
        } else if (
          (error.name === 'gum.general' && error.message === 'Could not start video source')
          || (error.name === 'gum.not_found' && error.message === 'Requested device(s) was/were not found: video')
        ) {
          toast.error(t('meetings.show.missing_video'));
        } else {
          throw error;
        }
      });
  }, [JitsiMeetJS, t]);

  const handleChangeLocalTrack = useCallback(debounce(async (type, isPlay) => {
    if (isPlay) {
      await onLocalTracks([type]);
    } else {
      await removeLocalTrack(type);
    }
  }, 250), [onLocalTracks, removeLocalTrack]);

  const onChangeRemoteTrack = (participantId, track) => {
    const participantElement = document.getElementById(`participant_${participantId}`);
    if (!participantElement) return;

    const remoteVideo = document.getElementById(`remoteVideo_${participantId}`);
    const remoteAudio = document.getElementById(`remoteAudio_${participantId}`);
    const remoteAvatar = document.getElementById(`remoteAvatar_${participantId}`);

    if (track.getType() === 'video') {
      if (track.isMuted()) {
        remoteAvatar.hidden = false;
        remoteVideo.hidden = true;
        track.detach(remoteVideo);
      } else {
        remoteAvatar.hidden = true;
        remoteVideo.hidden = false;
        track.attach(remoteVideo);
      }
    }

    if (track.getType() === 'audio') {
      if (track.isMuted()) {
        track.detach(remoteAudio);
      } else {
        track.attach(remoteAudio);
      }
    }
  };

  const onRemoteTrack = track => {
    if (track.isLocal()) {
      return;
    }
    const participantId = track.getParticipantId();
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
      audioLevel => console.warn(`TRACK AUDIO LEVEL CHANGED: ${audioLevel}`),
    );
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
      onTrack => onChangeRemoteTrack(participantId, onTrack),
    );
    track.addEventListener(
      JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
      () => console.warn('LOCAL TRACK STOPPED'),
    );
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
      deviceId => console.warn(
        `TRACK AUDIO OUTPUT CHANGED:  ${deviceId}`,
      ),
    );
    onChangeRemoteTrack(participantId, track);
  };

  const onUserJoined = useCallback((participantId, participant) => {
    const videoConferencePage = document.getElementById('videoConferencePage');
    const hasClassLargeVideo = videoConferencePage.classList?.contains('large-video');
    const participantInfo = JSON.parse(participant._displayName);
    setParticipants(prevParticipant => [...prevParticipant, { ...participantInfo, participantId }]);

    const elementId = `participant_${participantId}`;
    const participantHtml = `
        <div id='${elementId}' class='video-container ${hasClassLargeVideo && 'small-video'}'>
          <div class='text-name'>${participantInfo.name}</div>
          <img
            id='remoteAvatar_${participantId}'
            class='participant-avatar'
            src='${getImageSrc(participantInfo?.image, defaultAvatar)}'
            width=300 height='auto'
            alt='${participantInfo.name}'
          />
          <video id='remoteVideo_${participantId}' autoplay='1' playsinline hidden></video>
          <audio id='remoteAudio_${participantId}' autoplay='1'></audio>
        </div>
      `;
    if (participantInfo.isSharing) {
      videoConferenceContent.current?.insertAdjacentHTML('afterbegin', participantHtml);
    } else {
      videoConferenceContent.current?.insertAdjacentHTML('beforeend', participantHtml);
    }
    if (hasClassLargeVideo) {
      document.querySelector('.video-container.large-video')?.classList?.remove('full-width');
    }
  }, []);

  const handleDisplayScreenShare = useCallback(id => {
    toggleLargeVideo(`participant_${id}`);
  }, []);

  const onUserLeft = useCallback((participantId, participant) => {
    const participantInfo = JSON.parse(participant._displayName);
    if (participantInfo.id === currentUser.id) {
      return;
    }
    if (participantInfo.isSharing) {
      toast.success(t('meetings.show.stop_sharing'));
    } else {
      toast.success(t('meetings.show.left_meeting', { name: participantInfo?.name }));
    }
    const participantElement = document.getElementById(`participant_${participantId}`);
    participantElement?.remove();
    setParticipants(prevParticipant => {
      const data = prevParticipant.filter(p => p.participantId !== participantId);
      return data;
    });

    setTimeout(() => {
      if (document.querySelectorAll('.video-container').length === 1) {
        document.querySelector('.video-container.large-video')?.classList?.add('full-width');
      }
    }, 100);
  }, [currentUser.id, t]);

  const onConferenceJoined = useCallback(() => {
    // add local tracks to Conference when joined
    if (localTracksRef.current.audio) {
      room.current.addTrack(localTracksRef.current.audio);
    }
    if (localTracksRef.current.video) {
      room.current.addTrack(localTracksRef.current.video);
      displayLocalVideo(localTracksRef.current.video, true);
    }
  }, []);

  const onConnectionSuccess = () => {
    setJoined(true);
    room.current = connection.current.initJitsiConference(code, conferenceOptions);
    room.current.setDisplayName(JSON.stringify(currentUser));
    room.current.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
    room.current.on(JitsiMeetJS.events.conference.TRACK_REMOVED, track => {
      console.warn(`TRACK REMOVED: ${track}`);
    });
    room.current.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
      console.warn(`TRACK MUTE CHANGED: ${track.getType()} - ${track.isMuted()}`);
    });
    room.current.on(
      JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
      (userID, audioLevel) => console.warn(`TRACK AUDIO LEVEL CHANGED: ${userID} - ${audioLevel}`),
    );

    room.current.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
    room.current.on(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
    room.current.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
    room.current.join();
    room.current.setReceiverVideoConstraint(720);
    setState(prevState => ({ ...prevState, isLoading: false }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleUnload = useCallback((redirect = '') => {
    if (room.current?.room) {
      localTracks.audio?.dispose();
      localTracks.video?.dispose();
      room.current?.leave();
      connection.current?.disconnect();
    }
    setState(prevState => ({ ...prevState, isSharing: false }));
    setJoined(false);
    if (redirect === 'end_call') {
      window.location.href = `/organizations/${organizationKey}/meetings`;
    } else if (window.location.href !== `/organizations/${organizationKey}/meetings/${code}`) {
      window.location.reload();
    }
  }, [code, localTracks, organizationKey]);

  const handleVideoLocalLoaded = () => {
    setVideoLocalLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);

  const startMeeting = () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    connection.current = new JitsiMeetJS.JitsiConnection(null, null, connectionOptions());
    connection.current.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      onConnectionSuccess,
    );
    connection.current.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      handleUnload,
    );
    connection.current.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      () => {
        setState(prevState => ({ ...prevState, isLoading: false }));
        toast.error('Connection failed!');
      },
    );
    connection.current.connect();
  };

  const loadLibrary = useCallback(async () => {
    bodyPixNet.current = await bodyPix.load(bodyPixOptions);
  }, []);

  useEffect(() => {
    if (!initJitsi && JitsiMeetJS) {
      JitsiMeetJS.init();
      loadLibrary();
      setState(prevState => ({ ...prevState, initJitsi: true }));
      setLocalTracks({});
    }
  }, [JitsiMeetJS, initJitsi, loadLibrary]);

  useEffect(() => {
    if (initJitsi && isDefaultTurnOnVideo(currentUser, meeting)) {
      handleChangeLocalTrack('video', true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, meeting, initJitsi]);

  useEffect(() => () => {
    if (location.pathname !== `/organizations/${organizationKey}/meetings/${code}`) {
      handleUnload();
    }
  }, [code, handleUnload, organizationKey]);

  const setModal = useCallback(selected => {
    const videoConferencePage = document.getElementById('videoConferencePage');
    let count = unreadCount;

    // Read messages when selected chat
    if (selected === 'chat') {
      count = 0;
    }
    if (selected) {
      videoConferencePage.classList.add('display-left-panel');
    } else {
      videoConferencePage.classList.remove('display-left-panel');
    }
    setState(prevState => ({ ...prevState, modal: selected, unreadCount: count }));
  }, [unreadCount]);

  const handleVideoContainerOnClick = useCallback(e => {
    const videoContainer = e.target.classList.contains('video-container')
      ? e.target : e.target.parentElement.classList.contains('video-container') ? e.target.parentElement : null;
    videoContainer && toggleLargeVideo(videoContainer.id);
  }, []);

  return (
    <div className={styles.customMeeting}>
      {isLoading && <LoadingAllScreen />}
      <div className='meeting-container'>
        {isJoined ? (
          <div className='meeting-content'>
            <div className='header-pannel-sp'>
              <div className='meeting-name'>{meeting?.name}</div>
            </div>
            <div className='meeting-wrapper'>
              <div id='videoConferencePage' ref={videoConferenceContent} className='video-conference-page' onClick={handleVideoContainerOnClick}>
                <div id='localVideoConference' className='video-container'>
                  <div className='text-name'>
                    {!localTracks.audio && <MicDanger />}
                    {t('meetings.show.you')}
                  </div>
                  {!localTracks.video && (
                    <img
                      className='participant-avatar'
                      src={getImageSrc(currentUser?.image, defaultAvatar)}
                      width={300}
                      height='auto'
                      alt={currentUser.name}
                    />
                  )}
                  <video id='localVideo' hidden autoPlay muted playsInline onLoadedData={handleVideoLocalLoaded} />
                </div>
                <div id='backgroundVirtualCanvas'>
                  <video id='virtualVideo' width={videoWidth} height={videoHeight} autoPlay muted playsInline />
                  <canvas id='canvasOutput' width={videoWidth} height={videoHeight} />
                </div>
              </div>
              <BackgroundPane
                isDisplay={modal === 'background'}
                bodyPixNet={bodyPixNet.current}
                videoStream={localTracks.video}
                onClose={() => setModal()}
                room={room.current}
                videoLocalLoaded={videoLocalLoaded}
                setLocalTracks={setLocalTracks}
              />
              <Chat
                isDisplay={modal === 'chat'}
                code={code}
                channelRef={channelRef}
                currentUser={currentUser}
                onClose={() => setModal()}
              />
              <ParticipantList
                isDisplay={modal === 'participant'}
                currentUser={currentUser}
                participants={participants}
                participantRequestList={participantRequestList}
                setParticipantRequestList={setParticipantRequestList}
                channelRef={channelRef}
                code={code}
                onClose={() => setModal()}
              />
            </div>
            <Toolbox
              meetingName={meeting?.name}
              state={state}
              setModal={setModal}
              setState={setState}
              localTracks={localTracks}
              participants={participants}
              handleChangeLocalTrack={handleChangeLocalTrack}
              handleEndCall={() => handleUnload('end_call')}
            />
            <ScreenShare
              currentUser={currentUser}
              code={code}
              JitsiMeetJS={JitsiMeetJS}
              isSharing={isSharing}
              cancelSharing={() => setState(prevState => ({ ...prevState, isSharing: false }))}
              handleDisplayScreenShare={handleDisplayScreenShare}
            />
          </div>
        ) : (
          <PrejoinMeeting
            startMeeting={startMeeting}
            currentUser={currentUser}
            localTracks={localTracks}
            initJitsi={initJitsi}
            handleChangeLocalTrack={handleChangeLocalTrack}
            handleEndCall={() => handleUnload('end_call')}
          />
        )}
      </div>
    </div>
  );
};

export default JitsiComponent;
