import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { logout, updateLanguage } from 'redux/slices/auth';
import { dropdownSidebarItems } from './config';
import { dropdownActions } from './styles';

const UserMenu = ({ size = 32, page }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn, currentUser } = useSelector(state => state.auth);

  const newLocale = useMemo(() => (i18n.language === 'en' ? 'ja' : 'en'), [i18n.language]);

  const handleLogout = () => {
    dispatch(logout()).unwrap();
  };

  const changeLanguage = lng => {
    if (isLoggedIn) {
      dispatch(updateLanguage({ locale: lng })).unwrap().then(() => {
        i18n.changeLanguage(lng);
      });
    } else {
      i18n.changeLanguage(lng);
    }
  };

  return (
    <Dropdown
      menu={{
        items: dropdownSidebarItems({
          t, changeLanguage, handleLogout, newLocale, currentUser, page,
        }),
      }}
      trigger={['click']}
      getPopupContainer={trigger => trigger.parentNode}
      overlayClassName={dropdownActions}
    >
      <Typography.Link>
        <UserAvatar size={size} url={currentUser.image} alt={currentUser.name} />
      </Typography.Link>
    </Dropdown>
  );
};

export default UserMenu;
