import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, deactivateAccount, logout } from 'redux/slices/auth';
import { ReactComponent as CloseSvg } from 'icons/CloseBold.svg';
import LoadingAllScreen from 'components/LoadingAllScreen';
import InputForm from 'components/forms/InputForm';
import FileForm from 'components/forms/FileForm';
import NavBarMobile from 'components/NavBarMobile';
import { SUPPORTED_IMAGE_FORMATS } from 'utils/constants';
import confirm from 'components/Confirm';
import SideBar from 'components/SideBar';
import { styles } from './styles';

const schema = t => yup.object({
  name: yup.string().required(t('common.error.required')).max(250, t('common.error.max_length', { max: 250 })),
  username: yup.string().max(250, t('common.error.max_length', { max: 250 })),
  email: yup.string().email(t('common.error.email_required')).max(100, t('common.error.max_length', { max: 100 })),
});

const CustomerProfile = ({ currentUser }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { submitting } = useSelector(state => state.auth);
  const [imageSrc, setImageSrc] = useState(currentUser?.image);

  const defaultValues = useMemo(() => ({
    name: currentUser?.name || '',
    email: currentUser?.email || '',
    username: currentUser?.username || '',
  }), [currentUser]);

  const methods = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues,
  });
  const { handleSubmit, reset, setValue } = methods;

  const handleUpdateProfile = useCallback(formValues => {
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('username', formValues.username || '');
    formData.append('line_email', formValues.email || '');
    if (formValues.image) { formData.append('image', formValues.image[0]); }
    dispatch(updateProfile(formData));
  }, [dispatch]);

  const handleChangeImage = e => {
    setValue('image', e.target.files);
    setImageSrc(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null);
  };

  const handleDeactivate = useCallback(async () => {
    const result = await confirm({
      title: t('users.modals.deactivate_user.title'),
      submitText: t('users.modals.deactivate_user.submit_btn'),
      className: styles.deactivateModal,
    });
    if (result) {
      dispatch(deactivateAccount()).unwrap().then(() => {
        dispatch(logout());
      });
    }
  }, [dispatch, t]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return (
    <div className={styles.index}>
      {submitting && (<LoadingAllScreen />)}
      <SideBar />
      <NavBarMobile page='profile' />
      <div className='title'>
        {t('pages.profile')}
      </div>
      <div className='flex justify-center'>
        <FormProvider {...methods}>
          <div className='profile'>
            <div className='image-content'>
              <FileForm
                name='image'
                imageSrc={imageSrc}
                fileTypesAccept={SUPPORTED_IMAGE_FORMATS}
                onChange={handleChangeImage}
              />
            </div>
            <div className='form-container'>
              <div className='profile-info-row'>
                <label>{t('users.form.name')}</label>
                <InputForm name='name' />
              </div>
              <div className='profile-info-row'>
                <label>{t('users.form.email')}</label>
                <InputForm type='email' name='email' />
              </div>
              <div className='profile-info-row'>
                <label>{t('users.form.username')}</label>
                <InputForm name='username' />
              </div>
            </div>
            <div className='flex flex-row-reverse'>
              <button
                type='submit'
                disabled={submitting}
                className='submit-btn ant-btn ant-btn-primary'
                onClick={handleSubmit(handleUpdateProfile)}
              >
                {t('users.form.save')}
              </button>
            </div>
            <div className='deactivate-btn' onClick={handleDeactivate}>
              <CloseSvg width={16} height={16} />
              {t('users.form.deactivate')}
            </div>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default CustomerProfile;
