import React from 'react';
import CompassLogo from 'images/Compass-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => (
  <div className='footer'>
    <div className='company-info'>
      <div className='left-panel'>
        <img src={CompassLogo} alt='Footer Logo' />
        <div className='info'>
          <div className='company-name'>
            株式会社Compass
          </div>
          <div className='address'>
            〒650-0035
            <br />
            兵庫県神戸市中央区浪花町56 KiP内
          </div>
        </div>
      </div>
      <div className='center-panel'>
        <div className='phone-text'>
          <FontAwesomeIcon icon={faPhone} alt='icon' className='phone-icon' rotation={270} />
          お電話でのお問い合わせ
        </div>
        <a href='tel:078-600-2284' className='phone-number'>078-600-2284</a>
        <div className='working-time'>
          受付時間／平日10:00~18:00
        </div>
      </div>
      <div className='right-panel'>
        <a href={process.env.REACT_APP_DEFAULT_CUSTOMER_LOGIN_URL || '/customer_login'} className='item'>アカウント開設・ログイン</a>
        <a href='/terms' className='item'>利用規約</a>
        <a href='/privacy' className='item'>プライバシーポリシー</a>
        <a href='https://corp.choice-career.com' className='item' target='_blank' rel='noreferrer'>会社概要</a>
      </div>
    </div>
  </div>
);

export default Footer;
