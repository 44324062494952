import { css } from '@emotion/css';
import { COLORS, truncateMultiline } from 'utils/constants';

export const styles = {
  index: css`
    .user-page {
      width: 100%;

      .title-header {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        padding: 24px 16px;
      }

      .user-container {
        display: flex;

        .main-content {
          width: 100%;
          padding: 16px 0 40px 0;

          .total-members {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: ${COLORS.athensGray2};
            margin-bottom: 16px;
            margin-left: 16px;
          }

          &.show-details {
            width: calc(100% - 370px);
          }

          .list-users {
            margin-bottom: 30px;

            &.grid-view {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 10px;
              padding-left: 16px;
              padding-right: 16px;

              .user-card {
                width: 398px;
                height: 112px;
                display: flex;
                align-items: center;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                padding: 16px;
                cursor: pointer;

                &:hover {
                  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
                }

                .user-info {
                  width: calc(100% - 80px);
                  margin-left: 16px;
                  display: flex;
                  flex-direction: column;

                  .name {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 24px;
                    color: ${COLORS.mineShaft};
                    ${truncateMultiline(2)}
                    margin-bottom: 8px;
                  }

                  .user-title {
                    width: 100%;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: 0.15px;
                    color: ${COLORS.silverChalice};
                    ${truncateMultiline(2)}
                  }
                }
              }
            }

            &.list-view {
              display: inline;

              .ant-table-wrapper {
                margin-bottom: 30px;

                .ant-table-content {
                  th, td {
                    border-bottom: none;
                    font-weight: 500;
                  }

                  .ant-table-row {
                    cursor: pointer;
                  }

                  th.ant-table-cell {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    background: none;
                    color: ${COLORS.silverChalice};
                  }

                  td.ant-table-cell {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;

                    &.ant-table-cell-row-hover {
                      background-color: ${COLORS.wildSand};
                    }

                    .info-name {
                      margin-left: 24px;
                    }
                  }
                }
              }
            }
          }
        }

        .user-details {
          width: 370px;
          min-height: calc(100vh - 150px);
          border-left: 1px solid ${COLORS.mercury};
          position: relative;

          .user-info {
            position: sticky;
            top: 0;
            padding: 16px;

            .group-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 16px;

              .title {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                color: ${COLORS.black};
              }
            }

            .group-info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-bottom: 24px;

              .text-name {
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                color: ${COLORS.mineShaft};
                margin-top: 16px;
                margin-bottom: 4px;
              }

              .user-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: ${COLORS.silverChalice};
              }
            }

            .info {
              margin-bottom: 16px;

              label {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: ${COLORS.mineShaft};
                margin-bottom: 2px;
              }

              .description {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                word-break: break-all;
                color: ${COLORS.silverChalice};
                white-space: break-spaces;
              }
            }

            .dots-icon {
              width: 24px;
              cursor: pointer;
            }
          }
        }
      }
    }
  `,
};

export const header = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-top: 0;
  border-bottom: 1px solid ${COLORS.mercury};

  .search-group {
    width: 460px;
    max-width: 100%;
    position: relative;

    .input-search {
      width: 100%;
      max-width: 100%;

      &.ant-select-focused {
        .ant-select-selector {
          background-color: ${COLORS.white};
          box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
          border-radius: 28px;
        }
      }

      .ant-select-selector {
        height: 56px;
        border: none;
        background-color: ${COLORS.athensGray};
        border-radius: 100px;

        .ant-select-selection-search-input,
        .ant-select-selection-placeholder {
          height: 100%;
          padding-left: 42px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
        }

        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
          color: ${COLORS.silverChalice};
        }
      }

      .ant-select-clear {
        background: none;
        top: 50%;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .icon-search {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 19px;
      left: 19px;
      z-index: 2;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .add-btn {
      display: flex;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      margin-left: 20px;
      color: ${COLORS.dodgerBlue};
      cursor: pointer;
      align-items: center;

      path {
        stroke: ${COLORS.dodgerBlue};
      }

      .text {
        margin-left: 10px;
      }
    }

    .change-view-group {
      width: 76px;
      height: 40px;
      background-color: ${COLORS.athensGray};
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      .change-btn {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;

        &.active {
          background-color: ${COLORS.white};
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`;

export const searchResults = css`
  box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
  border-radius: 0 0 28px 28px;

  .search-results-item {
    display: flex;
    align-items: center;
    padding: 8px 2px;

    .item-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-left: 12px;
    }
  }
`;

export const addUserModal = css`
  .ant-modal-content .new-form {
    .image-content {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      .image-main, .image-field {
        border-radius: 50%;
      }

      .upload-btn {
        right: 0;
      }
    }

    .form-content {
      width: 100%;

      .input-form {
        margin-bottom: 16px;
      }
    }
  }
`;

export const removeUserModal = css`
  .ant-modal-content {
    .ant-modal-header {
      text-align: center;
    }

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 24px 16px;

      .content, .confirm-name-input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${COLORS.black};
      }

      .confirm-name-input {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        margin-top: 24px;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: center;
    }
  }
`;
