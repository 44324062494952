import React from 'react';
import UserAvatar from 'components/UserAvatar';

const GridView = ({
  users = [],
  handleSelectedUser,
}) => (
  <div className='list-users grid-view'>
    {users.map((user, index) => (
      <div className='user-card' key={index} onClick={() => handleSelectedUser(user.id)}>
        <div className='image-wrapper'>
          <UserAvatar size={80} url={user?.image} alt={user.name} />
        </div>
        <div className='user-info'>
          <span className='name'>{user.name}</span>
          <span className='user-title'>{user.email}</span>
        </div>
      </div>
    ))}
  </div>
);

export default GridView;
