import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { Controller } from 'react-hook-form';
import { customCheckboxForm } from './styles';

export const CheckboxForm = ({
  label, name, defaultValue, labelDescription, labelDescriptionClassName,
  labelClassName, errorClassName, className, required, control, ...rest
}) => (
  <div
    className={`${className || customCheckboxForm} checkbox-form`}
  >
    <Controller
      name={name}
      control={control}
      render={props => (
        <Checkbox
          {...rest}
          {...props}
          disabled={rest.disabled || rest.isDisabled}
          checked={props.field.value || false}
          onChange={e => {
            const func = rest.onChange || props.field.onChange;
            func(e.target.checked);
          }}
        >
          <div className='label-group'>
            {label && <span className={labelClassName || 'text-label'}>{label}</span>}
            {labelDescription && <span className={labelDescriptionClassName || 'text-description'}>{labelDescription}</span>}
          </div>
        </Checkbox>
      )}
    />

  </div>
);
