import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { getImageSrc } from 'utils/fileHelper';

const ListView = ({
  organizations = [],
  handleSelectedOrganization,
}) => {
  const [t] = useTranslation();
  const columns = [
    {
      title: t('organizations.form.name'),
      dataIndex: 'name',
      key: 'name',
      width: 380,
      render: (_, org) => (
        <div className='flex items-center'>
          <img className='org-image' src={getImageSrc(org.image)} alt={org.name} />
          <span>{org.name}</span>
        </div>
      ),
    },
    {
      title: t('organizations.form.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('users.form.name'),
      dataIndex: 'admin',
      key: 'user_name',
      width: 240,
      render: admin => admin?.name,
    },
    {
      title: t('users.form.email'),
      dataIndex: 'admin',
      key: 'user_email',
      width: 240,
      render: admin => admin?.email,
    },
  ];
  return (
    <div className='list-organizations list-view'>
      <Table
        columns={columns}
        dataSource={organizations}
        rowKey='id'
        pagination={false}
        onRow={organization => ({
          onClick: () => handleSelectedOrganization(organization.id),
        })}
      />
    </div>
  );
};

export default ListView;
