import api from 'redux/api-common';
import { camelCaseObject } from 'utils';
import qs from 'qs';

const fetchChatRooms = ({ params }) => api
  .get(`/chat_rooms?${qs.stringify(params)}`)
  .then(response => camelCaseObject(response.data));

const fetchChatRoom = ({ params: { id, ...rest } }) => api
  .get(`/chat_rooms/${id}?${qs.stringify(rest)}`)
  .then(response => camelCaseObject(response.data));

const assignMembers = ({ params: { chatRoomId, ...rest } }) => api
  .post(`chat_rooms/${chatRoomId}/assign_members`, rest)
  .then(response => camelCaseObject(response.data));

const unassignMember = ({ params: { chatRoomId, ...rest } }) => api
  .put(`chat_rooms/${chatRoomId}/unassign_member`, rest)
  .then(response => camelCaseObject(response.data));

const createChatRoom = data => api
  .post('chat_rooms', data)
  .then(response => camelCaseObject(response.data));

const updateChatRoom = ({ params }) => api
  .put(`chat_rooms/${params.id}`, params)
  .then(response => camelCaseObject(response.data));

const removeChatRoom = ({ params: { id, ...rest } }) => api
  .delete(`chat_rooms/${id}?${qs.stringify(rest)}`)
  .then(response => camelCaseObject(response.data));

export default {
  fetchChatRooms,
  fetchChatRoom,
  assignMembers,
  unassignMember,
  createChatRoom,
  removeChatRoom,
  updateChatRoom,
};
