import React from 'react';
import { ReactComponent as AvatarSvg } from 'icons/Avatar.svg';
import { commonStyles } from './styles';

const UserAvatar = ({
  url, size = '48px', alt = 'avatar',
  className, iconClassName, borderClassName = 'border',
}) => (
  <div
    className={`${className || commonStyles.userAvatar} ${url ? '' : borderClassName} flex place-content-center items-center`}
    style={{
      width: size,
      height: size,
      minWidth: size,
      overflow: 'hidden',
      backgroundColor: 'white',
    }}
  >
    {url ? <img className='avatar' src={url} width={size} height={size} alt={alt} />
      : <AvatarSvg className={`${iconClassName || 'icon-user'}`} />}
  </div>
);

export default UserAvatar;
