import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';

export const styles = {
  login: css`
    .submit-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 700;
      border-radius: 5px;
    }

    .redirect-btn {
      font-size: 14px;
      font-weight: 500;
    }

    .cancel-btn {
      width: 110px;
      display: flex;
      justify-content: center;
      font-weight: 700;
      line-height: 22px;
      color: ${COLORS.trout};
      background-color: ${COLORS.catskillWhite};
      border-width: 1px;
      padding: 5px;
      border-color: transparent;
      border-radius: 5px;
    }
  `,
  customerLogin: css`
    background-color: ${COLORS.athensGray};
    min-height: 100%;

    .login-form {
      width: 738px;
      margin: 20px;
    }

    .org-image {
      border-radius: 12px;
    }

    .section-body {
      height: inherit;
    }

    .buttons {
      margin-top: 40px;
      background-color: ${COLORS.white};
      padding: 40px 16px;
      border-radius: 12px;
      box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
    }

    .description {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      white-space: break-spaces;
      text-align: center;
    }

    .agree-term-checkbox {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-top: 20px;

      .ant-checkbox {
        top: 0px;
        margin: auto;

        .ant-checkbox-inner {
          border-width: 2px;
        }
      }
    }

    .title {
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      margin-bottom: 20px;
    }

    .line-btn {
      display: flex;
      border: none;
      text-decoration: none;
      background: none;
      cursor: pointer;
      outline: none;
      background-color: ${COLORS.mantis};;
      font-size: 16px;
      font-weight: bold;
      color: ${COLORS.white};
      border-radius: 5px;
      min-width: 240px;
      padding: 10px 24px;
      border-radius: 100px;
      gap: 10px;


      .line-image {
        margin-right: 10px;
      }

      span {
        width: 100%;
        margin: auto 0;
        vertical-align: middle;
      }

      &:hover {
        background-color: ${COLORS.japaneseLaurel};
      }

      &:disabled {
        background-color: ${COLORS.athensGray2};
      }
    }

    @media (max-width: 767px) {
      .description {
        white-space: unset;
        text-align: left;
      }
    }
  `,
};
