import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getOrganizationKey } from 'utils';
import { set } from 'lodash';
import UsersService from '../services/UsersService';

export const fetchUsers = createAsyncThunk(
  'users/FETCH_USERS',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await UsersService.fetchUsers(data);
    return result?.data;
  },
);

export const fetchUser = createAsyncThunk(
  'users/FETCH_USER',
  async id => {
    const result = await UsersService.fetchUser({ params: { id, organization_key: getOrganizationKey() } });
    return result?.data;
  },
);

export const createUser = createAsyncThunk(
  'users/CREATE_USER',
  async data => {
    if (data instanceof FormData) {
      data.set('organization_key', getOrganizationKey());
    } else {
      set(data, 'organization_key', getOrganizationKey());
    }
    const result = await UsersService.createUser(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result;
  },
);

export const updateUser = createAsyncThunk(
  'users/UPDATE_USER',
  async data => {
    if (data instanceof FormData) {
      data.set('organization_key', getOrganizationKey());
    } else {
      set(data, 'organization_key', getOrganizationKey());
    }
    const result = await UsersService.updateUser({ params: data, id: data.get('id') });
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result;
  },
);

export const deleteUser = createAsyncThunk(
  'users/DELETE_USER',
  async ({ id }) => {
    const result = await UsersService.removeUser({ params: { id, organization_key: getOrganizationKey() } });
    return result;
  },
);

export const suggestionUsers = createAsyncThunk(
  'users/SUGGESTION_USERS',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await UsersService.fetchUsers(data);
    return result?.data;
  },
);

const initialState = {
  users: [],
  user: {},
  pagination: { currentPage: 1, perPage: 20 },
  fetching: false,
  fetchingUser: false,
  isSubmitting: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: {
    [fetchUsers.pending]: state => {
      state.fetching = true;
    },
    [fetchUsers.fulfilled]: (state, _action) => {
      state.users = _action.payload.users;
      state.pagination = _action.payload.meta;
      state.fetching = false;
    },
    [fetchUsers.rejected]: state => {
      state.users = [];
      state.pagination = [];
      state.fetching = false;
    },
    [fetchUser.pending]: state => {
      state.fetchingUser = true;
    },
    [fetchUser.fulfilled]: (state, _action) => {
      state.user = _action.payload.user;
      state.fetchingUser = false;
    },
    [fetchUser.rejected]: state => {
      state.fetchingUser = false;
    },
    [createUser.pending]: state => {
      state.isSubmitting = true;
    },
    [createUser.fulfilled]: state => {
      state.isSubmitting = false;
    },
    [createUser.rejected]: state => {
      state.isSubmitting = false;
    },
    [updateUser.pending]: state => {
      state.isSubmitting = true;
    },
    [updateUser.fulfilled]: state => {
      state.isSubmitting = false;
    },
    [updateUser.rejected]: state => {
      state.isSubmitting = false;
    },
  },
});

const { reducer } = usersSlice;
export default reducer;
