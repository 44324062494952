import React from 'react';
import { getImageSrc } from 'utils/fileHelper';

const GridView = ({
  organizations = [],
  handleSelectedOrganization,
}) => (
  <div className='list-organizations grid-view'>
    {organizations.map((organization, index) => (
      <div className='organization-card' key={index} onClick={() => handleSelectedOrganization(organization.id)}>
        <div className='image-wrapper'>
          <img
            className='org-image'
            src={getImageSrc(organization.image)}
            width={412}
            height='auto'
            alt={organization.name}
          />
        </div>
        <div className='name-membrane'>
          <span className='name'>{organization.name}</span>
        </div>
      </div>
    ))}
  </div>
);

export default GridView;
