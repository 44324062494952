import moment from 'moment';

export const timezoneConverter = startTime => {
  if (!startTime) return '';
  return moment(startTime).utcOffset(startTime.split(' ').pop());
};

export const formatStartTime = (startTime, format = 'HH:mm') => {
  if (!startTime) return '';
  return timezoneConverter(startTime).format(format);
};

export const formatDateTimezone = (time, timezone, format = 'YYYY/MM/DD') => {
  if (!time || !timezone) return '';
  return moment(time).utcOffset(timezone).format(format);
};

export const dateToFromNowDaily = startTime => {
  if (!startTime) return;

  return timezoneConverter(startTime).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'YYYY/MM/DD',
    sameElse() {
      return 'YYYY/MM/DD';
    },
  });
};
