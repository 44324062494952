const userAccessPages = ['Profile', 'Dashboard', 'Chat', 'Meetings', 'MeetingShow', 'PlannedMeetings'];
const customerAccessPages = ['Profile', 'Chat', 'Meetings', 'MeetingShow', 'PlannedMeetings'];

export const canAccessPage = (role, page) => {
  if (role === 'system_admin') {
    return ['Profile', 'Organizations'].includes(page);
  }
  if (role === 'admin') {
    return [...userAccessPages, 'Users'].includes(page);
  }
  if (role === 'employee') {
    return userAccessPages.includes(page);
  }
  if (role === 'customer') {
    return customerAccessPages.includes(page);
  }
  return false;
};
