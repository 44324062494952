import React, { useState } from 'react';
import { Layout } from 'antd';
import Logo from 'images/logo.png';
import { ReactComponent as LoginSvg } from 'icons/Login.svg';
import { ReactComponent as PencilSvg } from 'icons/PencilEdit.svg';
import { ReactComponent as MenuSvg } from 'icons/Menu.svg';
import { ReactComponent as CloseSvg } from 'icons/Close.svg';
import classNames from 'classnames';

const { Header } = Layout;

const Navbar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const scrollToSection = sectionId => {
    const element = window.document.querySelector(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Header fixed='true'>
      <div className='header'>
        <div className='logo'>
          <img src={Logo} alt='Logo' />
        </div>
        <div className='header-buttons'>
          <a href={process.env.REACT_APP_DEFAULT_CUSTOMER_LOGIN_URL || '/customer_login'} className='login-btn'>
            <LoginSvg />
            <div className='text'>ログイン</div>
          </a>
          <a href={process.env.REACT_APP_DEFAULT_CUSTOMER_LOGIN_URL || '/customer_login'} className='register-btn'>
            <PencilSvg />
            <div className='text'>会員登録</div>
          </a>
          <a href='#contact' onClick={() => scrollToSection('#contact')} className='contact-btn'>
            お問い合わせ
          </a>
          <div className='menu-btn' onClick={toggleCollapsed}>
            {collapsed ? <CloseSvg width={19} height={19} /> : <MenuSvg /> }
          </div>
        </div>
      </div>
      <div className='menu-sp'>
        <div className={classNames('menu-items', { collapsed })}>
          <div className='item active'>ホーム</div>
          <div className='item'>ログイン</div>
          <div className='item'>会員登録</div>
          <div className='close-menu-btn' onClick={toggleCollapsed}>
            <CloseSvg width={21} height={21} />
            閉じる
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Navbar;
