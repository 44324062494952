import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import organizationReducer from './slices/organizations';
import userReducer from './slices/users';
import chatRoomsReducer from './slices/ChatRooms';
import messagesReducer from './slices/Messages';
import meetingsReducer from './slices/meetings';
import contactsReducer from './slices/contacts';

const reducers = {
  auth: authReducer,
  organizations: organizationReducer,
  users: userReducer,
  chatRooms: chatRoomsReducer,
  messages: messagesReducer,
  meetings: meetingsReducer,
  contacts: contactsReducer,
};

const combineReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'auth/LOGOUT/fulfilled') {
    state = undefined;
  }
  return combineReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
