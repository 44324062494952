import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import OrganizationsService from '../services/OrganizationsService';

export const fetchOrganizations = createAsyncThunk(
  'organizations/FETCH_ORGANIZATIONS',
  async (data = {}) => {
    const result = await OrganizationsService.fetchOrganizations(data);
    return result?.data;
  },
);

export const fetchOrganization = createAsyncThunk(
  'organizations/FETCH_ORGANIZATION',
  async id => {
    const result = await OrganizationsService.fetchOrganization(id);
    return result?.data;
  },
);

export const deleteOrganization = createAsyncThunk(
  'organizations/DELETE_ORGANIZATION',
  async ({ id }) => {
    const result = await OrganizationsService.removeOrganization(id);
    return result;
  },
);

export const createOrganization = createAsyncThunk(
  'organizations/CREATE_ORGANIZATION',
  async data => {
    const result = await OrganizationsService.createOrganization(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result;
  },
);

export const updateOrganization = createAsyncThunk(
  'organizations/UPDATE_ORGANIZATION',
  async data => {
    const result = await OrganizationsService.updateOrganization(data, data.get('id'));
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result;
  },
);

export const suggestionOrganizations = createAsyncThunk(
  'organizations/SUGGESTION_ORGANIZATIONS',
  async (data = {}) => {
    const result = await OrganizationsService.fetchOrganizations(data);
    return result?.data;
  },
);

const initialState = {
  organizations: [],
  organization: {},
  pagination: { currentPage: 1, perPage: 20 },
  fetching: false,
  fetchingOrganization: false,
  submitting: false,
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  extraReducers: {
    [fetchOrganizations.pending]: state => {
      state.fetching = true;
    },
    [fetchOrganizations.fulfilled]: (state, _action) => {
      state.organizations = _action.payload.organizations;
      state.pagination = _action.payload.meta;
      state.fetching = false;
    },
    [fetchOrganizations.rejected]: state => {
      state.organizations = [];
      state.pagination = {};
      state.fetching = false;
    },
    [fetchOrganization.pending]: state => {
      state.fetchingOrganization = true;
    },
    [fetchOrganization.fulfilled]: (state, _action) => {
      state.organization = _action.payload;
      state.fetchingOrganization = false;
    },
    [fetchOrganization.rejected]: state => {
      state.fetchingOrganization = false;
    },
    [deleteOrganization.pending]: state => {
      state.submitting = true;
    },
    [deleteOrganization.fulfilled]: state => {
      state.submitting = false;
    },
    [deleteOrganization.rejected]: state => {
      state.submitting = false;
    },
    [createOrganization.pending]: state => {
      state.submitting = true;
    },
    [createOrganization.fulfilled]: state => {
      state.submitting = false;
    },
    [createOrganization.rejected]: state => {
      state.submitting = false;
    },
    [updateOrganization.pending]: state => {
      state.submitting = true;
    },
    [updateOrganization.fulfilled]: state => {
      state.submitting = false;
    },
    [updateOrganization.rejected]: state => {
      state.submitting = false;
    },
  },
});

const { reducer } = organizationsSlice;
export default reducer;
