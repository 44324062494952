import React from 'react';
import { Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';

const MessageAttachments = ({ files = [] }) => {
  const handleImageError = e => {
    // first time load image after uplaod return ERR_BLOCKED_BY_ORB
    // TODO: investigate this issue
    const reTry = Number(e.target.getAttribute('reTry')) || 1;
    if (reTry < 3) {
      setTimeout(() => {
        e.target.src = `${e.target.src}`;
        e.target.setAttribute('reTry', reTry + 1);
      }, 1000);
    }
  };

  const previewFile = file => {
    if (file.type.includes('image')) {
      return (
        <Image
          className='image-card'
          src={file.url}
          alt={file.filename}
          loading='lazy'
          onError={handleImageError}
        />
      );
    } if (file.type.includes('video')) {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video className='video-card' controls>
          <source src={file.url} />
        </video>
      );
    }
    if (file.type.includes('audio')) {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls>
          <source src={file.url} />
        </audio>
      );
    }
    return (
      <div className='file-card'>
        <FontAwesomeIcon className='file-icon' icon={faFileArchive} />
        <span className='file-name'>
          <a target='_blank' href={file.url} rel='noreferrer'>{file.filename}</a>
        </span>
      </div>
    );
  };

  return (
    <div className='file-chat-box'>
      <Image.PreviewGroup>
        {files.map((file, index) => (
          <div className='file-content' key={`files-${index}`}>
            {previewFile(file)}
          </div>
        ))}
      </Image.PreviewGroup>
    </div>
  );
};

export default MessageAttachments;
