import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from 'icons/CloseSvg.svg';
import { ReactComponent as CheckSvg } from 'icons/Check.svg';
import { ReactComponent as RemoveSvg } from 'icons/Close.svg';
import UserAvatar from 'components/UserAvatar';
import { isPresent } from 'utils';
import { sideToolbar, participantList } from './styles';

const ParticipantList = ({
  isDisplay, currentUser, participants, onClose, channelRef, code,
  participantRequestList, setParticipantRequestList,
}) => {
  const [t] = useTranslation();

  const handleApproveJoinning = useCallback((participant, isAccept) => {
    channelRef.current.send({
      code,
      command_type: isAccept ? 'approve_joining' : 'reject_joining',
      user: participant,
    });
    setParticipantRequestList(prevParticipant => prevParticipant.filter(user => user.id !== participant.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelRef, code]);

  return (
    <div className={`${participantList} ${sideToolbar} ${!isDisplay && 'hidden'}`}>
      <div className='header'>
        <div className='title'>
          {t('meetings.show.participants')}
        </div>
        <CloseSvg className='cursor-pointer' onClick={onClose} />
      </div>
      <div className='participant-list'>
        {isPresent(participantRequestList) && (
          <div className='participant-list-waiting'>
            <div className='title-waiting'>{t('meetings.show.title_waiting')}</div>
            {participantRequestList.map((participant, index) => (
              <div className='user-group' key={`participant-${index}`}>
                <div className='avatar-group'>
                  <UserAvatar size={48} url={participant?.image} alt={participant.name} />
                  <span className='text-name'>{participant.name}</span>
                </div>
                <div className='actions'>
                  <RemoveSvg className='cursor-pointer' onClick={() => handleApproveJoinning(participant, false)} />
                  <CheckSvg className='cursor-pointer' onClick={() => handleApproveJoinning(participant, true)} />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='user-group'>
          <div className='avatar-group'>
            <UserAvatar size={48} url={currentUser?.image} alt={currentUser.name} />
            <span className='text-name'>{`${currentUser.name} (${t('meetings.show.you')})`}</span>
          </div>
        </div>
        {participants.map((participant, index) => (
          <div className='user-group' key={`participant-${index}`}>
            <div className='avatar-group'>
              <UserAvatar size={48} url={participant?.image} alt={participant.name} />
              <span className='text-name'>{participant.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParticipantList;
