import api from 'redux/api-common';
import qs from 'qs';
import { camelCaseObject } from 'utils';

const login = (email, password) => api
  .post('/login', JSON.stringify({ user: { email, password } }))
  .then(response => {
    if (response?.headers?.authorization) {
      localStorage.setItem('token', response.headers.authorization);
    }

    return response.data;
  });

const logout = () => {
  api.delete('/logout').then(() => {
    localStorage.removeItem('token');
  });
};

const forgotPassword = email => api
  .post('/password', JSON.stringify({ user: { email } }))
  .then(response => response.data);

const resetPassword = (reset_password_token, password) => api
  .patch('/password', JSON.stringify({ user: { reset_password_token, password } }))
  .then(response => response.data);

const getProfile = () => api
  .get('/profile')
  .then(response => response.data);

const updateLanguage = locale => api
  .post('/profile/update_language', JSON.stringify({ locale }))
  .then(response => response.data);

const updateProfile = data => api
  .put('/profile/update_profile', data)
  .then(response => response.data);

const deactivateAccount = data => api
  .delete('/profile/deactivate', data)
  .then(response => response.data);

const getLineInfo = params => api
  .get(`/line/line_info?${qs.stringify(params)}`)
  .then(response => camelCaseObject(response.data));

export default {
  login,
  logout,
  forgotPassword,
  resetPassword,
  getProfile,
  updateLanguage,
  updateProfile,
  getLineInfo,
  deactivateAccount,
};
