import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import AuthService from 'redux/services/AuthService';
import { isBlank } from 'utils';
import i18next from 'i18n';

export const login = createAsyncThunk(
  'auth/LOGIN',
  async ({ email, password }, thunkAPI) => {
    try {
      const result = await AuthService.login(email, password);
      return result?.data?.user;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const logout = createAsyncThunk(
  'auth/LOGOUT',
  async () => {
    await AuthService.logout();
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/FORGOT_PASSWORD',
  async ({ email }) => {
    await AuthService.forgotPassword(email);
  },
);

export const resetPassword = createAsyncThunk(
  'auth/RESET_PASSWORD',
  async ({ token: resetToken, password }, thunkAPI) => {
    try {
      const data = await AuthService.resetPassword(resetToken, password);
      return data;
    } catch (error) {
      toast.error('The token has expired');
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getProfile = createAsyncThunk(
  'auth/PROFILE',
  async (_, thunkAPI) => {
    try {
      const result = await AuthService.getProfile();
      const language = get(result, 'data.user.language', 'ja');
      i18next.changeLanguage(language);
      return result.data;
    } catch (error) {
      toast.error('The token has expired');
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateLanguage = createAsyncThunk(
  'auth/UPDATE_LANGUAGE',
  async ({ locale }) => {
    const result = await AuthService.updateLanguage(locale);
    return result.data;
  },
);

export const updateProfile = createAsyncThunk(
  'auth/UPDATE_PROFILE',
  async (data, thunkAPI) => {
    const result = await AuthService.updateProfile(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
      return thunkAPI.rejectWithValue();
    }
    return result.data;
  },
);

export const deactivateAccount = createAsyncThunk(
  'auth/DEACTIVATE_ACCOUNT',
  async data => {
    const result = await AuthService.deactivateAccount(data);
    return result.data;
  },
);

export const getLineInfo = createAsyncThunk(
  'auth/GET_LINE_INFO',
  async (data, thunkAPI) => {
    try {
      const result = await AuthService.getLineInfo(data);
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const loginAsCustomer = createAsyncThunk(
  'auth/LOGIN_AS_CUSTOMER',
  data => {
    localStorage.setItem('token', data.authorization);
    localStorage.setItem('customerLoginUrl', data.customerLoginUrl);
    return data;
  },
);

const initialState = {
  isLoggedIn: !isBlank(localStorage.getItem('token')),
  submitting: false,
  lineInfo: null,
  fetched: false,
  fetchedProfile: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.fulfilled]: state => {
      state.isLoggedIn = true;
      localStorage.removeItem('customerLoginUrl');
    },
    [login.rejected]: state => {
      state.isLoggedIn = false;
    },
    [logout.fulfilled]: state => {
      state.isLoggedIn = false;
      state.currentUser = null;
      state.customerLoginUrl = localStorage.getItem('customerLoginUrl');
    },
    [logout.rejected]: state => {
      state.isLoggedIn = false;
      state.currentUser = null;
    },
    [getProfile.fulfilled]: (state, _action) => {
      state.isLoggedIn = true;
      state.fetchedProfile = true;
      state.currentUser = _action.payload.user;
    },
    [getProfile.rejected]: state => {
      state.currentUser = null;
      state.fetchedProfile = true;
    },
    [updateProfile.pending]: state => {
      state.submitting = true;
      state.fetchedProfile = false;
    },
    [updateProfile.fulfilled]: (state, _action) => {
      state.currentUser = _action.payload.user;
      state.submitting = false;
    },
    [updateProfile.rejected]: state => {
      state.submitting = false;
    },
    [getLineInfo.fulfilled]: (state, _action) => {
      state.lineInfo = _action.payload;
      state.fetched = true;
    },
    [getLineInfo.rejected]: state => {
      state.fetched = true;
    },
    [loginAsCustomer.fulfilled]: state => {
      state.isLoggedIn = true;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
