import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { canAccessPage } from 'utils/permissionHelper';
import { getProfile } from 'redux/slices/auth';
import { teamLayout } from './styles';
import JoinMeetingModal from './JoinMeetingModal';
import SideBar from './SideBar';
import OrganizationBar from './OrganizationBar';
// import OrganizationsBar from './OrganizationsBar';

const TeamLayout = ({ Component, page }) => {
  const dispatch = useDispatch();
  const { isLoggedIn, currentUser, customerLoginUrl } = useSelector(state => state.auth);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (isLoggedIn && !currentUser) {
      dispatch(getProfile());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoggedIn]);

  if (!isLoggedIn) {
    return <Navigate to={customerLoginUrl || '/login'} replace />;
  }

  if (!currentUser) {
    return <LoadingAllScreen />;
  }

  if (!canAccessPage(currentUser.role, page)) {
    return <Navigate to='/profile' replace />;
  }

  return (
    <div className={teamLayout}>
      <OrganizationBar />
      <SideBar page={page} level={2} />
      <div className='team-wrapper'>
        <Component currentUser={currentUser} />
      </div>
      {isOpenModal && (
        <JoinMeetingModal toggle={() => setIsOpenModal(false)} />
      )}
    </div>
  );
};

export default TeamLayout;
