import api from 'redux/api-common';
import { camelCaseObject } from 'utils';
import qs from 'qs';

const fetchMeetings = ({ params }) => api
  .get(`/meetings?${qs.stringify(params)}`)
  .then(response => camelCaseObject(response.data));

const fetchMeeting = ({ params: { id, ...rest } }) => api
  .get(`/meetings/${id}?${qs.stringify(rest)}`)
  .then(response => camelCaseObject(response.data));

const createMeeting = ({ params }) => api
  .post('meetings', params)
  .then(response => camelCaseObject(response.data));

const updateMeeting = ({ params: { id, ...rest } }) => api
  .put(`/meetings/${id}`, rest)
  .then(response => camelCaseObject(response.data));

const deleteMeeting = ({ params: { id, ...rest } }) => api
  .delete(`/meetings/${id}?${qs.stringify(rest)}`)
  .then(response => camelCaseObject(response.data));

const joinMeeting = ({ params: { id, ...rest } }) => api
  .post(`/meetings/${id}/join_meeting`, rest)
  .then(response => camelCaseObject(response.data));

const authenticateMeeting = ({ params: { code, ...rest } }) => api
  .post(`/meetings/${code}/authenticate_meeting`, rest)
  .then(response => camelCaseObject(response.data));

export default {
  fetchMeetings,
  fetchMeeting,
  createMeeting,
  updateMeeting,
  deleteMeeting,
  joinMeeting,
  authenticateMeeting,
};
