import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';

export const styles = {
  index: css`
    .waiting-room {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .waiting-image {
        width: 445px;
        height: 215px;
        margin-bottom: 48px;
      }

      .waiting-accept {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: ${COLORS.mineShaft};
        margin-bottom: 16px;
      }

      .text-session {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
        color: ${COLORS.silverChalice};
      }
    }

    #backgroundVirtualCanvas {
      position: absolute;
      z-index: -1;
  
      #canvasOutput {
        display: none;
      }
    }
  `,
  customMeeting: css`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .meeting-container {
      width: 100%;
      height: 100vh;
      position: relative;
      background: #191B1F;

      .meeting-content {
        width: 100%;
        height: 100%;

        .header-pannel-sp {
          display: none;
          height: 76px;
          border-bottom: 2px solid ${COLORS.mercury};
          padding: 0 16px;
          align-items: center;

          .meeting-name {
            color: ${COLORS.white};
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
          }
        }

        .meeting-wrapper {
          width: 100%;
          height: calc(100% - 104px); // minus height bottom toolbox
          display: flex;

          .video-conference-page {
            margin: 0 auto;
            width: 100%;
            padding: 20px;
            height: 100%;
            overflow-x: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 16px;
            position: relative;

            .video-container {
              width: 520px;
              height: 300px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              background: rgba(167, 167, 167, 0.15);
              border-radius: 12px;
              overflow: hidden;
              cursor: pointer;

              .participant-avatar {
                width: 180px;
                max-width: 80%;
                height: 180px;
                max-height: 80%;
                border-radius: 50%;
                object-fit: cover;
                background-color: ${COLORS.white};
                margin: auto;
              }

              .text-name {
                width: fit-content;
                display: flex;
                align-items: center;
                height: 32px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                letter-spacing: 0.1px;
                background: rgba(40, 40, 40, 0.6);
                color: white;
                border-radius: 100px;
                position: absolute;
                left: 16px;
                bottom: 16px;
                padding: 6px 16px;
                column-gap: 12px;
                z-index: 2;
              }

              .border-video {
                width: 100%;
                height: 100%;
                border: 4px solid ${COLORS.dodgerBlue};
                border-radius: 12px;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
              }

              video {
                height: 100%;
                position: absolute;
                margin: auto;
              }
            }

            .large-video {
              order: 0;
              position: fixed;
              width: calc(100% - 190px); // minus width small videos
              height: calc(100% - 124px);
              left: 10px;
            }
            .small-video {
              order: 1;
              width:  150px; // minus grap and padding
              height: 150px;
              margin-bottom: 10px;
            }
            .full-width {
              width: calc(100% - 20px);
            }

            &.display-left-panel {
              width: calc(100% - 387px); // minus width right pane

              .large-video {
                order: 0;
                position: fixed;
                width: calc(100% - 190px - 387px); // minus width right pane
                left: 10px;
              }

              .large-video.full-width {
                width: calc(100% - 387px); // minus width right pane
              }
            }
            &.large-video {
              flex-direction: column;
              align-content: flex-end;
              justify-content: start;
              flex-wrap: nowrap;
              align-items: flex-end;
              gap: 0;
              padding-bottom: 0;
            }
          }
        }

        .video-sharing-content {
          display: none;
        }
        .video-sharing-content:has(.video-container) {
          min-width: 80%;
          margin: auto;
          display: block;

          .video-container {
            width: 100%;
            height: 100%;
            video {
              width: 100%;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {

      .meeting-container {
        .meeting-content {
          .header-pannel-sp {
            display: flex;
          }
          .meeting-wrapper {
            height: calc(100svh - 240px); // minus height bottom toolbox
            flex-flow: column;

            .video-conference-page {
              height: calc(100svh - 240px); // minus height bottom toolbox
              align-content: start;

              .video-container {
                height: 192px;

                .participant-avatar {
                  width: 150px;
                  height: 150px;
                }
              }

              .large-video {
                order: 0;
                position: fixed;
                width: calc(100% - 20px);
                height: calc(100% - 260px);
                left: 10px;
              }
              .small-video {
                display: none;
              }
              .full-width {
                width: calc(100% - 20px);
              }

              &.display-left-panel {
                width: calc(100% - 387px); // minus width right pane
  
                .large-video {
                  order: 0;
                  position: fixed;
                  width: calc(100% - 20px);
                  left: 10px;
                }
  
                .large-video.full-width {
                  width: calc(100% - 20px);
                }
              }
              &.large-video {
                flex-direction: column;
                align-content: flex-end;
                justify-content: start;
                flex-wrap: nowrap;
                align-items: flex-end;
                gap: 0;
                padding-bottom: 0;
              }

              &.display-left-panel {
                width: 100%;
              }
            }
          }
        }
      }
    }
  `,
};

export const prejoinMeeting = css`
  width: 100%;
  height: 100%;

  .premeeting-screen {
    height: 100%;
    display: flex;
    background: ${COLORS.black};

    .left-content {
      width: 390px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: ${COLORS.mineShaft2};

      .header-title {
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 22px;
        color: white;
      }

      .name-input {
        width: 100%;
        height: 35px;
        color: ${COLORS.black};
        padding: 5px;
        border-radius: 12px;
        margin-top: 22px;
        margin-bottom: 16px;
      }

      .join-meeting-btn {
        width: 100%;
        height: 35px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        border-radius: 12px;
        margin-top: 22px;
        margin-bottom: 16px;
      }

      .group-btns {
        display: flex;
        column-gap: 20px;

        .settings-button {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${COLORS.white};
          border-radius: 6px;

          &:hover {
            background-color: ${COLORS.abbey};
          }

          svg {
            height: 20px;
          }
        }
      }
    }

    .right-content {
      width: calc(100% - 390px);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .video-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;

        video {
          width: 100%;
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }
  }

  @media (max-width: 767px) {

    .premeeting-screen {
      flex-flow: column-reverse;

      .left-content, .right-content {
        width: 100%;
      }

      .right-content {
        .video-wrapper {
          video {
            width: 50%;
            object-fit: unset;
            height: unset;
            border-radius: 0;
          }
        }
      }
    }
  }
`;

export const toolbox = css`
  width: 100%;
  min-height: 104px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .meeting-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .toolbox-content-items {
    display: flex;
    column-gap: 20px;

    button {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${COLORS.white};
      background: rgba(167, 167, 167, 0.15);
      border-radius: 12px;
      position: relative;

      &:hover {
        background-color: ${COLORS.abbey};
      }

      &.active-btn {
        background-color: ${COLORS.dodgerBlue};
      }

      &.danger-active-btn {
        background-color: ${COLORS.cinnabar};
      }

      .badge-count {
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: white;
        background: ${COLORS.blueGrey};
        border-radius: 100%;
        position: absolute;
        right: -4px;
        top: -3px;
        padding: 1px;

        &.unread {
          background-color: ${COLORS.cinnabar};
        }
      }
    }
  }

  .end-meeting-content {
    position: relative;

    .end-meeting-btn {
      width: 112px;
      height: 56px;
      border-radius: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: white;
      background-color: ${COLORS.cinnabar};
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-end;
    padding-top: 15px;

    .meeting-name {
      display: none;
    }

    .toolbox-content-items {
      column-gap: 16px;
      margin: auto;

      .share-btn {
        display: none;
      }
    }
  }
`;

export const sideToolbar = css`
  width: 371px;
  height: calc(100% - 16px);
  margin-top: 16px;
  border-radius: 12px;
  background-color: ${COLORS.athensGray};
  padding: 24px 16px 16px;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    padding-right: 6px;

    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: ${COLORS.mineShaft};
    }
  }

  @media (max-width: 767px) {
    width: calc(100% - 40px);
    margin: auto;
    margin-top: 20px;
    height: calc(100% - 50px);
  }
`;

export const participantList = css`
  .participant-list {
    height: calc(100% - 60px);
    overflow-y: auto;

    .title-waiting {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-bottom: 24px;
      color: ${COLORS.black};
    }

    .participant-list-waiting {
      border-bottom: 2px solid ${COLORS.silver};
      margin-bottom: 10px;
    }

    .user-group {
      display: flex;
      column-gap: 24px;
      margin-bottom: 24px;
      align-items: center;
      justify-content: space-between;

      .avatar-group {
        display: flex;
        align-items: center;
        column-gap: 24px;

        .text-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: ${COLORS.mineShaft};
          letter-spacing: 0.15px;
          word-break: break-word;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        column-gap: 22px;
      }
    }
  }
`;

export const backgroundPane = css`
  .background-list {
    max-height: calc(100% - 60px);
    display: flex;
    flex-wrap: wrap;
    column-gap: 17px;
    overflow-y: auto;

    .background-wrapper {
      width: 150px;
      height: 100px;
      margin-bottom: 16px;

      .background-image {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        box-sizing: border-box;
        align-items: center;
        object-fit: cover;
        border-radius: 12px;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }

        &.selected {
          border: 4px solid ${COLORS.dodgerBlue};
        }
      }

      .none-background {
        background-color: ${COLORS.mineShaft};
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.1px;
        color: white;
      }
    }
  }
`;

export const chatPane = css`
  .message-container {
    height: calc(100% - 60px); // minus height .header

    .message-list {
      width: 100%;
      height: calc(100% - 66px); // minus height .message-form
      overflow-y: auto;

      .message-card {
        display: flex;
        margin-bottom: 16px;

        .sender-avatar {
          width: 32px;
          margin-right: 12px;
          margin-top: 24px;
        }

        .message-card-content {
          .group-name {
            display: flex;
            align-items: center;

            .name {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: ${COLORS.mineShaft};
            }

            .date {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: ${COLORS.silverChalice};
              margin-left: 8px;
            }
          }

          .message-body {
            width: fit-content;
            background: white;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            border-radius: 12px;
            padding: 10px;
            margin-top: 8px;
            color: ${COLORS.mineShaft};
            word-break: break-word;
          }
        }
      }
    }

    .message-form {
      width: 100%;
      display: flex;
      position: relative;
      column-gap: 16px;
      padding-top: 10px;

      .message-input {
        width: calc(100% - 72px);

        input {
          width: 100%;
          height: 56px;
          border: none;
          border-radius: 12px;
        }
      }

      .send-btn {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${COLORS.cornflowerBlue};
        box-shadow: 0px 5px 12px rgba(47, 136, 241, 0.3);
        border-radius: 12px;
      }
    }
  }
`;

export const endCallAction = css`
  .ant-dropdown-menu {
    border-radius: 12px;
    padding: 14px 16px;

    .ant-dropdown-menu-item {
      padding: 0;
      border-radius: 100px;

      .ant-dropdown-menu-title-content {
        button {
          width: 200px;
          height: 48px;
          border-radius: 100px;
        }

        .end-call-btn {
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.1px;
          color: white;
          margin-bottom: 10px;
          background-color: ${COLORS.cinnabar};
        }
      }
    }
  }
`;
