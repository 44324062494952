import React, { useMemo } from 'react';
import UserAvatar from 'components/UserAvatar';
import { multiAvatar } from './styles';

const MultiAvatar = ({ chatRoom = {} }) => {
  const renderAvatar = useMemo(() => {
    const assignerCount = chatRoom?.users.length;

    return (
      <div className='multi-avatar'>
        {chatRoom.users.slice(0, 2).map((user, index) => (
          <div className={`small-avatar small-avatar-${index}`} key={`avatar-${user.id}`}>
            <UserAvatar
              size={20}
              url={user?.image}
              alt={user.name}
            />
          </div>
        ))}
        {assignerCount > 2 && <div className='user-count'>{assignerCount - 2 }</div>}
      </div>
    );
  }, [chatRoom]);

  return (
    <div className={multiAvatar}>
      {renderAvatar}
    </div>
  );
};

export default MultiAvatar;
