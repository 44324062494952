import React from 'react';
import { Spin } from 'antd';
import { commonStyles } from 'components/styles';

export const LoadingAllScreen = () => (
  <div className={commonStyles.loadingAllScreen}>
    <div className='m-auto'>
      <Spin size='large' />
    </div>
  </div>
);

export default LoadingAllScreen;
