import React from 'react';
import { Button, Modal } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputForm } from 'components/forms/InputForm';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { commonStyles } from 'components/styles';
import { modalUnlock } from './styles';

const schema = t => yup.object({
  passcode: yup.string().required(t('common.error.required')),
});
const ModalUnlock = ({
  toggle,
  submit,
}) => {
  const [t] = useTranslation();
  const { joining } = useSelector(state => state.meetings);
  const methods = useForm({
    resolver: yupResolver(schema(t)),
  });
  const { handleSubmit } = methods;

  return (
    <Modal
      title={t('meetings.show.modals.unlock.title')}
      open
      className={`${commonStyles.modal} ${modalUnlock}`}
      onCancel={toggle}
      footer={[
        <Button key='back' className='cancel-btn' onClick={toggle}>
          {t('common.actions.cancel')}
        </Button>,
        <Button key='submit' type='primary' className='save-btn' loading={joining} onClick={handleSubmit(submit)}>
          {t('common.actions.submit')}
        </Button>,
      ]}
    >
      <FormProvider {...methods}>
        <InputForm
          name='passcode'
          label={t('meetings.show.modals.unlock.passcode')}
          placeholder={t('meetings.show.modals.unlock.passcode_placeholder')}
          type='password'
        />
      </FormProvider>
    </Modal>
  );
};

export default ModalUnlock;
