import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MessagesService from '../services/MessagesService';
import { fetchChatRoom } from './ChatRooms';

export const fetchMessages = createAsyncThunk(
  'messages/FETCH_MESSAGES',
  async ({ chatRoomId, page }) => {
    const result = await MessagesService.fetchMessages(chatRoomId, page);
    return result?.data;
  },
);

export const createMessage = createAsyncThunk(
  'messages/CREATE_MESSAGE',
  async ({ formData, chatRoomId }) => {
    const result = await MessagesService.createMessage(chatRoomId, formData);
    return result?.data;
  },
);

export const readMessage = createAsyncThunk(
  'messages/READ_MESSAGE',
  async (data = {}) => {
    const result = await MessagesService.readMessage(data);
    return result?.data;
  },
);

export const deleteMessage = createAsyncThunk(
  'messages/DELETE_MESSAGE',
  async (data = {}) => {
    const result = await MessagesService.deleteMessage(data);
    return result?.data;
  },
);

export const clearMessages = createAsyncThunk(
  'messages/CLEAR_MESSAGES',
  async () => {},
);

const initialState = {
  messages: [],
  pagination: { totalPages: 1, currentPage: 1 },
  fetching: false,
  newMessageIndex: -1,
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
      state.newMessageIndex = state.messages.length - 1;
    },
    removeMessage: (state, action) => {
      const messageDeletedIndex = state.messages.findIndex(m => m.id === action.payload.id);
      if (messageDeletedIndex >= 0) {
        state.messages[messageDeletedIndex] = action.payload;
      }
    },
  },
  extraReducers: {
    [fetchMessages.pending]: state => {
      state.fetching = true;
    },
    [fetchMessages.fulfilled]: (state, action) => {
      state.pagination = action.payload.meta;
      if (state.pagination.currentPage > 1) {
        state.messages = action.payload.messages.reverse().concat(state.messages);
      } else {
        state.messages = action.payload.messages.reverse();
        state.newMessageIndex = state.messages.length - 1;
      }
      state.fetching = false;
    },
    [fetchMessages.rejected]: state => {
      state.messages = [];
      state.pagination = {};
      state.fetching = false;
    },
    [fetchChatRoom.pending]: state => {
      state.messages = [];
    },
    [fetchChatRoom.fulfilled]: state => {
      state.newMessageIndex = -1;
    },
    [fetchChatRoom.rejected]: state => {
      state.messages = [];
    },
    [clearMessages.fulfilled]: state => {
      state.messages = [];
    },
  },
});

const { reducer } = messagesSlice;
export default reducer;
export const { addMessage, removeMessage } = messagesSlice.actions;
