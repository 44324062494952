import api from 'redux/api-common';
import { camelCaseObject } from 'utils';

const fetchMessages = (chatRoomId, page) => api
  .get(`chat_rooms/${chatRoomId}/messages?page=${page}`)
  .then(response => camelCaseObject(response.data));

const createMessage = (chatRoomId, data) => api
  .post(`chat_rooms/${chatRoomId}/messages`, data)
  .then(response => camelCaseObject(response.data));

const readMessage = ({ params: { chat_room_id, message_id } }) => api
  .post(`chat_rooms/${chat_room_id}/messages/${message_id}/read_message`)
  .then(response => camelCaseObject(response.data));

const updateMessage = (chatRoomId, id, data) => api
  .put(`chat_rooms/${chatRoomId}/messages/${id}`, data)
  .then(response => camelCaseObject(response.data));

const deleteMessage = ({ chatRoomId, id }) => api
  .delete(`chat_rooms/${chatRoomId}/messages/${id}`)
  .then(response => camelCaseObject(response.data));

export default {
  fetchMessages,
  createMessage,
  readMessage,
  updateMessage,
  deleteMessage,
};
