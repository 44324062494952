import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserMenu from 'components/UserMenu';

const HeaderBar = () => {
  const [t] = useTranslation();
  const { currentUser } = useSelector(state => state.auth);
  if (currentUser?.role !== 'system_admin') {
    return;
  }

  return (
    <div className='header-page'>
      <div className='content-header'>
        <div className='title'>
          {t('pages.profile')}
        </div>
        <div className='content-header-right'>
          <UserMenu size={48} page='profile' />
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
