import React, { useEffect } from 'react';
import {
  Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from 'redux/slices/auth';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { defaultRootPath } from 'utils';
import { fetchMeeting } from 'redux/slices/meetings';
import PrivateRoute from './components/PrivateRoute';
import TeamLayout from './components/TeamLayout';
import Home from './pages/home';
import ThankYou from './pages/landing-page/ThankYou';
import Privacy from './pages/landing-page/Privacy';
import Terms from './pages/landing-page/Terms';
import ForgotPassword from './pages/home/ForgotPassword';
import ResetPassword from './pages/home/ResetPassword';
import Login from './pages/home/Login';
import CustomerLogin from './pages/home/CustomerLogin';
import Profile from './pages/profile';
import Organizations from './pages/organizations';
import Users from './pages/users';
import Chat from './pages/chat';
import Meetings from './pages/meetings';
import MeetingShow from './pages/meetings/Show';
import CustomMeeting from './pages/meetings/Show/custom_meeting';

const NoMatch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector(state => state.auth);
  // redirect to default path if no match
  useEffect(() => {
    // support old meeting link type, redirect to new type
    const isMeetingPath = pathname.match(/\/meetings\/.+/);
    if (isMeetingPath) {
      const code = pathname.match(/\/meetings\/([^/]+)/)[1];
      dispatch(fetchMeeting({ params: { id: code } })).unwrap().then(meetingData => {
        navigate(`/organizations/${meetingData.organizationKey}${pathname}`);
      });
      return;
    }

    if (isLoggedIn) {
      dispatch(getProfile()).unwrap().then(data => {
        const { user } = data;
        navigate(defaultRootPath(user));
      });
    } else {
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingAllScreen />;
};

const Router = () => (
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/thank_you' element={<ThankYou />} />
    <Route path='/privacy' element={<Privacy />} />
    <Route path='/terms' element={<Terms />} />
    <Route path='/login' element={<Login />} />
    <Route path='/customer_login' element={<CustomerLogin />} />
    <Route path='/forgot_password' element={<ForgotPassword />} />
    <Route path='/reset_password' element={<ResetPassword />} />
    <Route path='/profile' element={<PrivateRoute Component={Profile} page='Profile' />} />
    <Route path='/organizations' element={<PrivateRoute Component={Organizations} page='Organizations' />} />
    <Route path='/organizations/:organizationKey/custom_meeting/:code' element={<MeetingShow />} />
    <Route path='/organizations/:organizationKey/meetings/:code' element={<CustomMeeting />} />

    <Route path='/organizations/:organizationKey/users' element={<TeamLayout Component={Users} page='Users' />} />
    <Route path='/organizations/:organizationKey/meetings' element={<TeamLayout Component={Meetings} page='Meetings' />} />
    <Route path='/organizations/:organizationKey/chat' element={<TeamLayout Component={Chat} page='Chat' />} />
    <Route path='/organizations/:organizationKey/chat/:id' element={<TeamLayout Component={Chat} page='Chat' />} />
    <Route path='*' element={<NoMatch />} />
  </Routes>
);

export default Router;
