import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { ConfirmationModal } from './ConfirmationModal';

const confirm = props => new Promise(resolve => {
  let el = document.createElement('div');
  const {
    close, submit, submitAction, cancelAction,
  } = props;

  const handleResolve = result => {
    unmountComponentAtNode(el);
    el = null;
    document.activeElement.blur();
    resolve(result);
  };
  const closeResolve = () => {
    close && close();
    handleResolve(false);
  };
  const submitResolve = () => {
    submitAction && submitAction();
    !submitAction && submit && submit();
    handleResolve(true);
  };

  const cancelResolve = () => {
    cancelAction && cancelAction();
    !cancelAction && close && close();
    handleResolve(false);
  };

  render(<ConfirmationModal {...props} close={closeResolve} submit={submitResolve} cancel={cancelResolve} />, el);
});

export default confirm;
