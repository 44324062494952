// eslint-disable-next-line no-extend-native
Number.prototype.pad = function (size) {
  let numberAsString = String(this);
  while (numberAsString.length < size) {
    numberAsString = `0${numberAsString}`;
  }
  return numberAsString;
};

if (!RegExp.escape) {
  RegExp.escape = function (s) {
    return String(s).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
  };
}
