import {
  words, toString, upperFirst, lowerFirst, isNumber, isEmpty,
} from 'lodash';

export const camelCase = (string, options = {}) => {
  const re = words(toString(string).replace(/['\u2019]/g, ''), /[^_\s]+/g).reduce((result, word, index) => result + (index ? upperFirst(word) : lowerFirst(word)), '');
  return options.upperFirst ? upperFirst(re) : re;
};

export const camelCaseObject = (object, ignoreList = []) => {
  let obj = { ...object };
  if (typeof object === 'object' && object !== null) {
    if (Array.isArray(object)) {
      obj = object.map(item => camelCaseObject(item, ignoreList));
    } else {
      Object.keys(obj).forEach(key => {
        let tmp = obj[key];
        if (!ignoreList.includes(key)) {
          if (typeof tmp === 'object' && tmp !== null) {
            if (Array.isArray(tmp)) {
              tmp = tmp.map(item => camelCaseObject(item, ignoreList));
            } else {
              tmp = camelCaseObject(tmp, ignoreList);
              obj[key] = tmp;
            }
          }
        }

        delete obj[key];
        if (tmp === null) { tmp = undefined; }
        obj[camelCase(key)] = tmp;
      });
    }
    return obj;
  }
  return object === null ? undefined : object;
};

export const isBlank = value => {
  if (value === true || isNumber(value) || value instanceof File || value instanceof Date) { return false; }
  return isEmpty(value);
};

export const isPresent = value => !isBlank(value);

export const scrollToTop = ele => window.scrollTo({
  top: ele ? ele.getBoundingClientRect().top + window.scrollY - 50 : 0,
  behavior: 'smooth',
});

export const defaultRootPath = ({ organizations = [] }) => `/organizations/${organizations[0]?.key}/chat`;

export const getOrganizationKey = () => window.location.pathname.match(/\/organizations\/([^/]+)/)?.[1];
