import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react';
import {
  Button, Modal, Input, Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchSvg } from 'icons/Search.svg';
import { isBlank } from 'utils';
import UserAvatar from 'components/UserAvatar';
import { commonStyles } from 'components/styles';
import { fetchUsers } from 'redux/slices/users';
import { assignNewModal } from './styles';
import { getSelectedUser } from './utils';

const AssignNewModal = ({
  chatRoomId,
  handleCancel,
  handleAssignMember,
  isSubmitting,
  members,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.users);
  const [selectedUsers, setSelectedUsers] = useState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchUsers = useCallback(debounce(keyword => {
    dispatch(fetchUsers({
      params: { keyword },
    }));
  }, 500), []);

  const handleSave = useCallback(() => {
    handleAssignMember(chatRoomId, getSelectedUser(selectedUsers), () => {
      handleCancel();
    });
  }, [handleAssignMember, selectedUsers, chatRoomId, handleCancel]);

  useEffect(() => {
    onSearchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnToggleSelected = useCallback((userId, isSelected) => {
    setSelectedUsers(selected => ({ ...selected, [userId]: !isSelected }));
  }, []);

  const userContent = useMemo(() => {
    const filterUsers = [];
    users.forEach(user => {
      if (!members.some(member => member.id === user.id)) {
        filterUsers.push(user);
      }
    });

    return filterUsers.map((user, index) => (
      <div className='user-card' key={index}>
        <Checkbox
          checked={selectedUsers[user.id] || false}
          onClick={e => { handleOnToggleSelected(user.id, selectedUsers[user.id]); e.preventDefault(); }}
        />
        <div className='image-wrapper'>
          <UserAvatar size={48} url={user.image} alt={user.name} />
        </div>
        <div className='user-info'>
          <span className='name'>{user.name}</span>
          <span className='user-title'>{user.email}</span>
        </div>
      </div>
    ));
  }, [handleOnToggleSelected, members, selectedUsers, users]);

  return (
    <Modal
      title={t('chat_rooms.modals.assign_new_modal.title')}
      open
      onCancel={handleCancel}
      className={`${commonStyles.modal} ${assignNewModal}`}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={isSubmitting}
          disabled={isBlank(getSelectedUser(selectedUsers))}
          className='save-btn'
          onClick={handleSave}
        >
          {t('chat_rooms.modals.assign_new_modal.assign')}
        </Button>,
      ]}
    >
      <div className='form-content'>
        <Input
          prefix={<SearchSvg className='icon-search' />}
          placeholder={t('common.actions.search')}
          className='search-input'
          onChange={e => onSearchUsers(e.target.value)}
        />
        <div className='user-list'>
          {userContent}
        </div>
      </div>
    </Modal>
  );
};

export default AssignNewModal;
