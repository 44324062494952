import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';

export const styles = {
  index: css`
    .meeting-page {
      width: 100%;
      display: flex;

      .meeting-content {
        width: 380px;
        height: 100vh;
        border-right: 1px solid ${COLORS.mercury};

        .btn-group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 10px;
          padding: 24px 16px 0 16px;

          .title {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
          }

          .button {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: ${COLORS.mineShaft};
          }

          .schedule-btn {
            display: flex;
            align-items: center;
            column-gap: 12px;
            color: ${COLORS.dodgerBlue};
          }

          .default-btn {
            width: 166px;
            height: 40px;
            border-radius: 100px;
          }
        }

        .list-meetings {
          width: 100%;
          height: calc(100% - 118px);
          overflow-y: auto;
          padding: 8px 6px;
          position: relative;

          .date {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: ${COLORS.black};
            padding: 16px 10px;
          }

          .meeting-card {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            padding: 16px 10px;
            border-radius: 8px;
            font-weight: 500;
            line-height: 20px;
            word-break: break-all;
            cursor: pointer;

            &.active {
              background-color: ${COLORS.wildSand};
            }

            .meeting-name {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: ${COLORS.mineShaft};
            }

            .time, .code {
              font-size: 14px;
              letter-spacing: 0.1px;
              color: ${COLORS.silverChalice};
            }
          }
        }
      }

      .meeting-details {
        width: calc(100% - 380px);
        position: relative;

        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid ${COLORS.mercury};
          padding: 24px 16px;

          .left-header {
            .meeting-name {
              font-weight: 600;
              font-size: 22px;
              line-height: 28px;
              color: ${COLORS.mineShaft};
              margin-bottom: 8px;
            }

            .datetime {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: ${COLORS.silverChalice};
            }
          }

          .right-header {
            .dots-icon {
              width: 21px;
              height: 26px;
              padding: 5px;
              cursor: pointer;

              &:hover {
                background-color: ${COLORS.wildSand};
              }
            }
          }
        }

        .meeting-info {
          width: 100%;
          padding: 16px;

          .label {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: ${COLORS.mineShaft};
            margin-bottom: 2px;
          }

          .description {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color:  ${COLORS.silverChalice};
            margin-bottom: 5px;
          }

          .user-group {
            display: flex;
            align-items: center;
            column-gap: 16px;
            margin-bottom: 16px;
          }

          .join-btn {
            min-width: 77px;
            height: 40px;
            margin-top: 24px;
            border-radius: 100px;
            font-weight: 500;
            line-height: 20px;
          }

          .remove-btn {
            width: fit-content;
            display: flex;
            align-items: center;
            padding: 6px;
            margin-top: 18px;
            color: ${COLORS.cinnabar};
            cursor: pointer;

            .text {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.1px;
              margin-left: 14px;
            }
          }
        }
      }
    }
  `,
};
