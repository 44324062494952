import React, { useMemo } from 'react';
import { Input, Button } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputForm from 'components/forms/InputForm';
import { createContact } from 'redux/slices/contacts';

const schema = t => yup.object({
  name: yup.string().required(t('common.error.required')).max(100, t('common.error.max_length', { max: 100 })),
  furigana_name: yup.string().required(t('common.error.required')).max(100, t('common.error.max_length', { max: 100 })),
  email: yup.string().required(t('common.error.required')).email(t('common.error.email_required')).max(250, t('common.error.max_length', { max: 100 })),
  phone: yup.string().matches(/^([0-9]*)$/, t('common.error.phone_number_required')).nullable().required(t('common.error.required'))
    .max(20, t('common.error.max_length', { max: 20 })),
  content: yup.string().nullable().max(250, t('common.error.max_length', { max: 1000 })),
});

const ContactForm = () => {
  const [t] = useTranslation();
  const { isSubmitting } = useSelector(state => state.contacts);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues = useMemo(() => ({
    name: '',
    furigana_name: '',
    email: '',
    content: '',
    phone: '',
  }), []);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema(t)),
    defaultValues,
  });

  const {
    handleSubmit, formState: { isValid },
  } = methods;

  const saveUser = values => {
    dispatch(createContact(values)).unwrap().then(() => { navigate('/thank_you'); });
  };

  return (
    <section id='contact' className='contact-form'>
      <div className='background-text'>
        CONTACT
      </div>
      <div className='title'>
        お問い合わせ
      </div>
      <FormProvider {...methods}>
        <div className='ant-form'>
          <div className='description'>
            Coffret Compass についてのお問い合わせは以下のフォームに必要事項をご入力の上、ご登録ください。
          </div>
          <div className='form-group'>
            <div className='label'>
              お名前
              <span className='required'>必須</span>
            </div>
            <InputForm name='name' placeholder='例）田中太郎' />
          </div>
          <div className='form-group'>
            <div className='label'>
              お名前（フリガナ）
              <span className='required'>必須</span>
            </div>
            <InputForm name='furigana_name' placeholder='例）タナカタロウ' />
          </div>
          <div className='form-group'>
            <div className='label'>
              電話番号
              <span className='required'>必須</span>
            </div>
            <InputForm name='phone' placeholder='例）0312345678' />
          </div>
          <div className='form-group'>
            <div className='label'>
              メールアドレス
              <span className='required'>必須</span>
            </div>
            <InputForm name='email' placeholder='例）info@example.com' />
          </div>
          <div className='form-group'>
            <div className='label'>
              お問い合わせ内容
            </div>
            <InputForm
              name='content'
              placeholder='Coffret Compass 導入に関するご相談、サービスやソリューションについてのお問い合わせやご質問、ご希望などをご記入ください。'
              Component={Input.TextArea}
              autoSize={{ minRows: 10, maxRows: 10 }}
            />
          </div>

          <div className='policy-text'>
            当社の
            <a href='/privacy' target='_blank'>プライバシーポリシー</a>
            に同意したものとします。
          </div>

          <div className='flex'>
            <Button className='submit-btn' loading={isSubmitting} type='submit' disabled={!isValid} onClick={handleSubmit(saveUser)}>
              問い合わせる
            </Button>
          </div>
        </div>
      </FormProvider>
    </section>
  );
};

export default ContactForm;
