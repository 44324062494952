import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { Select } from 'antd';
import { customSelectForm } from './styles';

export const SelectForm = ({
  label, name, rules = {}, className, defaultValue,
  inputClassName = 'w-full', errorClassName = 'text-red-600',
  required, labelClass, hidden, ...rest
}) => {
  const { control } = useFormContext();
  if (hidden) { return null; }

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue || null}
      render={({ field, fieldState: { error } }) => (
        <div className={`${className || customSelectForm} select-form`}>
          {label && (
            <label className={labelClass || 'text-stone-800'} htmlFor={name}>
              <span className='align-middle'>{label}</span>
            </label>
          )}
          <Select
            optionFilterProp='label'
            {...field}
            className={classNames(inputClassName, { 'disabled-form': rest.isDisabled })}
            ref={rest.innerRef}
            {...rest}
          />
          {error?.message && (
            <span className={errorClassName}>{error.message}</span>
          )}
        </div>
      )}
    />
  );
};
