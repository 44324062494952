import { css } from '@emotion/css';
import { COLORS, truncateMultiline } from 'utils/constants';

export const styles = {
  index: css`
    .chat-page {
      display: flex;
      width: 100%;
      height: 100svh;

      .chat-room-content {
        width: 380px;
      }

      .message-content {
        width: calc(100% - 380px - 370px); // minus width chat-room-content and member-content
        background-color: ${COLORS.athensGray};
      }

      .member-content {
        width: 370px;
        background-color: ${COLORS.athensGray};
        border-left: 1px solid ${COLORS.mercury};
      }

      .mobile-chat-buttons {
        display: none;
      }

      .filter-btn {
        text-align: right;
        cursor: pointer;
        padding: 2px 10px 0px 10px;

        .ant-dropdown-menu-item:hover {
          background-color: ${COLORS.dodgerBlue};
          color: ${COLORS.white};
        }

        .filter-popover {
          .ant-popover-inner-content {
            padding: 0;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      .chat-page {
        height: calc(100svh - 76px);

        .chat-room-content {
          .title {
            display: none;
          }
        }

        .message-content {
          width: calc(100% - 380px);
        }

        .message-container {
          height: calc(100svh - 164px);

          .message-list {
            height: calc(100svh - 252px);
          }
        }
      }
    }

    @media (max-width: 767px) {
      .chat-page {
        height: calc(100% - 76px);

        .chat-room-content {
          width: 100%;
          display: block;

          .chat-room-list {
            height: calc(100svh - 92px);
          }
        }
        .message-content {
          display: none;
          width: 100%;

          .header {
            display: none;
          }

          .message-container {
            min-height: 440px;
            height: calc(100svh - 75px);

            .message-form {
              .input-group {
                width: 100%;
              }
            }
            .message-list {
              height: 100%;
              padding-bottom: 10px;
            }

            .add-file-btn, .add-emoji-btn, .send-btn {
              display: none;
            }

            .mobile-chat-buttons {
              display: flex;
              margin-top: 10px;

              .add-file-btn, .add-emoji-btn {
                display: block;
                width: 56px;
                height: 56px;
                margin-right: 16px;
                background-color: ${COLORS.white};
                box-shadow: 0px 5px 12px 0px #00436514;
                border-radius: 12px;

                svg {
                  margin: auto;
                }
              }

              .send-btn {
                display: block;
                margin-left: auto;
                align-self: unset;

                svg {
                  margin: auto;
                }
              }
            }
          }
        }

        .chat-room-content {
          .chat-room-card.active-chat {
            background: unset;
          }
        }
      }

      .display-messages {
        .chat-room-content {
          display: none;
        }

        .message-content {
          display: block;
        }
      }
    }
  `,
  chatRooms: css`
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: ${COLORS.mineShaft};
      padding: 24px 16px 0 16px;
    }

    .chat-mode {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid ${COLORS.mercury};
      margin-top: 20px;

      button {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: ${COLORS.silverChalice};
        padding-bottom: 9px;
        margin-bottom: 2px;

        &.active {
          color: ${COLORS.dodgerBlue};
          border-bottom: 3px solid ${COLORS.dodgerBlue};
          margin-bottom: 0;
        }
      }
    }

    .chat-room-list {
      width: 100%;
      padding: 8px 6px;
      overflow-y: auto;
      position: relative;
      flex: 1;

      .chat-room-card {
        display: flex;
        width: 100%;
        min-height: 80px;
        padding: 16px 10px;
        border-radius: 8px;

        .large-avatar {
          border-radius: 50%;

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &.active-chat {
          background: ${COLORS.wildSand};
        }

        .avatar-group {
          width: 48px;
        }

        .chat-room-info {
          width: calc(100% - 48px);
          margin-left: 30px;

          .organization-name {
            font-size: 11px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.5px;
            text-align: left;
            color: ${COLORS.athensGray2};
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
          }

          .group-name {
            display: flex;
            align-items: center;

            .name {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: ${COLORS.mineShaft};
              margin-right: 16px;
              ${truncateMultiline(1)}
            }

            .date {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.1px;
              color: ${COLORS.silverChalice};
              margin-top: 3px;
            }
          }

          .new-message {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            width: 100%;;
            color: ${COLORS.silverChalice};
            ${truncateMultiline(1)}
          }

          .dot-unread {
            display: none;
          }
        }

        &.unread-message {
          .chat-room-info {
            .group-name .date, .new-message {
              font-weight: 500;
              color: ${COLORS.mineShaft};
            }
          }

          .dot-unread {
            display: block;
            margin: auto 0 auto 20px;

            .dot {
              display: inline-block;
              height: 10px;
              width: 10px;
              background-color: ${COLORS.dodgerBlue};
              border-radius: 50%;
            }
          }
        }
      }

      .no-chat-rooms {
        margin-top: 200px;
        text-align: center;
        color: ${COLORS.athensGray2};
      }
    }
  `,
  messages: css`
    .header {
      width: 100%;
      height: 88px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${COLORS.mercury};

      .left-header, .right-header {
        display: flex;
        align-items: center;

        .name {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: ${COLORS.mineShaft};
          margin-left: 16px;
        }

        .dots-icon {
          width: 21px;
          height: 26px;
          padding: 5px;
          margin-left: 30px;
          cursor: pointer;

          &:hover {
            background-color: ${COLORS.wildSand};
          }
        }
      }
    }


    .message-container {
      width: 100%;
      height: calc(100svh - 88px);
      display: flex;
      flex-direction: column;

      .message-list {
        flex-grow: 1;
        overflow-y: auto;

        .message-card {
          display: flex;
          padding: 16px 16px 0 16px;

          &.same-time-message {
            padding-top: 0;
          }

          .sender-avatar {
            width: 32px;
            min-width: 32px;
            margin-right: 12px;
          }

          .message-card-content {
            .group-name {
              display: flex;
              align-items: center;

              .name {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: ${COLORS.mineShaft};
              }

              .date {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: ${COLORS.silverChalice};
                margin-left: 8px;
                margin-top: 3px;
              }
            }

            .message-body {
              display: flex;

              .received-msg {
                width: fit-content;
                background: white;
                border-radius: 12px;
                padding: 10px;
                margin-top: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: ${COLORS.mineShaft};
                word-break: break-word;
                white-space: pre-line;

                &.deleted {
                  width: 668px;
                  color: ${COLORS.athensGray2};
                }
              }

              .file-chat-box {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                margin-top: 8px;

                .image-card {
                  width: 150px;
                  height: 100px;
                  object-fit: cover;
                }

                .video-card {
                  width: auto;
                  max-height: 115px;
                }

                .file-card {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  background-color: ${COLORS.linkWater};
                  padding: 5px;

                  .file-name {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    ${truncateMultiline(3)}
                  }

                  .file-icon {
                    width: 16px;
                    color: ${COLORS.bayOfMany};
                  }
                }

                .anticon-eye svg {
                  vertical-align: baseline;
                }
              }

              .trash-icon {
                align-self: flex-end!important;
                margin-bottom: 0px;
                margin-left: 5px;
                cursor: pointer;
                color: ${COLORS.silver2};
                min-width: 16px;
              }
            }
          }
        }
      }

      .message-form {
        width: 100%;
        padding: 16px;

        .input-group {
          width: calc(100% - 56px);
          background: white;
          box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
          border-radius: 12px;
          display: flex;
          align-items: center;
          column-gap: 22px;
          padding-left: 20px;
          position: relative;
          padding: 10px;

          .message-input{
            width: 100%;

            textarea {
              width: calc(100% - 10px);
              height: 23px;
              border: none;
              border-radius: 12px;
              resize: none;
              max-height: calc(22px * 10 + 10px);
            }
          }

          .emoji-picker {
            position: absolute;
            left: 0;
            bottom: 56px;
            z-index: 5;
          }

          .attachment-file {
            display: none;
          }
        }

        .send-btn {
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          align-self: flex-end;
          justify-content: center;
          margin-left: 16px;
          background: ${COLORS.cornflowerBlue};
          box-shadow: 0px 5px 12px rgba(47, 136, 241, 0.3);
          border-radius: 12px;

          &:disabled {
            background: ${COLORS.cornflowerBlue}50;
          }
        }

        .add-emoji-btn, .add-file-btn {
          align-self: flex-end;
          margin-bottom: 6px;
        }
      }
    }

    @media (max-width: 1600px) {
      .message-container .message-list .message-card .message-card-content .message-body {
        .received-msg {
          &.deleted {
            width: 300px;
          }
        }
      }
    }
  `,
  members: css`
    width: 100%;
    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 24px 16px 16px 16px;

      .title {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: ${COLORS.mineShaft};
      }

      .assign-btn {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: ${COLORS.dodgerBlue};
        align-items: center;

        path {
          stroke: ${COLORS.dodgerBlue};
        }

        .text {
          margin-left: 10px;
        }
      }
    }

    .no-assigned {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100% - 270px);

      .no-agent {
        margin-top: 33px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.1px;
        color: ${COLORS.silverChalice};
      }
    }

    .assigned-list {
      width: 100%;
      height: calc(100% - 70px);
      padding: 0 16px;
      overflow-y: auto;
      position: relative;

      .assigned-card {
        background-color: white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        margin-bottom: 16px;
        padding: 24px 16px;

        .group-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;
          border-bottom: 1px solid ${COLORS.mercury};
          position: relative;

          .text-name {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            color: ${COLORS.mineShaft};
            margin-top: 8px;
            margin-bottom: 4px;
          }

          .text-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: ${COLORS.silverChalice};
            margin-bottom: 24px;
          }

          .dots-icon {
            width: 21px;
            position: absolute;
            top: 0;
            right: 0;
            height: 26px;
            padding: 5px;
            cursor: pointer;

            &:hover {
              background-color: ${COLORS.wildSand};
            }
          }
        }

        .info {
          margin-bottom: 16px;

          label {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: ${COLORS.mineShaft};
            margin-bottom: 2px;
          }

          .description {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            word-break: break-all;
            color: ${COLORS.silverChalice};
            white-space: break-spaces;
          }
        }
      }
    }
  `,
  modal: css`
    width: 520px !important;

    .new-form {
      display: flex;
      column-gap: 10px;

      .form-content {
        width: 100%;

        .select-field {
          width: 100%;

          .ant-select-selector {
            min-height: 35px;
          }
        }
      }
    }
  `,
  deleteMessageModal: css`
    .ant-modal-content {
      padding: 24px;
      margin: 10px;

      .ant-modal-header {
        text-align: center;
        margin-bottom: 20px;
        padding: 16px 0 0 0;
      }

      .ant-modal-body {
        display: none;
      }

      .ant-modal-footer{
        display: flex;
        justify-content: center;

        .save-btn {
          margin-right: 8px;
          border: none;
          min-width: 120px;
        }
        .cancel-btn {
          margin-left: 8px;
          background-color: ${COLORS.athensGray2};
          color: ${COLORS.white};
          border: none;
          min-width: 120px;
        }
      }
    }
  `,
};

export const dropdownActions = css`
  .ant-dropdown-menu {
    box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
    border-radius: 12px;
    padding: 0;

    .ant-dropdown-menu-item {
      padding: 18px 16px;
      height: 56px;
      border-radius: 12px;

      .item-content {
        display: flex;
        align-items: center;

        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          min-width: 100px;
          color: ${COLORS.mineShaft};
        }

        .unassign {
          color: ${COLORS.cinnabar};
          margin-left: 14px;
        }
      }
    }
  }
`;

export const previewFiles = css`
  bottom: 56px;
  display: flex;

  .preview {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    gap: 10px;
    position: relative;

    .file-content {
      .img-wrp, .video-wrp {
        width: 60px;
        height: 60px;
        max-width: unset;
        object-fit: cover;
        border-radius: 12px;
      }

      .file-wrp {
        width: 60px;
        height: 60px;
        background-color: ${COLORS.linkWater};
        padding: 5px;

        .file-name {
          font-weight: 700;
          font-size: 10px;
          line-height: 16px;
          ${truncateMultiline(2)}
        }

        .file-icon {
          width: 10px;
          height: 12px;
          color: ${COLORS.bayOfMany};
        }
      }
    }
  }

  .remove-file {
    align-self: flex-end;
    margin-left: 10px;
    color: ${COLORS.cinnabar};
  }
`;

export const assignNewModal = css`
  width: 482px !important;

  .ant-modal-body {
    margin-bottom: 16px;

    .form-content {
      .search-input {
        width: 100%;
        height: 56px;
        border-radius: 100px;

        .ant-input-prefix {
          margin-left: 9px;
          margin-right: 19px;
        }
      }

      .user-list {
        .user-card {
          width: 100%;
          display: flex;
          align-items: center;
          padding-top: 16px;

          .image-wrapper {
            margin-left: 28px;
            margin-right: 24px;
          }

          .user-info {
            display: flex;
            flex-direction: column;

            .name {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: ${COLORS.mineShaft};
              margin-bottom: 8px;
              ${truncateMultiline(2)}
            }

            .user-title {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.1px;
              color: ${COLORS.silverChalice};
              ${truncateMultiline(2)}
            }
          }
        }
      }
    }
  }
`;

export const multiAvatar = css`
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;

  .multi-avatar {
    position: relative;
    z-index: 1;
    display: flex;

    .small-avatar {
      border-radius: 50%;

      &.small-avatar-0 {
        width: 10px;
        z-index: 3;
      }

      &.small-avatar-1 {
        z-index: 2;
      }

      img {
        border: 1px solid white;
      }
    }

    .user-count {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;
      border-radius: 50%;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: white;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      z-index: 4;
    }
  }
`;
