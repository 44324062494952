import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getOrganizationKey } from 'utils';
import { set } from 'lodash';
import ChatRoomsService from '../services/ChatRoomsService';

export const fetchChatRooms = createAsyncThunk(
  'chatRooms/FETCH_CHAT_ROOMS',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await ChatRoomsService.fetchChatRooms(data);
    return result?.data;
  },
);

export const fetchChatRoom = createAsyncThunk(
  'chatRooms/FETCH_CHAT_ROOM',
  async chatRoomId => {
    const result = await ChatRoomsService.fetchChatRoom({ params: { id: chatRoomId, organization_key: getOrganizationKey() } });
    return result?.data;
  },
);

export const assignMembers = createAsyncThunk(
  'chatRooms/ASSIGN_MEMBERS',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await ChatRoomsService.assignMembers(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result?.data;
  },
);

export const unassignMember = createAsyncThunk(
  'chatRooms/UNASSIGN_MEMBER',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await ChatRoomsService.unassignMember(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result?.data;
  },
);

export const createChatRoom = createAsyncThunk(
  'chatRooms/CREATE_CHAT_ROOM',
  async data => {
    data.organization_key = getOrganizationKey();
    const result = await ChatRoomsService.createChatRoom(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result?.data;
  },
);

export const updateChatRoom = createAsyncThunk(
  'chatRooms/UPDATE_CHAT_ROOM',
  async data => {
    const { showNotice = true } = data;
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await ChatRoomsService.updateChatRoom(data);

    if (showNotice) {
      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    }

    return result?.data;
  },
);

export const clearChatRoom = createAsyncThunk(
  'chatRooms/CLEAR_CHAT_ROOM',
  async () => {},
);

export const clearChatRooms = createAsyncThunk(
  'chatRooms/CLEAR_CHAT_ROOMS',
  async () => {},
);

const initialState = {
  chatRooms: [],
  chatRoom: {},
  fetching: false,
  fetchingChatRoom: false,
  isSubmitting: false,
};

const ChatRoomsSlice = createSlice({
  name: 'chatRooms',
  initialState,
  reducers: {
    updateChatRoomList: (state, _action) => {
      const isRoomExist = state.chatRooms.some(room => room.id === _action.payload.id);
      if (isRoomExist) {
        state.chatRooms = state.chatRooms.map(room => (
          room.id === _action.payload.id ? _action.payload : room
        ));
      } else {
        state.chatRooms.push(_action.payload);
      }
      if (state.chatRoom.id === _action.payload.id) {
        state.chatRoom = {
          ...state.chatRoom,
          ..._action.payload,
        };
      }
    },
  },
  extraReducers: {
    [fetchChatRooms.pending]: state => {
      state.fetching = true;
    },
    [fetchChatRooms.fulfilled]: (state, _action) => {
      const {
        meta: {
          arg: {
            loadMore,
            params: { page },
          },
        },
      } = _action;

      if (loadMore && page > 1) {
        state.chatRooms = [
          ...state.chatRooms,
          ..._action.payload.chatRooms,
        ];
      } else {
        state.chatRooms = _action.payload.chatRooms;
      }
      state.fetching = false;
    },
    [fetchChatRooms.rejected]: state => {
      state.chatRooms = [];
      state.fetching = false;
    },
    [fetchChatRoom.pending]: state => {
      state.fetchingChatRoom = true;
    },
    [fetchChatRoom.fulfilled]: (state, _action) => {
      state.chatRoom = _action.payload.chatRoom;
      state.fetchingChatRoom = false;
    },
    [fetchChatRoom.rejected]: state => {
      state.chatRoom = {};
      state.fetchingChatRoom = false;
    },
    [assignMembers.pending]: state => {
      state.isSubmitting = true;
    },
    [assignMembers.fulfilled]: state => {
      state.isSubmitting = false;
    },
    [assignMembers.rejected]: state => {
      state.isSubmitting = false;
    },
    [unassignMember.pending]: state => {
      state.isSubmitting = true;
    },
    [unassignMember.fulfilled]: state => {
      state.isSubmitting = false;
    },
    [unassignMember.rejected]: state => {
      state.isSubmitting = false;
    },
    [updateChatRoom.pending]: state => {
      state.isSubmitting = true;
    },
    [updateChatRoom.fulfilled]: (state, _action) => {
      state.chatRoom = _action.payload.chatRoom;
      state.chatRooms = state.chatRooms.map(chatRoom => (
        chatRoom.id === _action.payload.chatRoom.id ? _action.payload.chatRoom : chatRoom
      ));
      state.isSubmitting = false;
    },
    [updateChatRoom.rejected]: state => {
      state.isSubmitting = false;
    },
    [clearChatRoom.fulfilled]: state => {
      state.chatRoom = {};
    },
    [clearChatRooms.fulfilled]: state => {
      state.chatRooms = [];
    },
  },
});

const { reducer } = ChatRoomsSlice;
export const { updateChatRoomList, updateNewestMessage } = ChatRoomsSlice.actions;
export default reducer;
