import React from 'react';
import { Spin } from 'antd';
import { commonStyles } from 'components/styles';

export const Loading = ({ className, size = 'large', style }) => (
  <div className={className || commonStyles.loading} style={style}>
    <Spin size={size} />
  </div>
);

export default Loading;
