import moment from 'moment';
import { formatDate } from 'utils/date';
import { isBlank } from 'utils';

export const getSelectedUser = selectedUsers => Object.keys(selectedUsers).filter(key => selectedUsers[key]);

export const isSameTimeMessage = (message, previousMessage) => message?.user.id === previousMessage?.user.id
  && (formatDate(message.createdAt, 'YYYY/MM/DD-HH:mm') === formatDate(previousMessage.createdAt, 'YYYY/MM/DD-HH:mm'));

export const isUnreadMessage = (currentUser, chatRoom) => {
  if (isBlank(chatRoom.newestMessage)) {
    return false;
  }

  const currentUserReadMessage = chatRoom.readHistories.find(read => read.userId === currentUser.id);
  return chatRoom.newestMessage.id !== currentUserReadMessage?.messageId;
};

export const messageDateFormat = (date, sameDay = '[Today]') => {
  if (!date) return;

  return moment(date).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay,
    lastWeek: 'YYYY/MM/DD',
    sameElse() {
      return 'YYYY/MM/DD';
    },
  });
};

export const replaceContentWithLinks = text => {
  // Regular expression to match URLs
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig;
  const replaceTexts = [];

  // Function to replace URLs with HTML anchor tags
  const replacedText = text.replace(urlRegex, url => {
    const key = `__REPLACE_${new Date().getTime()}__`;
    replaceTexts.push({ key, value: `<a href="${url}" target="_blank">${url}</a>` });
    return key;
  });

  if (replaceTexts.length > 0) {
    // Encode HTML tags
    let encodedText = replacedText.replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
    replaceTexts.forEach(replaceText => {
      encodedText = encodedText.replace(replaceText.key, replaceText.value);
    });
    // eslint-disable-next-line react/react-in-jsx-scope
    return <div dangerouslySetInnerHTML={{ __html: encodedText }} />;
  }

  return text;
};

export const chatRoomHeaderUser = ({ currentUser, chatRoom }) => {
  if (currentUser.role === 'customer') {
    return chatRoom?.users && chatRoom.users[0];
  }
  return chatRoom?.user;
};
