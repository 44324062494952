import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as VideoCamSvg } from 'icons/VideoCam.svg';
import { sidebarItems } from 'components/config';
import UserMenu from 'components/UserMenu';
import JoinMeetingModal from 'components/JoinMeetingModal';

const SideBar = ({ page, level }) => {
  const { currentUser } = useSelector(state => state.auth);
  const { organizationKey } = useParams();
  const [isOpenModal, setIsOpenModal] = useState(false);

  if (!['admin', 'employee', 'customer'].includes(currentUser?.role)) {
    return;
  }
  return (
    <div className='sidebar' style={{ '--level': level }}>
      <div className='menu-items'>
        {sidebarItems(currentUser, organizationKey).map((item, index) => (
          <Link
            key={index}
            className={`${page === item.page ? 'active' : ''} menu-item`}
            to={item.link}
          >
            {item.icon}
          </Link>
        ))}
        {currentUser.role === 'customer' && (
          <div className='menu-item'><VideoCamSvg onClick={() => setIsOpenModal(true)} /></div>
        )}
      </div>
      <div className='user-menu'>
        <UserMenu />
      </div>
      {isOpenModal && (
        <JoinMeetingModal toggle={() => setIsOpenModal(false)} />
      )}
    </div>
  );
};

export default SideBar;
