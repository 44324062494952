import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './locales/en/translation.json';
import translationJa from './locales/ja/translation.json';

const resources = {
  en: {
    translation: translationEn,
  },
  ja: {
    translation: translationJa,
  },
};

let debug = true;
if (localStorage.getItem('disable-i18n-debug') || process.env.NODE_ENV === 'test') {
  debug = false;
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ja',
    fallbackLng: ['ja', 'en'],
    debug,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
