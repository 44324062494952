import React, { useCallback, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { InputForm } from 'components/forms/InputForm';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { forgotPassword } from 'redux/slices/auth';
import { styles } from './styles';

const schema = t => yup.object({
  email: yup.string().email(t('common.error.email_required')).required(t('common.error.required'))
    .max(100, t('common.error.max_length', { max: 100 })),
}).required();

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const captchaContainerRef = useRef();
  const formRef = useRef();

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  });
  const { handleSubmit, formState: { isSubmitting } } = methods;

  const handleForgotPassword = useCallback(({ email }) => {
    formRef.current.style.display = 'none';
    // eslint-disable-next-line no-undef
    AwsWafCaptcha.renderCaptcha(captchaContainerRef.current, {
      apiKey: process.env.REACT_APP_CAPTCHA_API_KEY,
      onSuccess: () => {
        dispatch(forgotPassword({ email }))
          .unwrap()
          .then(() => {
            toast.success(t('forgot.check_email'));
            navigate('/login');
          }).catch(() => {
            toast.error(t('forgot.email_invalid'));
          });
      },
      dynamicWidth: true,
      defaultLocale: 'ja-JP',
      disableLanguageSelector: true,
    });
  }, [dispatch, navigate, t]);

  return (
    <div className={styles.login}>
      {isSubmitting && (<LoadingAllScreen />)}
      <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full max-w-md space-y-8'>
          <div>
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              {t('forgot.forgot_password')}
            </h2>
          </div>
          <FormProvider {...methods}>
            <div ref={formRef} className='mt-8 space-y-6'>
              <input type='hidden' name='remember' defaultValue='true' />
              <div className='-space-y-px rounded-md shadow-sm'>
                <div className='mb-2'>
                  <InputForm
                    label={t('forgot.form.email')}
                    name='email'
                    type='email'
                    className='input-field'
                  />
                </div>
              </div>
              <div className='flex items-center justify-between gap-x-2'>
                <button
                  type='submit'
                  className='submit-btn ant-btn ant-btn-primary'
                  disabled={isSubmitting}
                  onClick={handleSubmit(handleForgotPassword)}
                >
                  {t('forgot.submit')}
                </button>
                <Link
                  to='/login'
                  className='cancel-btn'
                >
                  {t('common.actions.cancel')}
                </Link>
              </div>
            </div>
          </FormProvider>
          <div ref={captchaContainerRef} id='captcha-container' />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
