import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'antd';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import {
  fetchUsers, createUser, updateUser,
} from 'redux/slices/users';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { scrollToTop } from 'utils';
import { useParams } from 'react-router-dom';
import GridView from './GridView';
import ListView from './ListView';
import ModalAddUser from './ModalAddUser';
import UserDetails from './UserDetails';
import Header from './Header';
import { styles } from './styles';

const Users = () => {
  const { t } = useTranslation();
  const {
    users, fetching, isSubmitting,
    pagination: { currentPage, perPage, totalObjects },
  } = useSelector(state => state.users);
  const { organizationKey } = useParams();
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [gridView, setGridView] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [isShowDetails, setShowDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchData = useCallback(args => {
    setShowDetails(false);
    dispatch(fetchUsers({
      params: {
        page: currentPage,
        per_page: perPage,
        keyword,
        ...args,
      },
    }));
  }, [dispatch, keyword, currentPage, perPage]);

  const handleSaveUser = useCallback((formValues, id, callback) => {
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('email', formValues.email);
    formData.append('password', formValues.password);
    formData.append('introduction', formValues.introduction);
    formData.append('username', formValues.username);

    if (formValues.image) { formData.append('image', formValues.image[0]); }
    if (id) { formData.append('id', id); }

    if (id) {
      dispatch(updateUser(formData)).unwrap().then(result => {
        if (result?.success) {
          fetchData();
          callback && callback();
        }
      });
    } else {
      dispatch(createUser(formData)).unwrap().then(result => {
        if (result?.success) {
          if (keyword !== '') {
            setKeyword('');
          }
          fetchData({ page: 1, keyword: '' });
          callback && callback();
        }
      });
    }
  }, [dispatch, fetchData, keyword]);

  const showModal = (user = null) => {
    setIsModalOpen(true);
    setEditUser(user);
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationKey]);

  const handleSelectedUser = useCallback(id => {
    setSelectedUser(id);
    setShowDetails(true);
  }, []);

  const handleSearchUsers = useCallback(searchInput => {
    setKeyword(searchInput);
    fetchData({ page: 1, keyword: searchInput });
  }, [fetchData]);

  const handleChangePage = useCallback((page, pageSize) => {
    fetchData({ page, per_page: pageSize });
    scrollToTop();
  }, [fetchData]);

  return (
    <div className={styles.index}>
      <div className='user-page'>
        <div className='title-header'>{t('users.header_title')}</div>
        <Header
          keyword={keyword}
          gridView={gridView}
          setGridView={setGridView}
          toggleCreateModal={showModal}
          handleSearchUsers={handleSearchUsers}
        />
        {fetching && <LoadingAllScreen />}
        <div className='user-container'>
          <div className={classnames(isShowDetails ? 'show-details' : '', 'main-content')}>
            <div className='total-members'>
              {t('users.total_members', { total: totalObjects })}
            </div>
            {!isEmpty(users) && (
              <>
                {gridView ? (
                  <GridView
                    users={users}
                    handleSelectedUser={handleSelectedUser}
                  />
                ) : (
                  <ListView
                    users={users}
                    handleSelectedUser={handleSelectedUser}
                  />
                )}
                <Pagination
                  current={currentPage}
                  total={totalObjects}
                  onChange={handleChangePage}
                  onShowSizeChange={handleChangePage}
                  size='small'
                  pageSize={perPage}
                  showSizeChanger={totalObjects > 10}
                />
              </>
            )}
          </div>
          {isShowDetails && (
            <UserDetails
              userId={selectedUser}
              showModal={showModal}
              handleSearchUsers={handleSearchUsers}
            />
          )}
        </div>
        {isModalOpen && (
          <ModalAddUser
            editUser={editUser}
            handleCancel={() => setIsModalOpen(false)}
            handleSaveUser={handleSaveUser}
            isSubmitting={isSubmitting}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
