import api from 'redux/api-common';
import { camelCaseObject } from 'utils';
import qs from 'qs';

const fetchUsers = ({ params }) => api
  .get(`/users?${qs.stringify(params)}`)
  .then(response => camelCaseObject(response.data));

const fetchUser = ({ params: { id, ...rest } }) => api
  .get(`/users/${id}?${qs.stringify(rest)}`)
  .then(response => camelCaseObject(response.data));

const createUser = data => api
  .post('users', data)
  .then(response => camelCaseObject(response.data));

const updateUser = ({ params, id }) => api
  .put(`/users/${id}`, params)
  .then(response => camelCaseObject(response.data));

const removeUser = ({ params: { id, ...rest } }) => api
  .delete(`/users/${id}?${qs.stringify(rest)}`)
  .then(response => camelCaseObject(response.data));

export default {
  fetchUsers,
  fetchUser,
  createUser,
  updateUser,
  removeUser,
};
