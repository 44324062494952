import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from 'redux/slices/auth';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { Input } from 'antd';
import InputForm from 'components/forms/InputForm';
import FileForm from 'components/forms/FileForm';
import SwitchForm from 'components/forms/SwitchForm';
import NavBarMobile from 'components/NavBarMobile';
import { SUPPORTED_IMAGE_FORMATS } from 'utils/constants';
import SideBar from 'components/SideBar';
import { isPresent } from 'utils';
import HeaderBar from './HeaderBar';
import { styles } from './styles';

const schema = t => yup.object({
  email: yup.string().required(t('common.error.required')).email(t('common.error.email_required')).max(100, t('common.error.max_length', { max: 100 })),
  name: yup.string().required(t('common.error.required')).max(250, t('common.error.max_length', { max: 250 })),
  introduction: yup.string().max(10000, t('common.error.max_length', { max: 10000 })).nullable(),
  auto_reply_message: yup.string().max(500, t('common.error.max_length', { max: 500 })).nullable(),
  password: yup.string()
    .test('min_custom', t('common.error.min_length', { min: 6 }), value => (value ?? '').length === 0
      || (value ?? '').length >= 6)
    .test('max_custom', t('common.error.max_length', { max: 100 }), value => (value ?? '').length === 0
      || (value ?? '').length <= 100),
  confirm_password: yup.string().when('password', {
    is: value => (value ?? '').length > 0,
    then: yup.string().required(t('common.error.required'))
      .oneOf([yup.ref('password'), null], t('common.error.password_must_match'))
      .min(6, t('common.error.min_length', { min: 6 }))
      .max(100, t('common.error.max_length', { max: 100 })),
  }),
});

const EmployeeProfile = ({ currentUser }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { submitting } = useSelector(state => state.auth);
  const [imageSrc, setImageSrc] = useState(currentUser?.image);

  const defaultValues = useMemo(() => ({
    name: currentUser?.name || '',
    email: currentUser?.email,
    introduction: currentUser?.introduction || '',
    auto_reply_message: currentUser?.auto_reply_message || '',
    auto_reply_flag: String(currentUser?.auto_reply_flag) === 'true',
    counseling_flag: String(currentUser?.counseling_flag) === 'true',
  }), [currentUser]);

  const methods = useForm({
    resolver: yupResolver(schema(t)),
    mode: 'onChange',
    defaultValues,
  });
  const { handleSubmit, reset, setValue } = methods;

  const handleUpdateProfile = useCallback(formValues => {
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('email', formValues.email || '');
    formData.append('introduction', formValues.introduction || '');
    formData.append('auto_reply_message', formValues.auto_reply_message || '');
    formData.append('auto_reply_flag', formValues.auto_reply_flag);
    formData.append('counseling_flag', formValues.counseling_flag);
    if (formValues.password) { formData.append('password', formValues.password); }
    if (formValues.image) { formData.append('image', formValues.image[0]); }
    dispatch(updateProfile(formData));
  }, [dispatch]);

  const handleChangeImage = e => {
    if (isPresent(e.target.files)) {
      setValue('image', e.target.files);
      setImageSrc(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return (
    <div className={styles.index}>
      {submitting && (<LoadingAllScreen />)}
      <SideBar />
      <HeaderBar currentUser={currentUser} />
      {currentUser.role !== 'system_admin' && (
        <>
          <NavBarMobile page='profile' />
          <div className='title'>
            {t('pages.profile')}
          </div>
        </>
      )}
      <div className='flex justify-center'>
        <FormProvider {...methods}>
          <div className='profile'>
            <div className='image-content'>
              <FileForm
                name='image'
                imageSrc={imageSrc}
                fileTypesAccept={SUPPORTED_IMAGE_FORMATS}
                onChange={handleChangeImage}
              />
            </div>
            <div className='form-container'>
              <div className='profile-info-row'>
                <label>{t('users.form.name')}</label>
                <InputForm name='name' />
              </div>
              <div className='profile-info-row'>
                <label>{t('users.form.email')}</label>
                <InputForm type='email' name='email' />
              </div>
              {currentUser.role !== 'system_admin' && (
                <>
                  <div className='profile-info-row'>
                    <label>{t('users.form.introduction')}</label>
                    <InputForm
                      name='introduction'
                      Component={Input.TextArea}
                      autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                  </div>
                  <div className='profile-info-row'>
                    <label>{t('users.form.auto_reply_message')}</label>
                    <InputForm
                      name='auto_reply_message'
                      placeholder={t('users.form.auto_reply_message_placeholder')}
                      Component={Input.TextArea}
                      autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                  </div>
                  <div className='profile-info-row'>
                    <label>{t('users.form.auto_reply_flag')}</label>
                    <SwitchForm
                      name='auto_reply_flag'
                    />
                  </div>
                  <div className='profile-info-row'>
                    <label>{t('users.form.counseling_flag')}</label>
                    <SwitchForm
                      name='counseling_flag'
                    />
                  </div>
                </>
              )}
              <div className='profile-info-row'>
                <label>{t('users.form.password')}</label>
                <InputForm name='password' Component={Input.Password} autoComplete='new-password' />
              </div>
              <div className='profile-info-row'>
                <label>{t('users.form.confirm_password')}</label>
                <InputForm name='confirm_password' Component={Input.Password} />
              </div>
            </div>
            <div className='flex flex-row-reverse'>
              <button
                type='submit'
                disabled={submitting}
                className='submit-btn ant-btn ant-btn-primary'
                onClick={handleSubmit(handleUpdateProfile)}
              >
                {t('users.form.save')}
              </button>
            </div>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default EmployeeProfile;
