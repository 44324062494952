import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Button, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FileForm from 'components/forms/FileForm';
import { InputForm } from 'components/forms/InputForm';
import { SUPPORTED_IMAGE_FORMATS } from 'utils/constants';
import { commonStyles } from 'components/styles';
import { addOrganizationModal } from './styles';

const schema = t => yup.object().shape({
  name: yup.string().required(t('common.error.required')).max(100, t('common.error.max_length', { max: 100 })),
  description: yup.string().max(400, t('common.error.max_length', { max: 400 })),
});

const newOrganizationSchema = t => schema(t).concat(yup.object().shape({
  user: yup.object().shape({
    name: yup.string().required(t('common.error.required')).max(250, t('common.error.max_length', { max: 250 })),
    username: yup.string().max(250, t('common.error.max_length', { max: 250 })),
    email: yup.string().required(t('common.error.required')).email(t('common.error.email_required')).max(250, t('common.error.max_length', { max: 250 })),
    password: yup.string()
      .required(t('common.error.required'))
      .min(6, t('common.error.min_length', { min: 6 }))
      .max(100, t('common.error.max_length', { max: 100 })),
    confirmPassword: yup.string()
      .required(t('common.error.required'))
      .oneOf([yup.ref('password'), null], t('common.error.password_must_match'))
      .min(6, t('common.error.min_length', { min: 6 }))
      .max(100, t('common.error.max_length', { max: 100 })),
  }),
}));

const updateOrganizationSchema = t => schema(t).concat(yup.object().shape({
  user: yup.object().shape({
    name: yup.string().required(t('common.error.required')).max(250, t('common.error.max_length', { max: 250 })),
    username: yup.string().max(250, t('common.error.max_length', { max: 250 })),
    email: yup.string().required(t('common.error.required')).email(t('common.error.email_required')).max(250, t('common.error.max_length', { max: 250 })),
    password: yup.string().when('user.password', {
      is: value => value && value?.length > 0,
      then: yup.string().min(6, t('common.error.min_length', { min: 6 })).max(100, t('common.error.max_length', { max: 100 })),
      otherwise: yup.string(),
    }),
  }),
}));

const ModalAddOrganization = ({
  handleCancel,
  submitting,
  editOrganization,
  handleSaveOrganization,
}) => {
  const [t] = useTranslation();
  const [imageSrc, setImageSrc] = useState(editOrganization?.image);

  const defaultValues = useMemo(() => ({
    name: editOrganization?.name || '',
    user: {
      name: editOrganization?.admin?.name || '',
      email: editOrganization?.admin?.email || '',
      username: editOrganization?.admin?.username || '',
      password: '',
    },
    description: editOrganization?.description || '',
    lineChannelId: editOrganization?.lineChannelId || '',
    lineChannelSecret: editOrganization?.lineChannelSecret || '',
    lineChannelToken: editOrganization?.lineChannelToken || '',
    image: undefined,
  }), [editOrganization]);

  const methods = useForm({
    resolver: yupResolver(editOrganization ? updateOrganizationSchema(t) : newOrganizationSchema(t)),
    defaultValues,
  });

  const {
    handleSubmit, reset, setValue,
  } = methods;

  const handleChangeImage = e => {
    setValue('image', e.target.files);
    setImageSrc(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null);
  };

  const handleResetForm = useCallback(() => {
    reset(defaultValues);
    setImageSrc(editOrganization?.image);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrganization]);

  useEffect(() => {
    handleResetForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrganization]);

  const saveOrg = useCallback(formValues => {
    handleSaveOrganization(formValues, editOrganization?.id, () => {
      handleCancel();
      handleResetForm();
    });
  }, [editOrganization?.id, handleCancel, handleResetForm, handleSaveOrganization]);

  const modalAttribute = useMemo(() => {
    if (editOrganization?.id) {
      return {
        title: t('organizations.title_edit'),
        submitBtn: 'save-btn',
        submitText: t('common.actions.save'),
      };
    }
    return {
      title: t('organizations.title_new'),
      submitBtn: 'save-btn',
      submitText: t('common.actions.create'),
    };
  }, [editOrganization, t]);

  return (
    <Modal
      title={modalAttribute.title}
      open
      onCancel={handleCancel}
      className={`${commonStyles.modal} ${addOrganizationModal}`}
      footer={[
        <Button key='submit' type='primary' loading={submitting} onClick={handleSubmit(saveOrg)} className={modalAttribute.submitBtn}>
          {modalAttribute.submitText}
        </Button>,
      ]}
    >
      <FormProvider {...methods}>
        <div className='new-form'>
          <div className='image-content'>
            <FileForm
              name='image'
              imageSrc={imageSrc}
              fileTypesAccept={SUPPORTED_IMAGE_FORMATS}
              onChange={handleChangeImage}
            />
          </div>
          <div className='form-content'>
            <InputForm
              label={t('organizations.form.name')}
              name='name'
            />
            <InputForm
              Component={Input.TextArea}
              inputClassName='textarea-field'
              label={t('organizations.form.description')}
              name='description'
              maxLength={400}
              showCount
            />
            <InputForm
              label={t('users.form.name')}
              name='user.name'
            />
            <InputForm
              label={t('users.form.username')}
              name='user.username'
              autocomplete='new-username'
            />

            <InputForm
              label={t('organizations.form.email')}
              name='user.email'
              type='email'
            />
            <InputForm
              Component={Input.Password}
              label={t('organizations.form.password')}
              name='user.password'
              autocomplete='new-password'
            />
            {!editOrganization && (
              <InputForm
                Component={Input.Password}
                label={t('organizations.form.confirm_password')}
                name='user.confirmPassword'
              />
            )}
            <InputForm
              label={t('organizations.form.line_channel_id')}
              name='lineChannelId'
            />
            <InputForm
              label={t('organizations.form.line_channel_secret')}
              name='lineChannelSecret'
            />
            <InputForm
              label={t('organizations.form.line_channel_token')}
              name='lineChannelToken'
            />
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default ModalAddOrganization;
