import React from 'react';
import UsageFlowImage1 from 'images/landing-page/usage-flow-1.png';
import UsageFlowImage2 from 'images/landing-page/usage-flow-2.png';
import UsageFlowImage3 from 'images/landing-page/usage-flow-3.png';

const UsageFlow = () => (
  <div className='usage-flow'>
    <div className='background-text'>
      USAGE FLOW
    </div>
    <div className='title'>
      ご利用の流れ
    </div>
    <div className='steps'>
      <div className='step-box'>
        <div className='step-circle'>
          <div className='step-circle-number'> 01 </div>
        </div>
        <div className='step-image'>
          <img src={UsageFlowImage1} alt='Step 1' />
        </div>
        <div className='step-title'>  Lineでアカウント登録 or ログイン </div>
        <div className='step-description'>
          普段お使いのラインアカウントで簡単にアカウント登録やログインが可能です。
        </div>
      </div>
      <div className='step-box'>
        <div className='step-circle'>
          <div className='step-circle-number'> 02 </div>
        </div>
        <div className='step-image'>
          <img src={UsageFlowImage2} alt='Step 2' />
        </div>
        <div className='step-title'> 担当者とチャット </div>
        <div className='step-description'>
          専属の担当者とチャットでメッセージが可能に！
        </div>
      </div>
      <div className='step-box'>
        <div className='step-circle'>
          <div className='step-circle-number'>03</div>
        </div>
        <div className='step-image'>
          <img src={UsageFlowImage3} alt='Step 3' />
        </div>
        <div className='step-title'> 担当者とビデオ通話 </div>
        <div className='step-description'>
          担当者からビデオ通話の招待を受ければ、リンクにアクセスするだけで簡単にビデオ通話を始められます。
        </div>
      </div>
    </div>
    <div className='note'>
      <span>Coffret Compass</span>
      ならLINE によるサービス登録/ログインが可能！PCから面倒な設定は必要無し！
    </div>
    <a href={process.env.REACT_APP_DEFAULT_CUSTOMER_LOGIN_URL || '/customer_login'} className='register-btn-ref'>
      会員登録
      <span className='text-small'>は</span>
      こちら
    </a>
  </div>
);

export default UsageFlow;
