import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { isPresent } from 'utils';
import UserAvatar from 'components/UserAvatar';
import { ReactComponent as ThreeDotsSvg } from 'icons/ThreeDots.svg';
import { ReactComponent as CloseSvg } from 'icons/Close.svg';
import { ReactComponent as PlusSvg } from 'icons/Plus.svg';
import confirm from 'components/Confirm';
import AssignNewModal from './AssignNewModal';
import { styles, dropdownActions } from './styles';

const Members = React.memo(({
  chatRoom,
  handleAssignMember,
  handleUnassignMember,
  isSubmitting,
  canChangeAssign,
}) => {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState();

  const handleUnassign = useCallback(async userId => {
    if (chatRoom.users?.length === 1) {
      const result = await confirm({
        title: t('chat_rooms.modals.move_to_auto.title'),
        body: <div className='description'>{t('chat_rooms.modals.move_to_auto.content')}</div>,
        submitText: t('chat_rooms.modals.move_to_auto.unassign_btn'),
      });
      if (result) {
        // Move conversation to auto
        handleUnassignMember(chatRoom.id, { move_to_auto: true });
      }
    } else {
      const result = await confirm({
        title: t('chat_rooms.modals.unassign_modal.title'),
        body: <div className='description'>{t('chat_rooms.modals.unassign_modal.content')}</div>,
        submitText: t('chat_rooms.modals.unassign_modal.unassign_btn'),
      });
      if (result) {
        handleUnassignMember(chatRoom.id, { user_id: userId });
      }
    }
  }, [chatRoom, handleUnassignMember, t]);

  return (
    <div className={styles.members}>
      <div className='header'>
        <div className='title'>{t('chat_rooms.title_assigned')}</div>
        {chatRoom.users && canChangeAssign && (
          <button
            className='assign-btn'
            onClick={() => setIsModalOpen('assign_new')}
          >
            <PlusSvg width={14} height={14} />
            <div className='text'>{t('chat_rooms.assign_new')}</div>
          </button>
        )}
      </div>
      {isPresent(chatRoom.users) ? (
        <div className='assigned-list'>
          {chatRoom.users.map((user, index) => (
            <div className='assigned-card' key={`assigned--${index}`}>
              <div className='group-name'>
                <UserAvatar size={80} url={user?.image} alt={user.name} />
                <span className='text-name'>{user.name}</span>
                <span className='text-title'>{user.title}</span>
                {canChangeAssign && (
                  <Dropdown
                    menu={{
                      items: [{
                        label: (
                          <div className='item-content' onClick={() => handleUnassign(user.id)}>
                            <CloseSvg />
                            <div className='text unassign'>{t('chat_rooms.unassign')}</div>
                          </div>),
                        key: 'unassign',
                      }],
                    }}
                    overlayClassName={dropdownActions}
                    placement='bottomRight'
                    trigger={['click']}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    <ThreeDotsSvg className='dots-icon' />
                  </Dropdown>
                )}
              </div>
              <div className='info'>
                <label>{t('users.form.introduction')}</label>
                <div className='description'>{user.introduction}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='no-assigned'>
          <UserAvatar size={150} alt='no agent' />
          <div className='no-agent'>{t('chat_rooms.no_agent')}</div>
        </div>
      )}
      {isModalOpen === 'assign_new' && (
        <AssignNewModal
          handleCancel={() => setIsModalOpen()}
          handleAssignMember={handleAssignMember}
          chatRoomId={chatRoom.id}
          isSubmitting={isSubmitting}
          members={chatRoom.users}
        />
      )}
    </div>
  );
});

export default Members;
