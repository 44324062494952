import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { getOrganizationKey } from 'utils';
import { set } from 'lodash';
import MeetingsService from '../services/MeetingsService';

export const fetchMeetings = createAsyncThunk(
  'meetings/FETCH_MEETINGS',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await MeetingsService.fetchMeetings(data);
    return result?.data;
  },
);

export const fetchMeeting = createAsyncThunk(
  'meetings/FETCH_MEETING',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await MeetingsService.fetchMeeting(data);
    return result?.data;
  },
);

export const deleteMeeting = createAsyncThunk(
  'meetings/DELETE_MEETING',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await MeetingsService.deleteMeeting(data);
    return result?.data;
  },
);

export const createMeeting = createAsyncThunk(
  'meetings/CREATE_MEETING',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await MeetingsService.createMeeting(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result?.data;
  },
);

export const updateMeeting = createAsyncThunk(
  'meetings/UPDATE_MEETING',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await MeetingsService.updateMeeting(data);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    return result?.data;
  },
);

export const joinMeeting = createAsyncThunk(
  'meetings/JOIN_MEETING',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await MeetingsService.joinMeeting(data);
    return result?.data;
  },
);

export const authenticateMeeting = createAsyncThunk(
  'meetings/AUTHENTICE_MEETING',
  async (data = {}) => {
    set(data, 'params.organization_key', getOrganizationKey());
    const result = await MeetingsService.authenticateMeeting(data);
    return result?.data;
  },
);

const initialState = {
  meetings: [],
  meeting: {},
  messages: [],
  newMessageIndex: -1,
  pagination: {},
  fetching: false,
  submitting: false,
  joining: false,
};

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
      state.newMessageIndex = state.messages.length - 1;
    },
  },
  extraReducers: {
    [fetchMeetings.pending]: state => {
      state.fetching = true;
    },
    [fetchMeetings.fulfilled]: (state, _action) => {
      state.pagination = _action.payload.pagination;
      if (state.pagination.isLoadMore) {
        state.meetings = state.meetings.concat(_action.payload.meetings);
      } else {
        state.meetings = _action.payload.meetings;
      }
      state.fetching = false;
    },
    [fetchMeetings.rejected]: state => {
      state.meetings = [];
      state.pagination = {};
      state.fetching = false;
    },
    [fetchMeeting.pending]: state => {
      state.fetching = true;
    },
    [fetchMeeting.fulfilled]: (state, _action) => {
      state.meeting = _action.payload;
      state.fetching = false;
    },
    [fetchMeeting.rejected]: state => {
      state.fetching = false;
    },
    [joinMeeting.pending]: state => {
      state.joining = true;
    },
    [joinMeeting.fulfilled]: state => {
      state.joining = false;
    },
    [joinMeeting.rejected]: state => {
      state.joining = false;
    },
    [createMeeting.pending]: state => {
      state.submitting = true;
    },
    [createMeeting.fulfilled]: state => {
      state.submitting = false;
    },
    [createMeeting.rejected]: state => {
      state.submitting = false;
    },
    [authenticateMeeting.pending]: state => {
      state.joining = true;
    },
    [authenticateMeeting.fulfilled]: state => {
      state.joining = false;
    },
    [authenticateMeeting.rejected]: state => {
      state.joining = false;
    },
    [deleteMeeting.fulfilled]: (state, _action) => {
      const deletedMeetingId = get(_action, 'meta.arg.params.id');
      if (deletedMeetingId) {
        state.meetings = state.meetings.filter(meeting => meeting.id !== deletedMeetingId);
      }
    },
  },
});

const { reducer } = meetingsSlice;
export const { addMessage } = meetingsSlice.actions;
export default reducer;
