import React, { useCallback } from 'react';
import { Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ReactComponent as VideoCamSvg } from 'icons/VideoCam.svg';
import { ReactComponent as VideoCamOffSvg } from 'icons/VideoCamOff.svg';
import { ReactComponent as MicSvg } from 'icons/Mic.svg';
import { ReactComponent as MicOffSvg } from 'icons/MicOff.svg';
import { ReactComponent as GroupSvg } from 'icons/Group.svg';
import { ReactComponent as ScreenshareSvg } from 'icons/Screenshare.svg';
import { ReactComponent as BackgroundSvg } from 'icons/Background.svg';
import { ReactComponent as ChatSvg } from 'icons/Chat.svg';
import { toolbox, endCallAction } from './styles';

const Toolbox = ({
  meetingName,
  state: { modal, unreadCount, isSharing },
  setState, setModal,
  handleChangeLocalTrack,
  localTracks,
  participants,
  handleEndCall,
}) => {
  const [t] = useTranslation();
  const handleModal = useCallback(selected => {
    if (selected === modal) {
      setModal();
    } else {
      setModal(selected);
    }
  }, [modal, setModal]);

  return (
    <div className={toolbox}>
      <div className='meeting-name'>
        {meetingName}
      </div>
      <div className='toolbox-content-items'>
        <button className={localTracks.video ? '' : 'danger-active-btn'} onClick={() => handleChangeLocalTrack('video', !localTracks.video)}>
          {localTracks.video ? <VideoCamSvg /> : <VideoCamOffSvg />}
        </button>
        <button className={localTracks.audio ? '' : 'danger-active-btn'} onClick={() => handleChangeLocalTrack('audio', !localTracks.audio)}>
          {localTracks.audio ? <MicSvg /> : <MicOffSvg />}
        </button>
        <button className={classnames('share-btn', { 'active-btn': isSharing })} onClick={() => setState(prevState => ({ ...prevState, isSharing: !isSharing }))}>
          <ScreenshareSvg />
        </button>
        <button className={classnames('', { 'active-btn': modal === 'background' })} onClick={() => handleModal('background')}>
          <BackgroundSvg />
        </button>
        <button className={classnames('', { 'active-btn': modal === 'participant' })} onClick={() => handleModal('participant')}>
          <GroupSvg />
          {/* participants + currentUser */}
          <span className='badge-count'>{participants.length + 1}</span>
        </button>
        <button className={classnames('', { 'active-btn': modal === 'chat' })} onClick={() => handleModal('chat')}>
          <ChatSvg />
          {unreadCount > 0 && <span className='badge-count unread'>{unreadCount}</span>}
        </button>
      </div>
      <div className='end-meeting-content'>
        <Dropdown
          menu={{
            items: [
              {
                key: 'end_call',
                label: <Button type='text' className='end-call-btn' onClick={handleEndCall}>{t('meetings.show.end_call')}</Button>,
              }, {
                key: 'cancel_end_call',
                label: <Button>{t('common.actions.cancel')}</Button>,
              },
            ],
          }}
          placement='topRight'
          trigger={['click']}
          getPopupContainer={trigger => trigger.parentNode}
          overlayClassName={endCallAction}
        >
          <Button type='text' className='end-meeting-btn'>{t('meetings.show.end_call')}</Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default Toolbox;
