import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from 'redux/slices/auth';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { canAccessPage } from 'utils/permissionHelper';

const PrivateRoute = ({ Component, page }) => {
  const dispatch = useDispatch();
  const { isLoggedIn, currentUser, customerLoginUrl } = useSelector(state => state.auth);

  useEffect(() => {
    if (isLoggedIn && !currentUser) {
      dispatch(getProfile());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoggedIn]);

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={customerLoginUrl || '/login'} replace />;
  }

  if (!currentUser) {
    return <LoadingAllScreen />;
  }

  if (!canAccessPage(currentUser.role, page)) {
    return <Navigate to='/profile' replace />;
  }

  // authorized so return component
  return <Component currentUser={currentUser} />;
};

export default PrivateRoute;
