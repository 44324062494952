import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import copy from 'clipboard-copy';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { fetchOrganization } from 'redux/slices/organizations';
import { getImageSrc } from 'utils/fileHelper';
import Loading from 'components/Loading';
import { isPresent } from 'utils';
import { ReactComponent as CloseSvg } from 'icons/Close.svg';
import { ReactComponent as PenToSquareSvg } from 'icons/PenToSquare.svg';
import { ReactComponent as ThreeDotsSvg } from 'icons/ThreeDots.svg';
import { dropdownActions } from 'components/styles';
import RemoveOrganizationModal from './RemoveOrganizationModal';

const OrganizationDetails = ({
  organizationId,
  showModal,
  handleSearchOrganizations,
}) => {
  const [t] = useTranslation();
  const { organization = {}, fetchingOrganization } = useSelector(state => state.organizations);
  const dispatch = useDispatch();
  const [isOpenModalRemove, setOpenModalRemove] = useState(false);
  const customerLoginUrl = useMemo(
    () => `${location.origin}/customer_login?key=${organization.organizationKey}`,
    [organization.organizationKey],
  );

  useEffect(() => {
    dispatch(fetchOrganization(organizationId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const handleRemoveDone = useCallback(() => {
    setOpenModalRemove(false);
    handleSearchOrganizations('');
  }, [handleSearchOrganizations]);

  const copyValueToClipboard = useCallback(() => {
    const copySuccessPromise = copy(customerLoginUrl);

    copySuccessPromise.then(() => {
      toast.success(t('common.value_copied'));
    });
  }, [customerLoginUrl, t]);

  return (
    <div className='organization-details'>
      <div className='organization-info'>
        {fetchingOrganization && <Loading />}
        {isPresent(organization) && (
          <>
            <div className='content'>
              <div className='flex'>
                <img
                  width={48}
                  height={48}
                  className='organization-image'
                  src={getImageSrc(organization?.image)}
                  alt={organization?.image}
                />
                <div className='group-name'>
                  <span className='name'>{organization.name}</span>
                </div>
              </div>
              <Dropdown
                menu={{
                  items: [{
                    label: (
                      <div className='edit-btn' onClick={() => showModal(organization)}>
                        <PenToSquareSvg width={18} height={18} />
                        <div className='text'>{t('common.actions.edit')}</div>
                      </div>
                    ),
                    key: 'edit',
                  }, {
                    label: (
                      <div className='remove-btn' onClick={() => setOpenModalRemove(true)}>
                        <CloseSvg width={16} height={16} />
                        <div className='text'>{t('common.actions.remove')}</div>
                      </div>
                    ),
                    key: 'delete',
                  }],
                }}
                overlayClassName={dropdownActions}
                placement='bottomRight'
                trigger={['click']}
              >
                <ThreeDotsSvg className='dots-icon' />
              </Dropdown>
            </div>
            <div className='info'>
              <label>{t('organizations.form.description')}</label>
              <div className='description about'>{organization.description}</div>
            </div>
            <div className='info'>
              <label>{t('organizations.customer_login_url')}</label>
              <div className='url'>
                <span>
                  {customerLoginUrl}
                </span>
                <button className='ml-3' onClick={copyValueToClipboard}>
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </div>
            </div>
            <div className='info'>
              <label>{t('users.form.email')}</label>
              <div className='description'>{organization.admin?.email}</div>
            </div>
            <div className='info'>
              <label>{t('users.form.username')}</label>
              <div className='description'>{organization.admin?.username}</div>
            </div>
            <div className='info'>
              <label>{t('organizations.form.email')}</label>
              <div className='description'>{organization.admin?.email}</div>
            </div>

            {isOpenModalRemove && (
              <RemoveOrganizationModal
                organization={organization}
                handleRemoveDone={handleRemoveDone}
                closeModal={() => setOpenModalRemove(false)}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrganizationDetails;
