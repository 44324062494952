import { Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

const OrganizationBar = () => {
  const { currentUser, currentUser: { organizations = [] } } = useSelector(state => state.auth);
  const { organizationKey } = useParams();
  const { pathname } = useLocation();

  const generateUrl = useCallback(orgKey => pathname.replace(/\/organizations\/([^/]+)\/([^/]+)\/?.*/, `/organizations/${orgKey}/$2`), [pathname]);

  if (!['admin', 'employee', 'customer'].includes(currentUser?.role)) {
    return;
  }
  return (
    <div className='organizationbar'>
      <div className='menu-items'>
        {organizations.map((item, index) => (
          <Link
            key={index}
            className={`${organizationKey === item.key ? 'active' : ''} menu-item`}
            to={generateUrl(item.key)}
          >
            <Tooltip title={item.name} placement='right'>
              {item.image ? (
                <img src={item.image} alt={item.name} />
              ) : (
                <div className='name'>
                  {item.name}
                </div>
              )}
            </Tooltip>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OrganizationBar;
