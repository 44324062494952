import React, { useEffect } from 'react';
import { Layout } from 'antd';
import ThankYouImage from 'images/landing-page/thank-you.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { styles } from './styles';

const { Content } = Layout;

const ThankYou = () => {
  const { isSubmitted } = useSelector(state => state.contacts);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isSubmitted) {
    navigate('/');
    return null;
  }

  return (
    <Layout className={styles.index}>
      <Navbar />
      <Content>
        <section className='thank-you'>
          <div className='background-text'>
            CONTACT
          </div>
          <div className='title'>
            お問い合わせ
          </div>
          <div className='thank-you-text'>
            お問い合わせありがとうございます
          </div>
          <div className='thank-you-image'>
            <img width={517} height={329} src={ThankYouImage} alt='ThankYouImage' />
          </div>
          <div className='respond-text'>
            お問い合わせいただきありがとうございます。2～3営業日以内に、担当よりメールにて返信させていただきます。
          </div>
          <a href='/' className='back-btn'>
            トップへ戻る
          </a>
        </section>
      </Content>
      <Footer />
    </Layout>
  );
};

export default ThankYou;
