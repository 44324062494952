import React, { useMemo } from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UserAvatar from 'components/UserAvatar';
import MultiAvatar from './MultiAvatar';
import { messageDateFormat, isUnreadMessage, chatRoomHeaderUser } from './utils';

const ChatRoomCard = ({
  currentChatRoomId, chatRoom, index, setSwitchToMessages,
}) => {
  const [t] = useTranslation();
  const { currentUser } = useSelector(state => state.auth);
  const {
    organizationKey,
  } = useParams();
  const headerUser = useMemo(() => chatRoomHeaderUser({ chatRoom, currentUser }), [chatRoom, currentUser]);

  const newestMessage = useMemo(() => {
    if (!chatRoom.newestMessage) {
      return '';
    }
    if (get(chatRoom, 'newestMessage.deletedAt')) {
      return t('chat_rooms.messages.message_deleted');
    }
    return get(chatRoom, 'newestMessage.body') || t('chat_rooms.messages.sent_an_attachment');
  }, [chatRoom, t]);

  return (
    <Link to={`/organizations/${organizationKey}/chat/${chatRoom.id}`} onClick={() => setSwitchToMessages(true)} key={`chat-rooms-${index}`}>
      <div className={classnames(
        'chat-room-card',
        chatRoom.id === Number(currentChatRoomId) ? 'active-chat' : '',
        isUnreadMessage(currentUser, chatRoom) ? 'unread-message' : '',
      )}
      >
        <UserAvatar
          size={48}
          url={get(headerUser, 'image')}
          alt={get(headerUser, 'name')}
          className='large-avatar'
        />
        <div className='chat-room-info'>
          {currentUser.role === 'customer' && (<div className='organization-name'>{chatRoom.organization.name}</div>)}
          <div className='group-name'>
            {get(headerUser, 'name') && <div className='name'>{get(headerUser, 'name')}</div>}
            <span className='date'>{messageDateFormat(get(chatRoom, 'newestMessage.updatedAt'), 'hh:mma')}</span>
          </div>
          <div className='flex'>
            <div className='new-message'>
              {newestMessage}
            </div>
            <MultiAvatar chatRoom={chatRoom} />
          </div>
        </div>
        <div className='dot-unread'>
          <span className='dot' />
        </div>
      </div>
    </Link>
  );
};

export default ChatRoomCard;
