import React, { useCallback, useMemo, useState } from 'react';
import {
  Button, Checkbox, Modal, DatePicker, TimePicker,
} from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDate } from 'utils/date';
import moment from 'moment';
import * as yup from 'yup';
import {
  InputForm, CheckboxForm, SelectForm, RadioGroupForm,
} from 'components/forms';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { commonStyles } from 'components/styles';
import { customCheckboxForm } from 'components/forms/styles';
import styles from './styles';
import { addHour } from './utils';

const schema = t => yup.object({
  name: yup.string().required(t('common.error.required')).max(100),
  date: yup.date().required(t('common.error.required')),
  start_time: yup.date().required(t('common.error.required')),
  end_time: yup.date().min(yup.ref('start_time'), t('meetings.modals.schedule_new_meeting.time_greater_than'))
    .required(t('common.error.required')),
  timezone: yup.string().required(t('common.error.required')),
  passcode: yup.string(),
});

const ModalScheduleMeeting = ({
  toggle, submit, defaultValues = {
    timezone: moment().format('ZZ'),
    old_timezone: moment().format('ZZ'),
    repeat_type: 'no_repeat',
    date: moment(),
    start_time: moment(),
    end_time: addHour(moment(), 1),
  },
}) => {
  const [t] = useTranslation();
  const { submitting } = useSelector(state => state.meetings);
  const [usePasscode, setUsePasscode] = useState(!!defaultValues.passcode);

  const disabledDate = current => current.isBefore(moment().subtract(1, 'day'));

  const selectOptions = useMemo(() => ([
    { label: t('enums.boolean.on'), value: true },
    { label: t('enums.boolean.off'), value: false },
  ]), [t]);

  const timezoneOptions = useMemo(() => {
    let current = -(60 * 11); // GMT - 11
    const end = 60 * 14; // GTM + 14
    const options = [{ label: t('meetings.modals.schedule_new_meeting.with_timezone'), value: '' }];

    while (current <= end) {
      const hour = Math.floor((Math.abs(current) / 60)).pad(2);
      const minutes = (Math.abs(current) % 60).pad(2);
      const opeartor = current < 0 ? '-' : '+';

      options.push({
        label: `GMT ${opeartor} ${hour}:${minutes}`,
        value: `${opeartor}${hour}${minutes}`,
      });
      current += 30;
    }
    return options;
  }, [t]);

  const methods = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues,
  });
  const { handleSubmit, setValue } = methods;

  const handleSave = (formValues => {
    submit(formValues, () => {
      toggle();
    });
  });

  const handleUsePasscode = checked => {
    if (!checked) {
      setValue('passcode', '');
    }
    setUsePasscode(checked);
  };

  const handleStartTimeChange = useCallback(time => {
    setValue('start_time', time);
    setValue('end_time', addHour(time, 1));
  }, [setValue]);

  return (
    <Modal
      title={t('meetings.modals.schedule_new_meeting.title')}
      open
      centered
      onCancel={toggle}
      className={`${commonStyles.modal} ${styles}`}
      footer={[
        <Button key='submit' className='save-btn' type='primary' loading={submitting} onClick={handleSubmit(handleSave)}>
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      <FormProvider {...methods}>
        <InputForm
          name='name'
          placeholder={t('meetings.modals.schedule_new_meeting.name')}
          inputClassName='input-name'
        />
        <div className='header-label'>{t('meetings.modals.schedule_new_meeting.date')}</div>
        <div className='datetime-group'>
          <InputForm
            addMoreClassName='date-form'
            Component={DatePicker}
            format={value => formatDate(value, 'dddd, MMMM DD')}
            allowClear={false}
            suffixIcon=''
            name='date'
            disabledDate={disabledDate}
          />
          <InputForm
            addMoreClassName='time-form'
            Component={TimePicker}
            format='HH:mm'
            allowClear={false}
            suffixIcon=''
            name='start_time'
            onChange={handleStartTimeChange}
          />
          <div className='time-to'>{t('meetings.modals.schedule_new_meeting.time_to')}</div>
          <InputForm
            addMoreClassName='time-form'
            Component={TimePicker}
            format='HH:mm'
            allowClear={false}
            clearIcon={false}
            suffixIcon=''
            name='end_time'
          />
        </div>
        <div className='select-form-group'>
          <SelectForm
            name='repeat_type'
            options={[{ label: t('enums.meeting.repeat_type.no_repeat'), value: 'no_repeat' }]}
            disabled
          />
          <SelectForm name='timezone' options={timezoneOptions} disabled />
        </div>
        <div className='header-label'>{t('meetings.modals.schedule_new_meeting.security')}</div>
        <div className={`${customCheckboxForm} code-group`}>
          <Checkbox checked={usePasscode} onChange={e => handleUsePasscode(e.target.checked)}>
            <div className='label-group'>
              <span className='text-label'>{t('meetings.modals.schedule_new_meeting.meeting_code')}</span>
              <span className='text-description'>{t('meetings.modals.schedule_new_meeting.meeting_code_description')}</span>
            </div>
          </Checkbox>
          {usePasscode && (
            <InputForm name='passcode' type='text' />
          )}
        </div>
        <CheckboxForm
          name='waiting_room'
          label={t('meetings.modals.schedule_new_meeting.waiting_room')}
          labelDescription={t('meetings.modals.schedule_new_meeting.waiting_room_description')}
        />
        <div className='header-label'>{t('meetings.modals.schedule_new_meeting.video')}</div>
        <div className='video-group'>
          <RadioGroupForm
            name='host_video_on'
            label={t('meetings.modals.schedule_new_meeting.host_video_on')}
            options={selectOptions}
          />
          <RadioGroupForm
            name='participant_video_on'
            label={t('meetings.modals.schedule_new_meeting.participant_video_on')}
            options={selectOptions}
          />
        </div>
      </FormProvider>
    </Modal>
  );
};

export default ModalScheduleMeeting;
