import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Radio } from 'antd';
import { customRadioGroupForm } from './styles';

export const RadioGroupForm = ({
  label, rules = {}, name, className, defaultValue,
  errorClassName, labelClassName, options = [], groupClass, optionClassName,
  required, hidden, isDisabled, labelclass, children, ...rest
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { control } = rest.control ? rest : useFormContext();
  const [t] = useTranslation();

  if (hidden) { return null; }

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue ?? options[0]?.value}
      render={({ field, fieldState: { error } }) => (
        <div className={classNames(className || customRadioGroupForm, 'radio-group-form')}>
          {label && (
            <label className={labelclass || 'mr-7'}>
              <span className='radio-label'>{label}</span>
              {(rules.required || required) && (<span className='text-red-600'>{t('common.required_text')}</span>)}
            </label>
          )}
          <Radio.Group
            name={name}
            defaultValue={defaultValue ?? options[0]?.value}
            className={groupClass}
            onChange={e => {
              if (rest.onChange) {
                rest.onChange(e);
              } else {
                field.onChange(e.target.value);
              }
            }}
            value={field.value}
          >
            {options.map((opt, index) => (
              <React.Fragment key={`${name}-${index}`}>
                <span className={optionClassName}>
                  <Radio
                    value={opt.value}
                    disabled={isDisabled}
                  >
                    <div className={labelClassName || 'radio-label'}>{opt.label}</div>
                  </Radio>
                  {String(field.value ?? defaultValue) === String(opt.value) && children}
                </span>
              </React.Fragment>
            ))}
          </Radio.Group>
          {error?.message && (
            <span className={errorClassName}>{error.message}</span>
          )}
        </div>
      )}
    />
  );
};

export default RadioGroupForm;
