import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import UserAvatar from 'components/UserAvatar';
import Loading from 'components/Loading';
import { isPresent } from 'utils';
import { fetchUser } from 'redux/slices/users';
import { ReactComponent as CloseSvg } from 'icons/Close.svg';
import { ReactComponent as PenToSquareSvg } from 'icons/PenToSquare.svg';
import { ReactComponent as EyeSvg } from 'icons/Eye.svg';
import { ReactComponent as EyeHideSvg } from 'icons/EyeHide.svg';
import { ReactComponent as ThreeDotsSvg } from 'icons/ThreeDots.svg';
import { dropdownActions } from 'components/styles';
import RemoveUserModal from './RemoveUserModal';

const UserDetails = ({
  userId,
  showModal,
  handleSearchUsers,
}) => {
  const [t] = useTranslation();
  const { user = {}, fetchingUser } = useSelector(state => state.users);
  const { currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [isOpenModalRemove, setOpenModalRemove] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const canRemove = useMemo(() => currentUser.id !== user.id && user.role !== 'admin', [currentUser.id, user.id, user.role]);

  useEffect(() => {
    setPasswordVisible(false);
    dispatch(fetchUser(userId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleRemoveDone = useCallback(() => {
    if (canRemove) {
      setOpenModalRemove(false);
      handleSearchUsers('');
    }
  }, [canRemove, handleSearchUsers]);

  const showPassword = useMemo(() => (
    <div className='info'>
      <label>{t('users.form.password')}</label>
      <div className='flex justify-between items-center'>
        {passwordVisible ? (
          <>
            <div className='description'>{user.unencryptedPassword}</div>
            <button onClick={() => setPasswordVisible(false)}>
              <EyeSvg />
            </button>
          </>
        ) : (
          <>
            <div className='description'>**********</div>
            {user.unencryptedPassword && (
              <button onClick={() => setPasswordVisible(true)}>
                <EyeHideSvg />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  ), [t, passwordVisible, user.unencryptedPassword]);

  return (
    <div className='user-details'>
      <div className='user-info'>
        {fetchingUser && <Loading />}
        {isPresent(user) && (
          <>
            <div className='group-title'>
              <div className='title'>{t('users.details')}</div>
              <Dropdown
                menu={{
                  items: [{
                    label: (
                      <div className='edit-btn' onClick={() => showModal(user)}>
                        <PenToSquareSvg width={18} height={18} />
                        <div className='text'>{t('users.edit')}</div>
                      </div>
                    ),
                    key: 'edit',
                  }, canRemove ? {
                    label: (
                      <div className='remove-btn' onClick={() => canRemove && setOpenModalRemove(true)}>
                        <CloseSvg width={16} height={16} />
                        <div className='text'>{t('users.remove')}</div>
                      </div>
                    ),
                    key: 'delete',
                  } : null].filter(Boolean),
                }}
                overlayClassName={dropdownActions}
                placement='bottomRight'
                trigger={['click']}
              >
                <ThreeDotsSvg className='dots-icon' />
              </Dropdown>
            </div>
            <div className='group-info'>
              <UserAvatar size={110} url={user?.image} alt={user.name} />
              <span className='text-name'>{user.name}</span>
            </div>
            <div className='info'>
              <label>{t('users.form.introduction')}</label>
              <div className='description'>{user.introduction}</div>
            </div>
            <div className='info'>
              <label>{t('users.form.username')}</label>
              <div className='description'>{user.username}</div>
            </div>
            <div className='info'>
              <label>{t('users.form.email')}</label>
              <div className='description'>{user.email}</div>
            </div>
            {showPassword}

            {isOpenModalRemove && (
              <RemoveUserModal
                user={user}
                handleRemoveDone={handleRemoveDone}
                closeModal={() => setOpenModalRemove(false)}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
