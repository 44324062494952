import React from 'react';
import { Layout, Breadcrumb } from 'antd';
import Navbar from './Navbar';
import Footer from './Footer';
import { styles } from './styles';

const { Content } = Layout;

const Privacy = () => (
  <Layout className={styles.index}>
    <Navbar />
    <Content>
      <section className='privacy'>
        <Breadcrumb
          separator='>'
        >
          <Breadcrumb.Item>
            <a href='/'>ホームー</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>プライバシーポリシー</Breadcrumb.Item>
        </Breadcrumb>
        <div className='background-text'>
          PRIVACY POLICY
        </div>
        <div className='title'>
          プライバシーポリシー
        </div>
        <div className='privacy-content'>
          <div className='sub-title'>お問い合わせフォームの個人情報の取り扱いについて </div>
          <div className='description'>
            弊社は個人情報保護方針に基づき弊社ホームページのフォームより弊社のサービス等のお問合せをされた方の個人情報について以下のとおり取り扱います
          </div>
          <div className='item'>
            <div className='item-title'>1．個人情報の利用目的/個人情報の取得</div>
            <div className='item-content'>
              （1）お問い合わせ内容へのご返信
              <br />
              （2）弊社サービス等のご案内メールマガジン・ダイレクトメールをお送りすることがあります。
            </div>
          </div>
          <div className='item'>
            <div className='item-title'>2．個人情報の第三者提供</div>
            <div className='item-content'>
              弊社は、ご本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。
            </div>
          </div>
          <div className='item'>
            <div className='item-title'>3．個人情報の取り扱いの委託</div>
            <div className='item-content'>
              弊社では業務委託先に必要最低限の範囲で個人情報を委託する場合があります。その場合は委託先を適切に管理します。
            </div>
          </div>
          <div className='item'>
            <div className='item-title'>4．個人情報の管理</div>
            <div className='item-content'>
              弊社はご提供いただいた個人情報を弊社の責任のもと、厳重に管理します。
            </div>
          </div>
          <div className='item'>
            <div className='item-title'>5．個人情報の利用目的の通知、開示、訂正等</div>
            <div className='item-content'>
              弊社ではご提供いただいた個人情報についてのお問い合わせ（利用目的の通知、開示、訂正・追加又は削除、利用の停止又は第三者への提供の停止）についてご本人から個人情報の開示を依頼された場合、法令により特別の手続きが定められている場合を除き、速やかに依頼に応じ、書面により通知します。 但し、ご本人又は第三者の生命・身体・財産・その他の権利を害する恐れがある場合や法令違反のため等により依頼に応じられない場合はその旨と理由を通知します。
            </div>
          </div>
          <div className='item'>
            <div className='item-title'>6．個人情報の提供の任意性等</div>
            <div className='item-content'>
              （個人情報のご提供は任意ですが、ご提供いただけない場合はお問合せできません。また、間違った個人情報をご提供された場合、お問合せ者様はお問い合わせ内容にお答えできない場合がありますのでご了承ください。
            </div>
          </div>
          <div className='item'>
            <div className='item-title'>7．本人が容易に認識できない方法による個人情報の取得</div>
            <div className='item-content'>
              クッキーやウェブビーコン等を用いる等により、本人が容易に認識できない方法による個人情報の取得は行っておりません。
            </div>
          </div>
          <div className='contact-info'>
            制定日2019年5月20日
            <div className='company-name'>株式会社Compass</div>
            個人情報に関するお問い合わせ窓口
            <br />
            個人情報保護管理者：個人情報お客様相談室 室長
            <br />
            連絡先：TEL 090-7555-4510
          </div>
        </div>
      </section>
    </Content>
    <Footer />
  </Layout>
);

export default Privacy;
