import moment from 'moment/dist/moment';

export const isDate = date => String(new Date(date || '')) !== 'Invalid Date';

export const formatDate = (date, format = 'YYYY/MM/DD') => {
  if (moment.isMoment(date)) {
    return date.format(format);
  }
  return (isDate(date) ? moment(date).format(format) : '');
};

export const toMoment = date => {
  if (Array.isArray(date)) {
    const momentDate = date.map(d => (isDate(d) && !moment.isMoment(d) ? moment(new Date(d)) : d));

    [date[0], date[1]] = momentDate;
    return date;
  }
  return isDate(date) ? moment(date) : date;
};
