import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useTranslation } from 'react-i18next';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { authenticateMeeting, joinMeeting, fetchMeeting } from 'redux/slices/meetings';
import { styles } from './styles';
import ModalUnlock from './ModalUnlock';

const MeetingShow = ({ currentUser }) => {
  const { i18n } = useTranslation();
  const { code } = useParams();
  const dispatch = useDispatch();
  const apiRef = useRef();
  const [modal, setModal] = useState('readyJoinMeeting');
  const { meeting, fetching } = useSelector(state => state.meetings);

  const fetchMeetingData = useCallback(() => {
    const authenticateCode = localStorage.getItem(`${code}__authenticateCode`);
    dispatch(fetchMeeting({
      params: {
        id: code,
        authen_code: authenticateCode,
      },
    }))
      .unwrap()
      .then(data => {
        if (data) { setModal('readyJoinMeeting'); }
      });
  }, [code, dispatch]);

  // eslint-disable-next-line no-unused-vars
  const authMeeting = () => {
    const authenticateCode = localStorage.getItem(`${code}__authenticateCode`);
    dispatch(authenticateMeeting({
      params: {
        code,
        authenticate_code: authenticateCode,
      },
    })).unwrap().then(data => {
      if (data?.needPasscode) {
        setModal('unlock');
      } else {
        fetchMeetingData();
      }
    });
  };

  useEffect(() => {
    // authMeeting();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnlock = useCallback(({ passcode }) => {
    dispatch(joinMeeting({
      params: {
        id: code,
        passcode,
      },
    })).unwrap().then(data => {
      localStorage.setItem(`${code}__authenticateCode`, data?.authenticateCode);
      fetchMeetingData();
    });
  }, [code, dispatch, fetchMeetingData]);

  const handleParticipantJoined = payload => {
    console.log(payload);
    // apiRef.current.executeCommand('grantModerator', payload.id);
  };

  const handleToolbarButtonClicked = e => {
    // Leave meeting or End meeting for all
    if (e.key === 'hangup' || e.key === 'end-meeting') {
      if (e.key === 'hangup') {
        apiRef.current.executeCommand('hangup');
      }

      if (e.key === 'end-meeting') {
        apiRef.current.executeCommand('endConference');
      }
      setTimeout(() => {
        window.location.replace('/meetings');
      }, 300);
    }
  };

  const handleApiReady = apiObj => {
    apiRef.current = apiObj;
    apiRef.current.on('participantJoined', handleParticipantJoined);
    apiRef.current.on('toolbarButtonClicked', handleToolbarButtonClicked);
  };

  return (
    <div className={styles.index}>
      {fetching && <LoadingAllScreen />}
      {modal === 'unlock' && (
        <ModalUnlock
          toggle={() => setModal()}
          submit={handleUnlock}
        />
      )}
      {modal === 'readyJoinMeeting' && (
        <JitsiMeeting
          domain={process.env.REACT_APP_API_MEETING_URL}
          roomName={code}
          getIFrameRef={iframeRef => {
            iframeRef.classList.add('main-meeting');
          }}
          onApiReady={externalApi => handleApiReady(externalApi)}
          userInfo={{
            displayName: currentUser?.name,
            email: currentUser?.email,
            avatarUrl: currentUser?.image,
          }}
          interfaceConfigOverwrite={{
            SHOW_CHROME_EXTENSION_BANNER: false,
          }}
          configOverwrite={{
            defaultLanguage: i18n.language,
            localSubject: meeting.name,
            disableProfile: false,
            prejoinConfig: {
              enabled: true,
              hideDisplayName: false,
              hideExtraJoinButtons: ['no-audio'],
            },
            toolbarButtons: [
              'camera',
              'chat',
              'closedcaptions',
              'desktop',
              'download',
              'etherpad',
              'filmstrip',
              'fullscreen',
              'hangup',
              'highlight',
              'linktosalesforce',
              'microphone',
              'noisesuppression',
              'participants-pane',
              'raisehand',
              'recording',
              'select-background',
              'settings',
              'shareaudio',
              'sharedvideo',
              'stats',
              'tileview',
              'toggle-camera',
              'videoquality',
            ],
            breakoutRooms: {
              hideAddRoomButton: true,
            },
            buttonsWithNotifyClick: ['hangup', 'end-meeting'],
          }}
        />
      )}
    </div>
  );
};

export default MeetingShow;
