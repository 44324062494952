import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { commonStyles, confirmationModal } from './styles';

export const ConfirmationModal = props => {
  const [t] = useTranslation();
  const {
    title,
    header,
    body,
    close,
    submit,
    submitText = t('common.actions.submit'),
    cancelText = t('common.actions.cancel'),
    submitClass = 'save-btn',
    cancelClass = 'cancel-btn',
    submitType = 'primary',
    closable = false,
    className,
    centered = true,
    cancel,
  } = props;

  return (
    <Modal
      open
      centered={centered}
      closable={closable}
      onCancel={close}
      className={`${className} ${confirmationModal} ${commonStyles.modal}`}
      title={title || ''}
      footer={[
        <Button
          key='submit'
          type={submitType}
          className={submitClass}
          onClick={submit}
        >
          {submitText}
        </Button>,
        <Button key='cancel' onClick={cancel || close} className={cancelClass}>
          {cancelText}
        </Button>,
      ]}
    >
      <div className='confirm-modal'>
        {header}
        {body}
      </div>
    </Modal>
  );
};
