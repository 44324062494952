import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import ActionCable from 'actioncable';
import {
  fetchChatRoom, assignMembers, unassignMember, clearChatRoom,
} from 'redux/slices/ChatRooms';
import {
  fetchMessages, addMessage, createMessage, readMessage, removeMessage, clearMessages,
} from 'redux/slices/Messages';
import { camelCaseObject } from 'utils';
import NavBarMobile from 'components/NavBarMobile';
import Emitter from 'utils/emitter';
import Members from './Members';
import Messages from './Messages';
import ChatRooms from './ChatRooms';
import { styles } from './styles';

const Chat = ({ currentUser }) => {
  const {
    chatRooms: {
      chatRoom: currentChatRoom, fetching: chatRoomFetching, isSubmitting,
    },
    messages: {
      messages, fetching: messageFetching, pagination, newMessageIndex,
    },
  } = useSelector(state => state);
  const { organizationKey, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cable = ActionCable.createConsumer(`${process.env.REACT_APP_WS_URL}?token=${localStorage.getItem('token')}`);

  const canChangeAssign = useMemo(() => get(currentUser, 'role') === 'admin' || get(currentUser, 'role') === 'employee', [currentUser]);
  const [swichToMessages, setSwitchToMessages] = useState(false);
  const messageFuncRef = useRef({});

  const handleSaveMessage = useCallback((formValues, chatRoomId) => {
    const formData = new FormData();
    formData.append('body', trim(formValues.body));
    if (formValues.files) {
      [...formValues.files].forEach(file => {
        formData.append('files[]', file);
      });
    }
    dispatch(createMessage({ formData, chatRoomId }));
  }, [dispatch]);

  const handleReceivedMessage = useCallback((chatRoomId, data) => {
    const message = camelCaseObject(data.message);
    if (chatRoomId === message?.chatRoomId) {
      if (!message.deletedAt) {
        dispatch(addMessage(camelCaseObject(message)));
      } else {
        dispatch(removeMessage(camelCaseObject(message)));
      }
    }
  }, [dispatch]);

  const handleLoadMoreMessage = useCallback((chatRoomId, page, callback) => {
    dispatch(fetchMessages({ chatRoomId, page })).unwrap().then(() => {
      callback && callback();
    });
  }, [dispatch]);

  const handleFetchChatRoom = useCallback(chatRoomId => {
    dispatch(fetchChatRoom(chatRoomId)).unwrap().then(data => {
      dispatch(fetchMessages({ chatRoomId: data.chatRoom.id, page: 1 })).unwrap().then(result => {
        const messageList = result.messages;

        if (!isEmpty(messageList)) {
          const lastMessage = messageList[messageList.length - 1];
          dispatch(readMessage({
            params: {
              message_id: lastMessage.id,
              chat_room_id: data.chatRoom.id,
            },
          }));
        }
      });
    });
  }, [dispatch]);

  const handleAssignMember = useCallback((chatRoomId, userIds, callback) => {
    dispatch(assignMembers({ params: { chatRoomId, user_ids: userIds } })).unwrap().then(() => {
      callback && callback();
      if (
        currentUser.role !== 'admin' // is not admin
          && !userIds.includes(currentUser.id) // is not assign in this time
          && (currentChatRoom && !currentChatRoom.users.find(user => user.id === currentUser.id))) { // in current users also do not includes this user
        Emitter.emit('RELOAD_CHAT_ROOMS');
        navigate(`/organizations/${organizationKey}/chat`);
        return;
      }
      handleFetchChatRoom(chatRoomId);
    });
  }, [dispatch, currentUser.role, currentUser.id, currentChatRoom, handleFetchChatRoom, navigate, organizationKey]);

  const handleUnassignMember = useCallback((chatRoomId, formValues, callback) => {
    dispatch(unassignMember({ params: { chatRoomId, ...formValues } })).unwrap().then(() => {
      if (String(formValues.user_id) === String(currentUser.id)) {
        navigate(`/organizations/${organizationKey}/chat`);
      } else {
        handleFetchChatRoom(chatRoomId);
      }
      callback && callback();
    });
  }, [currentUser.id, dispatch, handleFetchChatRoom, navigate, organizationKey]);

  useEffect(() => {
    const channel = cable.subscriptions.create(
      {
        channel: 'ChatRoomsChannel',
        chat_room_id: id,
      },
      {
        connected: () => {},
        received: message => handleReceivedMessage(Number(id), message),
      },
    );

    return () => {
      channel.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      handleFetchChatRoom(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, organizationKey]);

  useEffect(() => {
    if (!id) {
      dispatch(clearChatRoom());
      dispatch(clearMessages());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (swichToMessages) {
      messageFuncRef.current?.scrollToBottom && messageFuncRef.current.scrollToBottom();
    }
  }, [swichToMessages]);

  return (
    <div className={styles.index}>
      <NavBarMobile
        page='chat'
        currentChatRoom={currentChatRoom}
        swichToMessages={swichToMessages}
        setSwitchToMessages={setSwitchToMessages}
      />
      <div className={classnames('chat-page', { 'display-messages': swichToMessages })}>
        <ChatRooms
          fetching={chatRoomFetching}
          chatRoomId={id}
          currentUser={currentUser}
          setSwitchToMessages={setSwitchToMessages}
        />
        <div className='message-content'>
          <Messages
            messages={messages}
            pagination={pagination}
            fetching={messageFetching}
            handleSaveMessage={handleSaveMessage}
            handleLoadMoreMessage={handleLoadMoreMessage}
            handleMoveToAuto={handleUnassignMember}
            currentUser={currentUser}
            chatRoom={currentChatRoom}
            newMessageIndex={newMessageIndex}
            canChangeAssign={canChangeAssign}
            messageFuncRef={messageFuncRef}
          />
        </div>
        <div className='member-content'>
          <Members
            chatRoom={currentChatRoom}
            handleAssignMember={handleAssignMember}
            handleUnassignMember={handleUnassignMember}
            isSubmitting={isSubmitting}
            currentUser={currentUser}
            canChangeAssign={canChangeAssign}
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;
