import React, {
  useCallback, useState, useEffect, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { debounce } from 'lodash';
import Loading from 'components/Loading';
import { fetchMeetings, createMeeting, updateMeeting } from 'redux/slices/meetings';
import { ReactComponent as ScheduleSvg } from 'icons/Schedule.svg';
import ModalScheduleMeeting from 'components/ModalScheduleMeeting';
import JoinMeetingModal from 'components/JoinMeetingModal';
import moment from 'moment';
import { formatDate } from 'utils/date';
import MeetingDetails from './MeetingDetails';
import { styles } from './styles';
import { formatStartTime, dateToFromNowDaily, formatDateTimezone } from './utils';

const Meetings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organizationKey } = useParams();
  const { fetching, meetings, pagination: { nextDay } } = useSelector(state => state.meetings);
  const meetingRef = useRef(null);
  const [modal, setModal] = useState();
  const [selectedMeeting, setSelectedMeeting] = useState();

  const fetchData = useCallback((args = {}) => {
    dispatch(fetchMeetings({
      params: {
        type: 'planned',
        date: formatDate(moment()),
        per_page: 20,
        ...args,
      },
    }));
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationKey]);

  useEffect(() => {
    if (selectedMeeting && !meetings.some(meeting => meeting.id === selectedMeeting.id)) {
      setSelectedMeeting(null);
    }
  }, [selectedMeeting, meetings]);

  const handleCreateMeeting = useCallback(() => {
    dispatch(createMeeting()).unwrap().then(data => {
      if (data.code) {
        navigate(data.code);
      }
    });
  }, [dispatch, navigate]);

  const handleScheduleMeeting = useCallback((values, callback) => {
    const {
      id, name, passcode, timezone, waiting_room, host_video_on, participant_video_on,
      repeat_type, date, start_time, end_time, old_timezone,
    } = values;

    const startTimeToTimezone = formatDateTimezone(start_time, old_timezone, 'HH:mm');
    const endTimeToTimezone = formatDateTimezone(end_time, old_timezone, 'HH:mm');
    const dateToTimezone = formatDateTimezone(date, old_timezone, 'YYYY/MM/DD');
    const startTime = `${dateToTimezone} ${startTimeToTimezone} ${timezone}`;
    const endTime = `${dateToTimezone} ${endTimeToTimezone} ${timezone}`;

    const attributes = {
      id,
      name,
      passcode,
      start_time: startTime,
      end_time: endTime,
      is_public: !waiting_room,
      host_video_on,
      participant_video_on,
      repeat_type,
    };

    if (id) {
      dispatch(updateMeeting({ params: attributes })).unwrap().then(() => {
        callback && callback();
        fetchData();
      });
    } else {
      dispatch(createMeeting({ params: attributes })).unwrap().then(() => {
        callback && callback();
        fetchData();
      });
    }
    setSelectedMeeting();
  }, [dispatch, fetchData, setSelectedMeeting]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadMoreMeeting = useCallback(debounce(() => {
    fetchData({ date: nextDay, is_load_more: true });
  }, 300), [nextDay]);

  useEffect(() => {
    const handleScrollMeeting = () => {
      const { scrollTop, clientHeight, scrollHeight } = meetingRef.current;

      if (scrollTop + clientHeight > (scrollHeight - 100)) {
        if (nextDay && !fetching) {
          handleLoadMoreMeeting();
        }
      }
    };
    meetingRef.current?.addEventListener('scroll', handleScrollMeeting);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      meetingRef.current?.removeEventListener('scroll', handleScrollMeeting);
    };
  }, [fetchData, fetching, handleLoadMoreMeeting, nextDay]);

  const meetingContent = useMemo(() => {
    const groupDate = meetings.reduce((group, item) => {
      const date = formatStartTime(item.startTime, 'YYYY/MM/DD');
      if (!group[date]) {
        group[date] = [];
      }
      group[date].push(item);
      return group;
    }, {});

    return Object.keys(groupDate).map((date, i) => (
      <div key={`${date}-${i}`}>
        <div className='date'>{dateToFromNowDaily(date)}</div>
        <div>
          {groupDate[date].map((meeting, index) => (
            <div
              key={`meeting-${meeting.id}-${index}`}
              onClick={() => setSelectedMeeting(meeting)}
              className={`meeting-card ${meeting.id === selectedMeeting?.id ? 'active' : ''}`}
            >
              <span className='meeting-name'>{meeting.name}</span>
              <span className='time'>{`${formatStartTime(meeting.startTime)}-${formatStartTime(meeting.endTime)}`}</span>
              <span className='code'>{`${t('meetings.code')}: ${meeting.code}`}</span>
            </div>
          ))}
        </div>
      </div>
    ));
  }, [meetings, selectedMeeting, t]);

  return (
    <div className={styles.index}>
      <div className='meeting-page'>
        <div className='meeting-content'>
          <div className='btn-group'>
            <div className='title'>{t('meetings.title')}</div>
            <button className='schedule-btn' onClick={() => setModal('schedule_new_meeting')}>
              <ScheduleSvg className='icon-schedule' />
              {t('meetings.schedule_meeting')}
            </button>
          </div>
          <div className='btn-group'>
            <Button
              className='default-btn'
              onClick={handleCreateMeeting}
            >
              {t('meetings.start_new')}
            </Button>
            <Button
              className='default-btn'
              onClick={() => setModal('join_meeting')}
            >
              {t('meetings.join_with_code')}
            </Button>
          </div>
          <div className='list-meetings' ref={meetingRef}>
            {meetingContent}
            {fetching && <Loading className='h-14 mt-2 flex justify-center' />}
          </div>
        </div>
        {selectedMeeting && (
          <MeetingDetails
            meeting={selectedMeeting}
            submit={handleScheduleMeeting}
          />
        )}
      </div>
      {modal === 'schedule_new_meeting' && (
        <ModalScheduleMeeting
          toggle={() => setModal()}
          submit={handleScheduleMeeting}
        />
      )}
      {modal === 'join_meeting' && (
        <JoinMeetingModal toggle={() => setModal()} />
      )}
    </div>
  );
};

export default Meetings;
