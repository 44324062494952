import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';

export const styles = {
  inputForm: css`
    .label-form {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .input-field-form {
      width: 100%;
      height: 48px;
      border-radius: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-top: 2px;
    }

    .textarea-field {
      width: 100%;
      height: 150px;
      border-radius: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;

      &.ant-input-textarea-show-count {
        textarea {
          width: 100%;
          height: 150px;
          border-radius: 12px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          resize: none;
        }

        &:after {
          float: left;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: ${COLORS.silverChalice};
          margin-top: 2px;
        }
      }
    }

    .invalid-field {
      border-color: red;
    }
  `,
  fileForm: css`
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 20px;
    background-color: ${COLORS.mercury};

    .image-field {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }

    .select-file {
      width: 100px;
      border-radius: 6px;
    }

    .upload-btn {
      position: absolute;
      right: -8px;
      bottom: -8px;
      z-index: 2;
      background-color: ${COLORS.white};
      border-radius: 50%;
      padding: 6px;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  `,
};

export const customSelectForm = css`
  width: 100%;

  .ant-select {
    width: 100%;

    .ant-select-selector {
      height: 48px;
      border-radius: 12px;
      align-items: center;
      background-color: ${COLORS.athensGray};

      .ant-select-selection-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${COLORS.mineShaft};
      }
    }
  }
`;

export const customCheckboxForm = css`
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;

    .ant-checkbox {
      top: 0;

      &:not(.ant-checkbox-checked) {
        .ant-checkbox-inner {
          border: 2px solid ${COLORS.silverChalice};
        }
      }
    }

    .label-group {
      display: flex;
      flex-direction: column;
      margin-left: 12px;

      .text-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: ${COLORS.mineShaft};
      }

      .text-description {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: ${COLORS.silverChalice};
      }
    }
  }
`;

export const customRadioGroupForm = css`
  .radio-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${COLORS.mineShaft};
  }
`;
