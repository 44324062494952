export const removeAllLargeAndSmallVideos = () => {
  document.querySelectorAll('.large-video').forEach(contentEl => {
    contentEl.classList.remove('large-video');
    contentEl.classList.remove('small-video');
  });
};

export const toggleLargeVideo = elementVideoId => {
  const selectedElement = document.getElementById(elementVideoId);
  const videoConferencePage = document.getElementById('videoConferencePage');
  const videoContainers = document.querySelectorAll('.video-container');

  if (!selectedElement) { return; }

  const hasClassLargeVideo = selectedElement && selectedElement.classList?.contains('large-video');
  videoContainers.forEach(contentEl => {
    if (hasClassLargeVideo) {
      contentEl.classList.remove('large-video');
      contentEl.classList.remove('small-video');
      videoConferencePage.classList.remove('large-video');
    } else if (contentEl.id === elementVideoId) {
      contentEl.classList.add('large-video');
      videoConferencePage.classList.add('large-video');
      contentEl.classList.remove('small-video');
    } else {
      contentEl.classList.remove('large-video');
      contentEl.classList.add('small-video');
      videoConferencePage.classList.add('large-video');
    }
  });

  if (videoContainers.length === 1 && !hasClassLargeVideo) {
    selectedElement.classList.add('full-width');
  } else {
    selectedElement.classList.remove('full-width');
  }
};

export const activeBackground = (selected, background) => (selected === background ? 'selected' : '');

export const isCurrentSendMessage = (t, currentUser, sender) => (currentUser.id === sender.id ? t('meetings.show.you') : sender.name);

export const isDefaultTurnOnVideo = (currentUser, meeting) => (currentUser.isHost && meeting.hostVideoOn || !currentUser.isHost && meeting.participantVideoOn);

export const runPostProcessing = ({
  virtualVideoElement, segmentationStream, canvasOutputCtx,
  segmentationMaskCanvas, videoWidth, videoHeight,
}) => {
  canvasOutputCtx.clearRect(0, 0, videoWidth, videoHeight);
  canvasOutputCtx.globalCompositeOperation = 'copy';
  canvasOutputCtx.filter = 'none';
  canvasOutputCtx.filter = `blur(${segmentationStream.edgeBlur}px)`;
  canvasOutputCtx.drawImage(segmentationMaskCanvas, 0, 0, videoWidth, videoHeight);
  canvasOutputCtx.globalCompositeOperation = 'source-in';
  canvasOutputCtx.filter = 'none';
  canvasOutputCtx.drawImage(virtualVideoElement, 0, 0, videoWidth, videoHeight);
  canvasOutputCtx.globalCompositeOperation = 'destination-over';
  if (segmentationStream.backgroundImage) {
    canvasOutputCtx.drawImage(segmentationStream.backgroundImage, 0, 0, videoWidth, videoHeight);
  }
  if (segmentationStream.isBlur) {
    canvasOutputCtx.filter = 'blur(10px)';
    canvasOutputCtx.drawImage(virtualVideoElement, 0, 0, videoWidth, videoHeight);
  }
  canvasOutputCtx.restore();
};

export const perform = async ({
  bodyPixNet, canvasOutputCtx, videoWidth, videoHeight, segmentationStream,
  segmentationMaskCtx, segmentationMaskCanvas, segmentationMask,
}) => {
  let running = true;
  const virtualVideoElement = document.getElementById('virtualVideo');
  const canvasOutputElement = document.getElementById('canvasOutput');
  canvasOutputCtx = canvasOutputElement.getContext('2d');

  while (running) {
    segmentationMaskCtx.clearRect(0, 0, videoWidth, videoHeight);
    segmentationMaskCtx.drawImage(virtualVideoElement, 0, 0, videoWidth, videoHeight);

    // eslint-disable-next-line no-await-in-loop
    const segmentation = await bodyPixNet.segmentPerson(segmentationMaskCanvas, {
      flipHorizontal: false, // Whether to flip the input video horizontally
      internalResolution: 'medium', // The resolution for internal processing (options: 'low', 'medium', 'high')
      segmentationThreshold: 0.7, // Segmentation confidence threshold (0.0 - 1.0)
      maxDetections: 10, // Maximum number of detections to return
      scoreThreshold: 0.2, // Confidence score threshold for detections (0.0 - 1.0)
      nmsRadius: 20, // Non-Maximum Suppression (NMS) radius for de-duplication
      minKeypointScore: 0.3, // Minimum keypoint detection score (0.0 - 1.0)
      refineSteps: 10, // Number of refinement steps for segmentation
    });

    for (let i = 0; i < segmentationStream.pixelCount; i += 1) {
      segmentationMask.data[i * 4 + 3] = segmentation.data[i] ? 255 : 0;
    }
    if (segmentationStream.isEnabled) {
      segmentationMaskCtx.putImageData(segmentationMask, 0, 0);
    }

    runPostProcessing({
      virtualVideoElement,
      segmentationStream,
      canvasOutputCtx,
      segmentationMaskCanvas,
      videoWidth,
      videoHeight,
    });

    if (document.getElementById('localVideo').hasAttribute('hidden')) {
      running = false;
      canvasOutputCtx.clearRect(0, 0, videoWidth, videoHeight);
      canvasOutputCtx.restore();
    }
  }
};
