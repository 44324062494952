import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';

export default css`
  .ant-modal-body {
    .header-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: ${COLORS.mineShaft};
      margin-top: 24px;
      margin-bottom: 16px;
    }

    .input-form {
      .input-name {
        width: 100%;
        border: none;
        border-bottom: 1px solid ${COLORS.mercury};
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: ${COLORS.mineShaft};
        padding: 0;

        &:focus {
          box-shadow: none;
          border-bottom: 1px solid ${COLORS.dodgerBlue};
        }
      }
    }

    .datetime-group {
      display: flex;
      margin: 16px 0;

      .time-to {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        padding: 15px 8px;
      }

      .date-form .input-field-form{
        width: 264px;
        height: 48px;
        margin-right: 18px;
      }

      .time-form .input-field-form{
        width: 67px;
        height: 48px;
      }
    }

    .select-form-group {
      display: flex;
      align-items: center;

      .select-form {
        width: 264px;
        height: 48px;
        margin-right: 18px;

        .ant-select-selector {
          background-color: ${COLORS.athensGray};
          border: none;
        }
      }
    }

    .code-group {
      margin-bottom: 16px;

      .input-form {
        width: 264px;
        margin-top: 6px;
        margin-left: 36px;
      }
    }

    .video-group {
      display: flex;
      align-items: center;
      column-gap: 32px;
      margin-bottom: 26px;
    }
  }
`;
