import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContactsService from '../services/ContactsService';

export const createContact = createAsyncThunk(
  'contacts/CREATE_CONTACT',
  async (data, thunkAPI) => {
    try {
      const result = await ContactsService.createContact(data);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

const initialState = {
  isSubmitting: false,
  isSubmitted: false,
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  extraReducers: {
    [createContact.pending]: state => {
      state.isSubmitting = true;
      state.isSubmitted = false;
    },
    [createContact.fulfilled]: state => {
      state.isSubmitting = false;
      state.isSubmitted = true;
    },
    [createContact.rejected]: state => {
      state.isSubmitting = false;
      state.isSubmitted = true;
    },
  },
});

const { reducer } = contactsSlice;
export default reducer;
