import { css } from '@emotion/css';

export const styles = {
  index: css`
    width: 100%;
    display: flex;
    justify-content: center;

    .main-meeting {
      width: 100%;
      height: 100vh;
    }
  `,
};

export const modalUnlock = css`
  .ant-modal-content .ant-modal-body {
    padding: 24px 16px;
  }
`;
