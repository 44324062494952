import React, { useCallback, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { login, getProfile } from 'redux/slices/auth';
import { InputForm } from 'components/forms/InputForm';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { defaultRootPath } from 'utils';
import { styles } from './styles';

const schema = t => yup.object({
  email: yup.string().required(t('common.error.required')).max(100, t('common.error.max_length', { max: 100 })),
  password: yup.string().required(t('common.error.required')).min(6, t('common.error.min_length', { min: 6 }))
    .max(100, t('common.error.max_length', { max: 100 })),
}).required();

const Login = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, currentUser, fetchedProfile } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  });
  const { handleSubmit, formState: { isSubmitting } } = methods;

  const handleLogin = useCallback(formValue => {
    const { email, password } = formValue;

    dispatch(login({ email, password }))
      .unwrap()
      .then(data => {
        i18n.changeLanguage(data?.language || 'ja');
        toast.success(t('login.success'));
      });
  }, [dispatch, i18n, t]);

  useEffect(() => {
    if (isLoggedIn && !currentUser) {
      dispatch(getProfile());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoggedIn]);

  if (isLoggedIn && currentUser) {
    if (currentUser.role === 'system_admin') {
      return <Navigate to='/organizations' />;
    }
    return <Navigate to={defaultRootPath(currentUser)} />;
  }

  if (isLoggedIn && !currentUser && !fetchedProfile) {
    return <LoadingAllScreen />;
  }

  return (
    <div className={`login-form ${styles.login}`}>
      {isSubmitting && (<LoadingAllScreen />)}
      <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full max-w-md space-y-8'>
          <div>
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              {t('login.title')}
            </h2>
          </div>
          <FormProvider {...methods}>
            <div className='mt-8 space-y-6'>
              <input type='hidden' name='remember' defaultValue='true' />
              <div className='-space-y-px rounded-md shadow-sm'>
                <div className='mb-4'>
                  <InputForm
                    label={t('login.form.email')}
                    name='email'
                    type='email'
                    className='input-field'
                  />
                </div>
                <div className='mb-4'>
                  <InputForm
                    label={t('login.form.password')}
                    name='password'
                    type='password'
                    className='input-field'
                  />
                </div>
              </div>
              <div>
                <button
                  type='submit'
                  className='submit-btn ant-btn ant-btn-primary'
                  disabled={isSubmitting}
                  onClick={handleSubmit(handleLogin)}
                >
                  <span>{t('login.submit')}</span>
                </button>
              </div>
              <div className='flex justify-end'>
                <Link
                  to='/forgot_password'
                  className='redirect-btn'
                >
                  {t('forgot_password')}
                </Link>
              </div>
            </div>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Login;
