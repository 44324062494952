import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ActionCable from 'actioncable';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { camelCaseObject } from 'utils';
import { updateChatRoomList, fetchChatRooms, clearChatRooms } from 'redux/slices/ChatRooms';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'components/Loading';
import Emitter from 'utils/emitter';
import ChatRoomCard from './ChatRoomCard';
import { styles } from './styles';
import Filter from './Filter';

const ChatRooms = React.memo(({
  chatRoomId,
  currentUser,
  setSwitchToMessages,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { organizationKey } = useParams();
  const [filterType, setFilterType] = useState('filter_all');
  const [filterStatus, setFilterStatus] = useState('filter_status_all');
  const [searchTerm, setSearchTerm] = useState('');
  const [chatType, setChatType] = useState('auto');
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const chatRoomsRef = useRef();

  const {
    chatRooms, chatRoom: currentChatRoom, fetching,
  } = useSelector(state => state.chatRooms);

  const fetchDataChatRooms = useCallback((args = {}, loadMore = true) => {
    const currentChatType = args.chatType || chatType;
    const currentPage = args.page || 1;
    const currentFilterType = args.filterType || filterType;
    const currentFilterStatus = args.filterStatus || filterStatus;
    const currentSearchTerm = args.searchTerm ?? searchTerm;

    setChatType(currentChatType);
    setPage(currentPage);
    setFilterStatus(currentFilterStatus);
    setFilterType(currentFilterType);
    setSearchTerm(currentSearchTerm);

    const filter = { search_term: currentSearchTerm, status: currentFilterStatus };

    if (currentChatType === 'manual') {
      filter.type = currentFilterType;
    }

    dispatch(fetchChatRooms({
      params: {
        per_page: 10,
        page: currentPage,
        filter,
        chat_type: currentChatType,
      },
      loadMore,
    })).unwrap().then(data => {
      setTotal(data?.total || 0);
      if (data?.total && chatRoomsRef.current && data.total > currentPage * 10) {
        setTimeout(() => {
          const container = chatRoomsRef.current;

          // Check if the container has a vertical scrollbar
          const hasVerticalScrollbar = container.scrollHeight > container.clientHeight;

          // Check if there is no scrollbar
          if (!hasVerticalScrollbar) {
            fetchDataChatRooms({ ...args, page: currentPage + 1 });
          }
        });
      }
    });
  }, [chatType, dispatch, filterStatus, filterType, searchTerm]);
  // );

  const cable = ActionCable.createConsumer(`${process.env.REACT_APP_WS_URL}?token=${localStorage.getItem('token')}`);

  useEffect(() => {
    const handleReceivedChatRoomList = data => {
      if (organizationKey === data?.organization_key && currentUser.organization_ids.includes(data?.organization_id)) {
        const chatRoom = camelCaseObject(data.chat_room);
        let isJoined = false;

        if (chatRoom.userId === currentUser.id || currentUser.role === 'admin' || (currentUser.role === 'employee' && chatRoom.chatType === 'auto')) {
          isJoined = true;
        } else {
          isJoined = chatRoom?.users.some(user => user.id === currentUser.id);
        }

        if (currentUser.role === 'customer' || (isJoined && chatType === chatRoom.chatType)) {
          dispatch(updateChatRoomList(chatRoom));
        }
      }
    };

    const channel = cable.subscriptions.create(
      { channel: 'UserChannel' },
      {
        connected: () => {},
        received: data => handleReceivedChatRoomList(data),
      },
    );

    return () => {
      channel.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentUser), chatType, organizationKey]);

  useEffect(() => {
    if (currentChatRoom?.chatType) {
      setChatType(currentChatRoom.chatType);
    }
  }, [currentChatRoom?.chatType]);

  useEffect(() => {
    const handler = () => {
      dispatch(clearChatRooms());
      fetchDataChatRooms();
    };

    Emitter.on('RELOAD_CHAT_ROOMS', handler);
    handler();

    return () => {
      Emitter.off('RELOAD_CHAT_ROOMS', handler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationKey, chatType]);

  return (
    <div className={`chat-room-content ${styles.chatRooms}`}>
      {(['admin', 'employee'].includes(currentUser.role)) && (
        <div className='chat-mode'>
          <button
            className={`${chatType === 'auto' ? 'active' : ''}`}
            onClick={() => setChatType('auto')}
          >
            {t('chat_rooms.auto')}
          </button>
          <button
            className={`${chatType === 'manual' ? 'active' : ''}`}
            onClick={() => setChatType('manual')}
          >
            {t('chat_rooms.manual')}
          </button>
        </div>
      )}
      { ['admin', 'employee'].includes(currentUser.role) && (
        <Filter
          filterStatus={filterStatus}
          filterType={filterType}
          fetchDataChatRooms={fetchDataChatRooms}
          chatType={chatType}
          curentSearchTerm={searchTerm}
        />
      )}

      <div className='chat-room-list' id='root-scrollable' ref={chatRoomsRef}>
        {fetching && (
          <Loading style={{ backgroundColor: 'transparent' }} />
        )}
        {currentUser?.role === 'customer' && isEmpty(chatRooms) && (
          <div className='no-chat-rooms'>{t('chat_rooms.no_chat_rooms')}</div>
        )}
        <InfiniteScroll
          dataLength={chatRooms.length}
          next={() => fetchDataChatRooms({ page: page + 1 })}
          hasMore={total > chatRooms.length}
          scrollableTarget='root-scrollable'
        >
          {chatRooms.map((chatRoom, index) => (
            <ChatRoomCard
              currentChatRoomId={chatRoomId}
              chatRoom={chatRoom}
              index={index}
              setSwitchToMessages={setSwitchToMessages}
              key={`ChatRoomCard-${index}`}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
});

export default ChatRooms;
