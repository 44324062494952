import React, {
  useCallback, useMemo, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from 'antd';
import { formatDate } from 'utils/date';
import { InputForm } from 'components/forms/InputForm';
import UserAvatar from 'components/UserAvatar';
import { ReactComponent as SendSvg } from 'icons/Send.svg';
import { ReactComponent as CloseSvg } from 'icons/CloseSvg.svg';
import { isCurrentSendMessage } from './utils';
import { sideToolbar, chatPane } from './styles';

const schema = t => yup.object({
  body: yup.string().required(t('common.error.required')),
});

const Chat = ({
  isDisplay, channelRef, code, currentUser, onClose,
}) => {
  const [t] = useTranslation();
  const { messages, newMessageIndex } = useSelector(state => state.meetings);
  const messageList = useRef(null);

  const methods = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues: { body: '' },
  });

  const { handleSubmit, reset, formState: { isSubmitting } } = methods;

  const sendMessage = useCallback(formValues => {
    channelRef.current.send({
      ...formValues,
      command_type: 'message',
      code,
      user: currentUser,
    });
    reset();
  }, [channelRef, code, currentUser, reset]);

  const onChatKeyPress = useCallback(e => {
    if (e.which === 13 && !e.shiftKey) {
      handleSubmit(sendMessage)();
    }
  }, [handleSubmit, sendMessage]);

  useEffect(() => {
    setTimeout(() => {
      if (messageList.current) {
        const { scrollTop, clientHeight, scrollHeight } = messageList.current;
        if ((scrollTop === 0) || (scrollHeight - (scrollTop + clientHeight) < 200)) {
          messageList.current?.scroll({ top: scrollHeight });
        }
      }
    }, 300);
  }, [code, newMessageIndex]);

  const renderMessages = useMemo(() => messages.map((message, index) => (
    <div className='message-card' key={`message-${index}`}>
      <div className='sender-avatar'>
        <UserAvatar size={32} url={message?.user?.image} />
      </div>
      <div className='message-card-content'>
        <div className='group-name'>
          <div className='name'>{isCurrentSendMessage(t, currentUser, message?.user)}</div>
          <div className='date'>{formatDate(message.createdAt, 'hh:mma')}</div>
        </div>
        <div className='message-body'>{message.body}</div>
      </div>
    </div>
  )), [currentUser, messages, t]);

  return (
    <div className={`${chatPane} ${sideToolbar} ${!isDisplay && 'hidden'}`}>
      <div className='header'>
        <div className='title'>
          {t('meetings.show.chat')}
        </div>
        <CloseSvg className='cursor-pointer' onClick={onClose} />
      </div>
      <div className='message-container'>
        <div className='message-list' ref={messageList}>
          {renderMessages}
        </div>
        <div className='message-form'>
          <FormProvider {...methods}>
            <InputForm
              name='body'
              className='message-input'
              errorClassName='hidden'
              placeholder={t('chat_rooms.placeholder_type_message')}
              onKeyPress={onChatKeyPress}
            />
            <Button
              className='send-btn'
              disabled={isSubmitting}
              icon={<SendSvg />}
              onClick={handleSubmit(sendMessage)}
            />
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Chat;
