import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Button, Modal, Input } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import FileForm from 'components/forms/FileForm';
import { InputForm } from 'components/forms/InputForm';
import { SUPPORTED_IMAGE_FORMATS } from 'utils/constants';
import { commonStyles } from 'components/styles';
import { addUserModal } from './styles';

const schema = t => yup.object({
  name: yup.string().required(t('common.error.required')).max(100, t('common.error.max_length', { max: 100 })),
  email: yup.string().required(t('common.error.required')).email(t('common.error.email_required')).max(250, t('common.error.max_length', { max: 250 })),
  introduction: yup.string().nullable().max(10000, t('common.error.max_length', { max: 10000 })),
});

const newMemberSchema = t => schema(t).concat(yup.object().shape({
  password: yup.string()
    .required(t('common.error.required'))
    .min(6, t('common.error.min_length', { min: 6 }))
    .max(100, t('common.error.max_length', { max: 100 })),
  confirmPassword: yup.string()
    .required(t('common.error.required'))
    .oneOf([yup.ref('password'), null], t('common.error.password_must_match'))
    .min(6, t('common.error.min_length', { min: 6 }))
    .max(100, t('common.error.max_length', { max: 100 })),
}));

const updateMemberSchema = t => schema(t).concat(yup.object().shape({
  password: yup.string().when('password', {
    is: value => value && value?.length > 0,
    then: yup.string().min(6, t('common.error.min_length', { min: 6 })).max(100, t('common.error.max_length', { max: 100 })),
    otherwise: yup.string(),
  }),
}, [['password', 'password']]));

const ModalAddUser = ({
  handleCancel,
  editUser,
  handleSaveUser,
  isSubmitting,
}) => {
  const [t] = useTranslation();
  const [imageSrc, setImageSrc] = useState(editUser?.image);

  const defaultValues = useMemo(() => ({
    name: editUser?.name || '',
    username: editUser?.username || '',
    email: editUser?.email || '',
    password: editUser?.unencryptedPassword || '',
    introduction: editUser?.introduction || '',
    image: undefined,
  }), [editUser]);

  const methods = useForm({
    resolver: yupResolver(editUser ? updateMemberSchema(t) : newMemberSchema(t)),
    defaultValues,
  });

  const {
    handleSubmit, reset, setValue,
  } = methods;

  const handleChangeImage = e => {
    setValue('image', e.target.files);
    setImageSrc(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null);
  };

  const handleResetForm = useCallback(() => {
    reset(defaultValues);
    setImageSrc(editUser?.image);
  }, [defaultValues, editUser, reset]);

  useEffect(() => {
    handleResetForm();
  }, [editUser, handleResetForm]);

  const saveUser = useCallback(formValues => {
    handleSaveUser(formValues, editUser?.id, () => {
      handleCancel();
      handleResetForm();
    });
  }, [editUser?.id, handleCancel, handleResetForm, handleSaveUser]);

  const modalAttribute = useMemo(() => {
    if (editUser) {
      return {
        title: t('users.title_edit'),
        submitBtn: 'save-btn',
        submitText: t('common.actions.save'),
      };
    }
    return {
      title: t('users.title_new'),
      submitBtn: 'save-btn',
      submitText: t('common.actions.add'),
    };
  }, [editUser, t]);

  return (
    <Modal
      title={modalAttribute.title}
      open
      onCancel={handleCancel}
      className={`${commonStyles.modal} ${addUserModal}`}
      footer={[
        <Button
          key='submit'
          type='primary'
          className={modalAttribute.submitBtn}
          loading={isSubmitting}
          onClick={handleSubmit(saveUser)}
        >
          {modalAttribute.submitText}
        </Button>,
      ]}
    >
      <FormProvider {...methods}>
        <div className='new-form'>
          <div className='image-content'>
            <FileForm
              name='image'
              imageSrc={imageSrc}
              fileTypesAccept={SUPPORTED_IMAGE_FORMATS}
              onChange={handleChangeImage}
            />
          </div>
          <div className='form-content'>
            <InputForm
              label={t('users.form.name')}
              name='name'
            />
            <InputForm
              label={t('users.form.introduction')}
              name='introduction'
              Component={Input.TextArea}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
            <InputForm
              label={t('users.form.username')}
              name='username'
            />
            <InputForm
              label={t('users.form.email')}
              name='email'
              type='email'
            />
            <InputForm
              Component={Input.Password}
              label={t('users.form.password')}
              name='password'
            />
            {!editUser && (
              <InputForm
                Component={Input.Password}
                label={t('users.form.confirm_password')}
                name='confirmPassword'
              />
            )}
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default ModalAddUser;
