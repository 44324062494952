import defaultImage from 'images/no-image.png';

export const getImageSrc = (imageUrl, defaultImageUrl = null) => {
  if (imageUrl) {
    return imageUrl;
  } if (defaultImageUrl) {
    return defaultImageUrl;
  }
  return defaultImage;
};
