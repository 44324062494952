import React, {
  useEffect, useCallback, useState, useMemo, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActionCable from 'actioncable';
import { toast } from 'react-toastify';
import { camelCaseObject } from 'utils';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { authenticateMeeting, joinMeeting, fetchMeeting } from 'redux/slices/meetings';
import { getProfile } from 'redux/slices/auth';
import { v4 as uuid } from 'uuid';
import waitingImage from 'images/waiting.png';
import JitsiComponent from './JitsiComponent';
import ModalUnlock from '../ModalUnlock';
import { styles } from './styles';

const cable = ActionCable.createConsumer(`${process.env.REACT_APP_WS_URL}?token=${localStorage.getItem('token')}`);

const CustomMeeting = () => {
  const [t] = useTranslation();
  const { isLoggedIn, currentUser } = useSelector(state => state.auth);
  const { meeting, fetching } = useSelector(state => state.meetings);
  const { code } = useParams();
  const dispatch = useDispatch();

  const guestId = useMemo(() => {
    const storedId = localStorage.getItem('MeetingGuestId');

    if (storedId) {
      return storedId;
    }

    const newId = uuid();

    localStorage.setItem('MeetingGuestId', newId);

    return newId;
  }, []);

  const [modal, setModal] = useState();
  const channelRef = useRef(null);
  const receivedMessageRef = useRef(null);
  const receivedRequestJoiningRef = useRef(null);

  const userInfo = useMemo(() => ({
    id: guestId,
    name: `Guest ${guestId.substring(0, 6)}`,
    image: null,
    isHost: meeting?.user?.id === currentUser?.id,
    isSharing: false,
    ...currentUser,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [currentUser, meeting]);

  const requestJoining = useCallback(() => {
    setModal('waiting_room');
  }, []);

  const fetchMeetingData = useCallback(() => {
    const authenticateCode = localStorage.getItem(`${code}__authenticateCode`);
    dispatch(fetchMeeting({
      params: {
        id: code,
        authen_code: authenticateCode,
      },
    }));
  }, [code, dispatch]);

  const handleUnlock = useCallback(({ passcode }) => {
    dispatch(joinMeeting({
      params: {
        id: code,
        passcode,
      },
    })).unwrap().then(data => {
      localStorage.setItem(`${code}__authenticateCode`, data?.authenticateCode);
      fetchMeetingData();
    });
  }, [code, dispatch, fetchMeetingData]);

  const verifyMeeting = useCallback(() => {
    const authenticateCode = localStorage.getItem(`${code}__authenticateCode`);
    dispatch(authenticateMeeting({
      params: {
        code,
        authenticate_code: authenticateCode,
      },
    })).unwrap().then(data => {
      if (data?.needPasscode) {
        setModal('unlock');
      } else {
        fetchMeetingData();
      }
    });
  }, [code, dispatch, fetchMeetingData]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!currentUser) {
        dispatch(getProfile());
      } else {
        verifyMeeting();
      }
    } else {
      verifyMeeting();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, currentUser]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (modal === 'waiting_room') {
        channelRef.current.send({
          code,
          command_type: 'request_joining',
          user: userInfo,
        });
      } else {
        clearInterval(intervalId);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [code, modal, userInfo]);

  useEffect(() => {
    if (!channelRef.current && meeting?.user) {
      channelRef.current = cable.subscriptions.create(
        {
          channel: 'MeetingChannel',
          code,
        },
        {
          connected: () => {},
          received: e => {
            const data = camelCaseObject(e);
            switch (data.commandType) {
              case 'message':
                return receivedMessageRef.current(data);
              case 'request_joining':
                if (userInfo.isHost) {
                  return receivedRequestJoiningRef.current(data);
                }
                break;
              case 'approve_joining':
                if (data.user.id === userInfo.id) {
                  return setModal('ready_join_meeting');
                }
                break;
              case 'reject_joining':
                if (data.user.id === userInfo.id) {
                  return toast.warning(t('meetings.show.reject_joining'));
                }
            }
          },
        },
      );

      if (meeting?.user?.id === currentUser?.id || meeting.isPublic) {
        setModal('ready_join_meeting');
      } else {
        requestJoining();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, modal, userInfo, meeting?.user, meeting?.isPublic, currentUser]);

  const renderMeeting = useMemo(() => {
    switch (modal) {
      case 'unlock':
        return <ModalUnlock toggle={() => setModal()} submit={handleUnlock} />;
      case 'waiting_room':
        return (
          <div className='waiting-room'>
            <img className='waiting-image' src={waitingImage} alt='waiting room' />
            <div className='waiting-accept'>{t('meetings.show.waiting_accept')}</div>
            <div className='text-session'>{meeting.organizationName}</div>
          </div>
        );
      case 'ready_join_meeting':
        return (
          <JitsiComponent
            meeting={meeting}
            currentUser={userInfo}
            channelRef={channelRef}
            receivedMessageRef={receivedMessageRef}
            receivedRequestJoiningRef={receivedRequestJoiningRef}
          />
        );
    }
  }, [handleUnlock, meeting, modal, t, userInfo]);

  return (
    <div className={styles.index}>
      {fetching && <LoadingAllScreen />}
      {renderMeeting}
    </div>
  );
};
export default CustomMeeting;
