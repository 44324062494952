import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/date';
import { get } from 'lodash';
import { Divider } from 'antd';
import { ReactComponent as TrashSvg } from 'icons/Trash.svg';
import { deleteMessage } from 'redux/slices/Messages';
import UserAvatar from 'components/UserAvatar';
import confirm from 'components/Confirm';
import { useParams } from 'react-router-dom';
import { messageDateFormat, isSameTimeMessage, replaceContentWithLinks } from './utils';
import MessageAttachments from './MessageAttachments';
import { styles } from './styles';

const MessageList = ({ messages = [] }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { organizationKey } = useParams();
  const { currentUser } = useSelector(state => state.auth);

  const isCustomer = useMemo(() => currentUser?.role === 'customer', [currentUser?.role]);
  const organization = useMemo(() => (currentUser?.organizations || []).find(org => org.key === organizationKey), [currentUser?.organizations, organizationKey]);
  const getActor = useCallback(message => {
    const user = get(message, 'user', {});
    if (isCustomer && user.role !== 'customer') {
      return organization || {};
    }

    return user;
  }, [isCustomer, organization]);

  const groupDate = messages.reduce((group, item) => {
    const date = formatDate(item.createdAt);
    if (!group[date]) {
      group[date] = [];
    }
    group[date].push(item);
    return group;
  }, {});

  const handleDeleteMessage = useCallback(async message => {
    const result = await confirm({
      title: (
        <>
          {t('chat_rooms.confirm_delete_message')}
          <div className='text-16 font-normal'>{t('chat_rooms.confirm_delete_message_notice')}</div>
        </>
      ),
      submitText: t('common.actions.remove'),
      className: styles.deleteMessageModal,
    });
    if (result) {
      dispatch(deleteMessage({ chatRoomId: message.chatRoomId, id: message.id }));
    }
  }, [dispatch, t]);

  const renderMessageBody = useCallback(message => (
    <div className='message-card-content'>
      <div className='message-body'>
        <div className='message-text-and-files'>
          {message.deletedAt ? (
            <div className='received-msg deleted'>{t('chat_rooms.messages.message_deleted')}</div>
          ) : (
            <>
              {message.body && <div className='received-msg'>{replaceContentWithLinks(message.body)}</div>}
              {message.files && <MessageAttachments files={message.files} />}
            </>
          )}
        </div>
        {(currentUser.id === message.userId && !message.deletedAt) && (
          <TrashSvg className='trash-icon' onClick={() => handleDeleteMessage(message)} />
        )}
      </div>
    </div>
  ), [currentUser.id, handleDeleteMessage, t]);

  return Object.keys(groupDate).map((date, i) => (
    <div key={`${date}-${i}`}>
      <Divider>{messageDateFormat(date)}</Divider>
      {groupDate[date].map((message, index) => (
        <div key={`message-${message.id}-${index}`}>
          {isSameTimeMessage(message, groupDate[date][index - 1]) ? (
            <div className='message-card same-time-message'>
              <div className='sender-avatar' />
              <div className='message-card-content'>
                {renderMessageBody(message)}
              </div>
            </div>
          ) : (
            <div className='message-card'>
              <div className='sender-avatar'>
                <UserAvatar size={32} url={getActor(message).image} />
              </div>
              <div className='message-card-content'>
                <div className='group-name'>
                  <div className='name'>{getActor(message).name}</div>
                  <div className='date'>{formatDate(message.createdAt, 'hh:mma')}</div>
                </div>
                {renderMessageBody(message)}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  ));
};

export default MessageList;
