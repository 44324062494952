import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from 'antd';
import { isBlank } from 'utils';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { ReactComponent as SearchSvg } from 'icons/Search.svg';
import { ReactComponent as GridSvg } from 'icons/Grid.svg';
import { ReactComponent as ListSvg } from 'icons/List.svg';
import { ReactComponent as PlusSvg } from 'icons/Plus.svg';
import { getImageSrc } from 'utils/fileHelper';
import { suggestionOrganizations } from 'redux/slices/organizations';
import UserMenu from 'components/UserMenu';
import { searchResults } from './styles';

const { Option } = AutoComplete;

const Header = ({
  keyword, gridView, setGridView,
  handleSearchOrganizations, toggleCreateModal,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [options, setOptions] = useState();
  const [searchInput, setSearchInput] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(value => {
    if (isBlank(value)) {
      setOptions();
      return;
    }

    dispatch(suggestionOrganizations({
      params: {
        keyword: value,
      },
    })).unwrap()
      .then(data => {
        setOptions(data?.organizations);
      });
  }, 300), []);

  const suggestions = useMemo(() => (options || []).map(option => (
    <Option
      key={option.id}
      value={option.name}
      name={option.name}
    >
      <div className='search-results-item'>
        <img className='item-image' src={getImageSrc(option.image)} alt={option.name} />
        <span className='item-name'>{option.name}</span>
      </div>
    </Option>
  )), [options]);

  const onKeyPress = useCallback(e => {
    const { value } = e.target;

    if (e.which === 13) {
      handleSearchOrganizations(value);

      if (isBlank(value)) {
        setOptions();
      }
    }
  }, [handleSearchOrganizations]);

  const onSelect = useCallback((value, option) => {
    handleSearchOrganizations(option.name);
  }, [handleSearchOrganizations]);

  useEffect(() => {
    setSearchInput(keyword);
  }, [keyword]);

  return (
    <div className='header-page'>
      <div className='content-header'>
        <div className='search-group'>
          <SearchSvg className='icon-search' />
          <AutoComplete
            className='input-search'
            placeholder={t('organizations.placeholder_search')}
            onSearch={onSearch}
            onKeyDown={onKeyPress}
            onSelect={onSelect}
            popupClassName={searchResults}
            allowClear
            value={searchInput}
            onChange={val => setSearchInput(val)}
          >
            {suggestions}
          </AutoComplete>
        </div>
        <div className='content-header-right'>
          <UserMenu size={48} page='organizations' />
        </div>
      </div>

      <div className='organization-header'>
        <div className='title'>{keyword ? t('organizations.search_result') : t('organizations.title')}</div>
        <div className='header-right'>
          <div className='change-view-group'>
            <div
              onClick={() => setGridView(true)}
              className={`${gridView ? 'active' : ''} change-btn`}
            >
              <GridSvg />
            </div>
            <div
              onClick={() => setGridView(false)}
              className={`${gridView ? '' : 'active'} change-btn`}
            >
              <ListSvg />
            </div>
          </div>
          <div className='add-btn' onClick={() => toggleCreateModal()}>
            <PlusSvg width={12} height={12} />
            <div className='text'>{t('organizations.create_new')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
