import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import Navbar from './Navbar';
import TopHeader from './TopHeader';
import UsageFlow from './UsageFlow';
import ContactForm from './ContactForm';
import Footer from './Footer';
import { styles } from './styles';

const { Content } = Layout;

const Home = () => {
  const screenRef = useRef(null);

  useEffect(() => {
    if (window.location.hash) {
      const element = screenRef.current?.querySelector(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Layout className={styles.index} ref={screenRef}>
      <Navbar />
      <Content>
        <TopHeader />
        <UsageFlow />
        <ContactForm />
      </Content>
      <Footer />
    </Layout>
  );
};

export default Home;
