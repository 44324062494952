import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from 'icons/CloseSvg.svg';
import { backgrounds, virtualBackgroundOptions } from './config';
import { activeBackground, perform } from './utils';
import { sideToolbar, backgroundPane } from './styles';

const { videoWidth, videoHeight } = virtualBackgroundOptions;
const segmentationStream = {
  isEnabled: false,
  isBlur: false,
  pixelCount: videoWidth * videoHeight,
  backgroundImage: null,
};

const segmentationMask = new ImageData(videoWidth, videoHeight);
const segmentationMaskCanvas = document.createElement('canvas');
const segmentationMaskCtx = segmentationMaskCanvas.getContext('2d');
segmentationMaskCanvas.width = videoWidth;
segmentationMaskCanvas.height = videoHeight;
const canvasOutputCtx = null;

const BackgroundPane = ({
  isDisplay, bodyPixNet, videoStream, onClose, room, videoLocalLoaded, setLocalTracks,
}) => {
  const [t] = useTranslation();
  const [backgroundSelected, setBackgroundSelected] = useState('none');
  const [imageSelected, setImageSelected] = useState('none');

  const getCaptureStream = () => {
    const canvasOutput = document.getElementById('canvasOutput');
    return canvasOutput.captureStream();
  };

  const playVideoOutput = useCallback(stream => {
    const localVideo = document.getElementById('localVideo');
    localVideo.srcObject = stream;
  }, []);

  const changeBackground = useCallback(async (index, image) => {
    setBackgroundSelected(index);
    setImageSelected(image);
  }, []);

  const applyBackground = useCallback(async () => {
    if (!videoStream?.stream || !videoLocalLoaded) {
      return;
    }

    setTimeout(() => {
      segmentationStream.isEnabled = true;
      segmentationStream.backgroundImage = imageSelected;
      segmentationStream.edgeBlur = 0.75;
      segmentationStream.isBlur = false;

      switch (backgroundSelected) {
        case 'none':
          segmentationStream.isEnabled = false;
          segmentationStream.edgeBlur = 0;
          segmentationStream.backgroundImage = null;
          break;
        case 'blur':
          segmentationStream.isBlur = true;
          break;
      }

      perform({
        bodyPixNet,
        canvasOutputCtx,
        videoWidth,
        videoHeight,
        segmentationMaskCtx,
        segmentationMaskCanvas,
        segmentationMask,
        segmentationStream,
      });
      playVideoOutput(getCaptureStream());
      const videoTrack = room.getLocalTracks().find(track => track.getType() === 'video');
      const stream = getCaptureStream();
      const virtualTrack = stream.getVideoTracks()[0];
      virtualTrack.hasBackground = true;
      virtualTrack._sourceName = 'camera';
      virtualTrack.getVideoType = () => 'camera';
      virtualTrack.getSourceName = videoTrack.getSourceName;
      virtualTrack.setSourceName = name => {
        virtualTrack._sourceName = name;
      };
      virtualTrack.getType = () => 'video';
      virtualTrack.isAudioTrack = () => videoTrack.isAudioTrack();
      virtualTrack.isVideoTrack = () => videoTrack.isVideoTrack();
      virtualTrack.isMuted = () => videoTrack.isMuted();
      virtualTrack.containers = videoTrack.containers;
      virtualTrack.conference = videoTrack.conference;
      virtualTrack.setConference = e => {
        virtualTrack.conference = e;
      };
      virtualTrack.getConference = () => videoTrack.conference;
      virtualTrack._maybeFireTrackAttached = () => {};
      virtualTrack.isLocal = videoTrack.isLocal;
      virtualTrack.participantId = videoTrack.getParticipantId();
      virtualTrack.getParticipantId = () => virtualTrack.participantId;
      virtualTrack.dispose = videoTrack.dispose;
      virtualTrack.stream = stream;
      virtualTrack._sendMuteStatus = videoTrack._sendMuteStatus;
      virtualTrack.stopStream = () => {};
      virtualTrack.detach = () => {};
      virtualTrack._onDeviceListWillChange = () => {};
      virtualTrack.removeAllListeners = () => {};
      virtualTrack.getTrack = () => virtualTrack;

      room.replaceTrack(videoTrack, virtualTrack);

      setLocalTracks(prevTracks => ({ ...prevTracks, video: virtualTrack }));
    }, 200);
  }, [videoStream, videoLocalLoaded, imageSelected, backgroundSelected, bodyPixNet, playVideoOutput, room, setLocalTracks]);

  const loadVirtualVideoStream = useCallback(() => {
    const virtualVideoEl = document.getElementById('virtualVideo');
    if (!videoStream?.stream) {
      setTimeout(() => virtualVideoEl.srcObject?.getTracks()[0].stop(), 200);
    } else if (!videoStream?.hasBackground) {
      videoStream.attach(virtualVideoEl);
    }
  }, [videoStream]);

  useEffect(() => {
    loadVirtualVideoStream();
  }, [videoStream, loadVirtualVideoStream]);

  useEffect(() => {
    applyBackground();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundSelected, imageSelected, videoLocalLoaded]);

  return (
    <div className={`${backgroundPane} ${sideToolbar} ${!isDisplay && 'hidden'}`}>
      <div className='header'>
        <div className='title'>
          {t('meetings.show.background_filter')}
        </div>
        <CloseSvg className='cursor-pointer' onClick={onClose} />
      </div>
      <div className='background-list'>
        <div className='background-wrapper' onClick={() => changeBackground('none', null)}>
          <div className={`${activeBackground(backgroundSelected, 'none')} none-background background-image`}>{t('meetings.show.none')}</div>
        </div>
        <div className='background-wrapper' onClick={() => changeBackground('blur', null)}>
          <div className={`${activeBackground(backgroundSelected, 'blur')} no-background background-image`}>{t('meetings.show.blur')}</div>
        </div>
        {backgrounds.map((background, index) => (
          <div className='background-wrapper' onClick={e => changeBackground(index, e.target)} key={index}>
            <img
              className={`${activeBackground(backgroundSelected, index)} background-image`}
              crossOrigin='anonymous'
              src={background}
              alt={`background-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BackgroundPane;
