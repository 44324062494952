import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'antd';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import classnames from 'classnames';
import LoadingAllScreen from 'components/LoadingAllScreen';
import {
  fetchOrganizations,
  createOrganization,
  updateOrganization,
} from 'redux/slices/organizations';
import { scrollToTop } from 'utils';
import GridView from './GridView';
import ListView from './ListView';
import ModalAddOrganization from './ModalAddOrganization';
import OrganizationDetails from './OrganizationDetails';
import Header from './Header';
import { styles } from './styles';

const Organizations = ({ currentUser }) => {
  const [t] = useTranslation();
  const {
    organizations, fetching, submitting,
    pagination: { currentPage, perPage, totalObjects },
  } = useSelector(state => state.organizations);
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editOrganization, setEditOrganization] = useState(null);
  const [gridView, setGridView] = useState(true);
  const [isShowDetails, setShowDetails] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const fetchData = useCallback(args => {
    setShowDetails(false);
    dispatch(fetchOrganizations({
      params: {
        page: currentPage,
        per_page: perPage,
        keyword,
        ...args,
      },
    }));
  }, [dispatch, keyword, currentPage, perPage]);

  const handleSaveOrganization = useCallback((formValues, id, callback) => {
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    formData.append('user[email]', formValues.user.email);
    formData.append('user[password]', formValues.user.password);
    formData.append('user[name]', formValues.user.name);
    formData.append('user[username]', formValues.user.username);
    ['lineChannelId', 'lineChannelSecret', 'lineChannelToken'].forEach(key => {
      if (!editOrganization || editOrganization[key] !== formValues[key]) {
        formData.append(snakeCase(key), formValues[key]);
      }
    });

    if (formValues.image) { formData.append('image', formValues.image[0]); }
    if (id) { formData.append('id', id); }

    if (id) {
      dispatch(updateOrganization(formData)).unwrap().then(result => {
        if (result?.success) {
          fetchData();
          callback && callback();
        }
      });
    } else {
      dispatch(createOrganization(formData)).unwrap().then(result => {
        if (result?.success) {
          if (keyword !== '') {
            setKeyword('');
          }
          fetchData({ page: 1, keyword: '' });
          callback && callback();
        }
      });
    }
  }, [dispatch, fetchData, keyword, editOrganization]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = (organization = null) => {
    setIsModalOpen(true);
    setEditOrganization(organization);
  };

  const handleSelectedOrganization = useCallback(id => {
    setSelectedOrganization(id);
    setShowDetails(true);
  }, []);

  const handleSearchOrganizations = useCallback(searchInput => {
    setKeyword(searchInput);
    fetchData({ page: 1, keyword: searchInput });
  }, [fetchData]);

  const handleChangePage = useCallback((page, pageSize) => {
    fetchData({ page, per_page: pageSize });
    scrollToTop();
  }, [fetchData]);

  return (
    <div className={styles.index}>
      <div className='organization-page'>
        <Header
          currentUser={currentUser}
          keyword={keyword}
          gridView={gridView}
          setGridView={setGridView}
          toggleCreateModal={showModal}
          handleSearchOrganizations={handleSearchOrganizations}
        />
        {fetching && <LoadingAllScreen />}
        <div className='organization-container'>
          <div className={classnames(isShowDetails ? 'show-details' : '', 'main-content')}>
            <div className='total-organizations'>
              {t('organizations.total_organizations', { total: totalObjects })}
            </div>
            {!isEmpty(organizations) && (
              <>
                {gridView ? (
                  <GridView
                    organizations={organizations}
                    handleSelectedOrganization={handleSelectedOrganization}
                  />
                ) : (
                  <ListView
                    organizations={organizations}
                    handleSelectedOrganization={handleSelectedOrganization}
                  />
                )}
                <Pagination
                  current={currentPage}
                  total={totalObjects}
                  onChange={handleChangePage}
                  onShowSizeChange={handleChangePage}
                  size='small'
                  pageSize={perPage}
                  showSizeChanger={totalObjects > 20}
                />
              </>
            )}
          </div>
          {isShowDetails && (
            <OrganizationDetails
              organizationId={selectedOrganization}
              showModal={showModal}
              handleSearchOrganizations={handleSearchOrganizations}
            />
          )}
        </div>
        {isModalOpen && (
          <ModalAddOrganization
            editOrganization={editOrganization}
            submitting={submitting}
            handleCancel={() => setIsModalOpen(false)}
            handleSaveOrganization={handleSaveOrganization}
          />
        )}
      </div>
    </div>
  );
};

export default Organizations;
