import React, {
  useCallback, useEffect,
} from 'react';
// import ReactDOM from 'react-dom'
import { Controller, get as getError, useFormContext } from 'react-hook-form';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { isPresent } from 'utils';
import classNames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { toMoment, formatDate } from 'utils/date';

const { RangePicker } = DatePicker;

const avoidDot = string => string.replace(/\./m, '-');

export const RangeForm = ({
  label, rules = {}, name, defaultValue, minDate, maxDate,
  children, labelclass, picker,
  suffixIcon = <FontAwesomeIcon icon={faCalendar} alt='icon' className='mr-1 my-auto font-size' />,
  inputClassName,
  errorClassName = 'mt-2 mb-2 text-sm text-red-600',
  className, required, isDisabled, hidden, showCalendarIcon, ...rest
}) => {
  const [t] = useTranslation();
  const {
    control, formState: { errors }, setValue, getValues,
  } = useFormContext();
  const error = getError(errors, name) || rest.errorMessage;

  const elementId = `custom-date-${avoidDot(name)}`;

  const disabledDate = useCallback(date => {
    if (!moment.isMoment(date)) {
      return true;
    }
    if (maxDate && date.isAfter(maxDate)) {
      return true;
    }

    if (minDate && date.isBefore(minDate)) {
      return true;
    }

    return false;
  }, [minDate, maxDate]);

  useEffect(() => {
    const { allowEmpty = [] } = rest;
    const handleTypingChangeDate = (event, index) => {
      const currentValue = getValues(name) || [null, null];
      const date = moment(event.target.value);

      if (date.isValid()) {
        currentValue[index] = date;
      }

      if (!event.target.value || event.target.value === '') {
        currentValue[index] = null;
      }

      if (allowEmpty[index] !== false || event.target.value) {
        setValue(name, currentValue);
        rest.onChange && rest.onChange(currentValue);
      }
    };

    const element = document.getElementById(elementId);
    if (element) {
      const inputs = element.querySelectorAll('.ant-picker-input input');
      inputs[0].addEventListener('change', event => handleTypingChangeDate(event, 0));
      inputs[0].maxLength = 10;
      inputs[1].addEventListener('change', event => handleTypingChangeDate(event, 1));
      inputs[1].maxLength = 10;
    }

    return () => {
      if (element) {
        const inputs = element.querySelectorAll('.ant-picker-input input');
        inputs[0].removeEventListener('change', event => handleTypingChangeDate(event, 0));
        inputs[1].removeEventListener('change', event => handleTypingChangeDate(event, 1));
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hidden) { return null; }

  const originValue = rest.originValue === undefined ? defaultValue : rest.originValue;

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      control={control}
      render={
        ({ field: { onChange, value } }) => {
          rest.watchValue && rest.watchValue(value);
          return (
            <div
              id={elementId}
              className={classNames(className, { invalid: isPresent(error), changed: formatDate(originValue) !== formatDate(value), disabled: isDisabled })}
            >
              {label && (
                <label className={labelclass || 'color-dark font-size-12'}>
                  {label}
                  {(rules.required || required) && (<span className='text-required font-8 ml-1'>{t('form.required_text')}</span>)}
                </label>
              )}
              <div className='position-relative'>
                <RangePicker
                  data-testid={name}
                  ref={rest.refInput || rest.innerRef}
                  name={name}
                  disabledDate={disabledDate}
                  picker={picker}
                  format='YYYY/MM/DD'
                  value={toMoment(value)}
                  disabled={isDisabled}
                  className={inputClassName || 'form-control form-control-sm font-12 border-light shadow-sm border-light'}
                  suffixIcon={showCalendarIcon && suffixIcon}
                  allowClear={!showCalendarIcon}
                  {...rest}
                  onChange={e => {
                    // e is an array
                    onChange(e);
                    rest.onChange && rest.onChange(e);
                  }}
                />
                {children}
              </div>
              {error?.message && (
                <div className={errorClassName}>{error.message}</div>
              )}
            </div>
          );
        }
      }
    />
  );
};
