import React from 'react';
import CustomerProfile from './CustomerProfile';
import EmployeeProfile from './EmployeeProfile';

const Profile = ({ currentUser }) => {
  if (currentUser?.role === 'customer') {
    return <CustomerProfile currentUser={currentUser} />;
  }
  return <EmployeeProfile currentUser={currentUser} />;
};

export default Profile;
