import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { previewFiles } from './styles';

const PreviewFiles = ({ attachedFiles, removeSelectedFile }) => {
  const preview = file => {
    if (file.type.includes('image')) {
      return <img className='img-wrp' src={URL.createObjectURL(file)} alt='...' />;
    } if (file.type.includes('video')) {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video className='video-wrp' controls>
          <source src={URL.createObjectURL(file)} />
        </video>
      );
    }
    return (
      <div className='file-wrp'>
        <span className='file-name'>{file.name}</span>
        <FontAwesomeIcon className='file-icon' icon={faFileExcel} />
      </div>
    );
  };

  return (
    <div className={previewFiles}>
      <div className='preview'>
        {attachedFiles.map((file, index) => (
          <div className='file-content' key={`file-${index}`}>
            {preview(file)}
          </div>
        ))}
      </div>
      <Button
        shape='circle'
        className='remove-file'
        icon={(<FontAwesomeIcon icon={faTrash} />)}
        onClick={removeSelectedFile}
      />
    </div>
  );
};

export default PreviewFiles;
