import { css } from '@emotion/css';
import { COLORS, truncateMultiline } from 'utils/constants';

export const styles = {
  index: css`
    .organization-page {
      width: 100%;
      padding-top: 20px;

      .content-header {
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;

        .search-group {
          width: 860px;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding: 0 20px;
          position: relative;

          .input-search {
            width: 100%;
            max-width: 100%;

            &.ant-select-focused {
              .ant-select-selector {
                background-color: ${COLORS.white};
                box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
                border-radius: 28px;
              }
            }

            .ant-select-selector {
              height: 56px;
              border: none;
              background-color: ${COLORS.athensGray};
              border-radius: 100px;

              .ant-select-selection-search-input,
              .ant-select-selection-placeholder {
                height: 100%;
                padding-left: 42px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
              }

              .ant-select-selection-placeholder {
                display: flex;
                align-items: center;
                color: ${COLORS.silverChalice};
              }
            }

            .ant-select-clear {
              background: none;
              top: 50%;

              svg {
                width: 12px;
                height: 12px;
              }
            }
          }

          .icon-search {
            position: absolute;
            width: 18px;
            height: 18px;
            top: 19px;
            right: calc(100% - 58px);
            z-index: 2;
          }
        }

        .content-header-right {
          display: flex;
          align-items: center;
          column-gap: 22px;
        }
      }

      .organization-header {
        width: 100%;
        display: flex;
        padding: 40px 16px 10px 16px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid ${COLORS.mercury};

        .title {
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
        }

        .header-right {
          display: flex;
          align-items: center;

          .add-btn {
            display: flex;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.1px;
            margin-left: 20px;
            color: ${COLORS.dodgerBlue};
            cursor: pointer;
            align-items: center;

            path {
              stroke: ${COLORS.dodgerBlue};
            }

            .text {
              margin-left: 10px;
            }
          }

          .change-view-group {
            width: 76px;
            height: 40px;
            background-color: ${COLORS.athensGray};
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            .change-btn {
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              cursor: pointer;
            }

            .change-btn.active {
              background-color: ${COLORS.white};
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }

      .organization-container {
        display: flex;
        min-height: calc(100vh - 175px);

        .main-content {
          width: 100%;
          padding: 17px 0px 40px 0px;

          &.show-details {
            width: calc(100% - 370px);
          }

          .total-organizations {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: ${COLORS.athensGray2};
            margin-bottom: 16px;
            margin-left: 16px;
          }
        }

        .organization-details {
          width: 370px;
          border-left: 1px solid ${COLORS.mercury};
          position: relative;

          .organization-info {
            position: sticky;
            top: 0;
            padding: 16px;

            .content {
              display: flex;
              justify-content: space-between;
              margin-bottom: 26px;

              .organization-image {
                width: 48px;
                height: 48px;
                object-fit: cover;
                border-radius: 12px;
              }

              .group-name {
                margin-left: 16px;
                padding-right: 10px;

                .name {
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 28px;
                  color: ${COLORS.mineShaft};
                }

                .view-as {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.1px;
                  color: ${COLORS.dodgerBlue};
                  cursor: pointer;
                }
              }
            }

            .info {
              margin-bottom: 16px;

              label {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: ${COLORS.mineShaft};
                margin-bottom: 2px;
              }

              .description {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                word-break: break-all;
                color: ${COLORS.silverChalice};

                &.about {
                  white-space: pre-wrap;
                }
              }

              .url {
                line-break: anywhere;

                span {
                  color: ${COLORS.dodgerBlue};
                }
              }
            }

            .dots-icon {
              width: 24px;
              cursor: pointer;
            }
          }
        }
      }

      .list-organizations {
        margin-bottom: 30px;

        &.grid-view {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          padding-left: 16px;
          padding-right: 16px;

          .organization-card {
            width: 412px;
            height: 300px;
            border-radius: 12px;
            background-color: ${COLORS.white};
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
            cursor: pointer;

            &:hover {
              box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
            }

            .image-wrapper {
              width: 100%;
              height: 244px;

              .org-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
              }
            }

            .name-membrane {
              width: 100%;
              height: calc(100% - 244px);
              display: flex;
              align-items: center;
              padding: 16px;

              .name {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: ${COLORS.black};
                ${truncateMultiline(2)}
              }
            }
          }
        }

        &.list-view {
          display: inline;

          .ant-table-content {
            .org-image {
              width: 40px;
              height: 40px;
              border-radius: 8px;
              object-fit: cover;
              margin-right: 24px;
            }

            th, td {
              border-bottom: none;
              font-weight: 500;
              word-break: break-all;
              vertical-align: top;
            }

            th.ant-table-cell {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.1px;
              background: none;
              color: ${COLORS.silverChalice};
            }

            td.ant-table-cell {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: ${COLORS.mineShaft};
            }
          }
        }
      }
    }
  `,
};

export const addOrganizationModal = css`
  .new-form {
    .image-content {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
    }

    .form-content {
      width: 100%;

      .input-form {
        margin-bottom: 16px;
      }

      .textarea-field {
        margin-bottom: 30px;
      }
    }
  }
`;

export const removeOrganizationModal = css`
  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 24px 16px;

      .content, .confirm-name-input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${COLORS.black};
      }

      .confirm-name-input {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        margin-top: 24px;
      }
    }
  }
`;

export const searchResults = css`
  box-shadow: 0px 5px 12px rgba(0, 67, 101, 0.08);
  border-radius: 0 0 28px 28px;

  .search-results-item {
    display: flex;
    align-items: center;
    padding: 8px 2px;

    .item-image {
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }

    .item-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-left: 12px;
    }
  }
`;
