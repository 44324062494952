import React, { useState, useCallback, useMemo } from 'react';
import { Button, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrganization } from 'redux/slices/organizations';
import { commonStyles } from 'components/styles';
import { removeOrganizationModal } from './styles';

const RemoveOrganizationModal = ({
  organization: { id, name },
  handleRemoveDone,
  closeModal,
}) => {
  const [t] = useTranslation();
  const { submitting } = useSelector(state => state.organizations);
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [confirmName, setConfirmName] = useState(false);

  const handleRemoveOrganization = useCallback(() => {
    if (step === 1) {
      setStep(2);
      return;
    }

    dispatch(deleteOrganization({ id }))
      .unwrap()
      .then(result => {
        if (result?.success) {
          setStep(3);
        } else {
          toast.error(result?.message);
        }
      });
  }, [dispatch, id, step]);

  const actions = useMemo(() => {
    if (step === 3) {
      return (
        <Button key='done' onClick={handleRemoveDone} className='cancel-btn'>
          {t('common.actions.done')}
        </Button>
      );
    }
    return (
      <>
        <Button key='cancel' onClick={closeModal} className='cancel-btn'>
          {t('common.actions.cancel')}
        </Button>
        <Button
          key='submit'
          loading={submitting}
          onClick={handleRemoveOrganization}
          className='remove-btn'
          disabled={step === 2 && (confirmName !== name)}
        >
          {t('common.actions.remove')}
        </Button>
      </>
    );
  }, [confirmName, closeModal, handleRemoveDone, handleRemoveOrganization, name, step, submitting, t]);

  const content = useMemo(() => {
    switch (step) {
      case 1: {
        return <div>{t('organizations.modals.remove_organization.content_step1')}</div>;
      }
      case 2:
        return (
          <>
            <div dangerouslySetInnerHTML={{
              __html: t('organizations.modals.remove_organization.content_step2', { name }),
            }}
            />
            <Input
              className='confirm-name-input'
              placeholder={t('organizations.modals.remove_organization.placeholder')}
              onChange={e => setConfirmName(e.target.value)}
            />
          </>
        );
      case 3: {
        return <div>{t('organizations.modals.remove_organization.content_step3')}</div>;
      }
    }
  }, [name, step, t]);

  return (
    <Modal
      open
      centered
      title={step === 3 ? '' : t('organizations.modals.remove_organization.title')}
      onCancel={closeModal}
      className={`${commonStyles.modal} ${removeOrganizationModal}`}
      footer={actions}
    >
      <div className='content'>{content}</div>
    </Modal>
  );
};

export default RemoveOrganizationModal;
