import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import {
  getProfile, getLineInfo, loginAsCustomer,
} from 'redux/slices/auth';
import LoadingAllScreen from 'components/LoadingAllScreen';
import { getImageSrc } from 'utils/fileHelper';
import { defaultRootPath } from 'utils';
import NotFound from 'components/NotFound';
import { styles } from './styles';

const CustomerLogin = () => {
  const { t } = useTranslation();
  const {
    error_message: errorMessage, authorization, key,
    account_not_found: isRegister,
  } = queryString.parse(location.search);
  const {
    isLoggedIn, currentUser, lineInfo, fetched,
  } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [checkedAgreeTerm, setCheckedAgreeTerm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && !currentUser) {
      if (key) {
        navigate(`/organizations/${key}/chat`);
      } else {
        dispatch(getProfile()).unwrap().then(data => {
          const { user } = data;
          navigate(defaultRootPath(user));
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoggedIn]);

  useEffect(() => {
    dispatch(getLineInfo({ key }));
  }, [dispatch, key]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn && authorization) {
    dispatch(loginAsCustomer({ authorization, customerLoginUrl: `${window.location.pathname}?key=${key}` }));
    toast.success(t('login.success'));
  }

  const handleConnectLine = useCallback(() => {
    window.location.href = lineInfo?.loginByLineUrl;
  }, [lineInfo]);

  const handleChangeAgreeTerm = useCallback(e => {
    setCheckedAgreeTerm(e.target.checked);
  }, []);

  const registerByLineInfo = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_API_URL}/line/register_with_line${location.search}`;
  }, []);

  if (!fetched) {
    return <LoadingAllScreen />;
  }

  return (
    <div className={styles.customerLogin}>
      {lineInfo ? (
        <div className='section-body flex items-center justify-center'>
          <div className='login-form w-full'>
            {lineInfo.organization && (
            <div className='image-wrapper'>
              <img
                className='org-image m-auto'
                src={getImageSrc(lineInfo.organization.image)}
                width='100%'
                height='auto'
                alt={lineInfo.organization.name}
              />
            </div>
            )}
            <div className='buttons flex flex-col items-center'>
              <div className='description'>
                {t('customer_login.description')}
              </div>
              {isRegister ? (
                <>
                  <div className='title'>
                    {t('customer_login.register_title')}
                  </div>
                  <button className='line-btn' disabled={!checkedAgreeTerm} onClick={registerByLineInfo}>
                    <span>{t('customer_login.register')}</span>
                  </button>
                  <Checkbox className='agree-term-checkbox' checked={checkedAgreeTerm} onChange={handleChangeAgreeTerm}>
                    {t('customer_login.agree_term_desc')}
                  </Checkbox>
                </>
              ) : (
                <>
                  <div className='title mt-6'>
                    {t('customer_login.login_title')}
                  </div>
                  <button className='line-btn' onClick={() => handleConnectLine()}>
                    <span>{t('customer_login.login')}</span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default CustomerLogin;
