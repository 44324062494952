import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className='w-screen h-screen bg-gray-200 flex justify-center'>
    <div className='w-96 h-fit p-5 mt-5 bg-white rounded shadow-lg'>
      <h4 className='font-bold'>
        <FontAwesomeIcon icon={faExclamationTriangle} className='text-red-600 mr-1' />
        <span>ページが見つかりませんでした 🧐🧐</span>
      </h4>
      <div className='mt-3 text-xs font-medium leading-5'>
        <p className='mb-0'>アクセスしたページが見つかりませんでした。</p>
        <Link to='/'>ホームの画面</Link>
      </div>
    </div>
  </div>
);

export default NotFound;
