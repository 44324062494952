import api from 'redux/api-common';
import { camelCaseObject } from 'utils';
import qs from 'qs';

const fetchOrganizations = ({ params }) => api
  .get(`/organizations?${qs.stringify(params)}`)
  .then(response => camelCaseObject(response.data));

const fetchOrganization = id => api
  .get(`/organizations/${id}`)
  .then(response => camelCaseObject(response.data));

const createOrganization = data => api
  .post('organizations', data)
  .then(response => camelCaseObject(response.data));

const updateOrganization = (data, id) => api
  .put(`/organizations/${id}`, data)
  .then(response => camelCaseObject(response.data));

const removeOrganization = id => api
  .delete(`/organizations/${id}`)
  .then(response => camelCaseObject(response.data));

export default {
  fetchOrganizations,
  fetchOrganization,
  createOrganization,
  updateOrganization,
  removeOrganization,
};
