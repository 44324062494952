import Emittery from 'emittery';

const emitter = new Emittery();

const Emitter = {
  on: (event, fn) => emitter.on(event, fn),
  once: (event, fn) => emitter.once(event, fn),
  off: (event, fn) => emitter.off(event, fn),
  emit: (event, payload) => emitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
