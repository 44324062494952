import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from 'antd';
import { isBlank } from 'utils';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { ReactComponent as SearchSvg } from 'icons/Search.svg';
import { ReactComponent as GridSvg } from 'icons/Grid.svg';
import { ReactComponent as ListSvg } from 'icons/List.svg';
import { ReactComponent as PlusSvg } from 'icons/Plus.svg';
import { suggestionUsers } from 'redux/slices/users';
import UserAvatar from 'components/UserAvatar';
import { header, searchResults } from './styles';

const Header = ({
  keyword,
  toggleCreateModal,
  handleSearchUsers,
  gridView, setGridView,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [options, setOptions] = useState();
  const [searchInput, setSearchInput] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(value => {
    if (isBlank(value)) {
      setOptions();
      return;
    }

    dispatch(suggestionUsers({
      params: {
        keyword: value,
      },
    })).unwrap()
      .then(data => {
        setOptions(data?.users);
      });
  }, 300), []);

  const onKeyPress = useCallback(e => {
    const { value } = e.target;

    if (e.which === 13) {
      handleSearchUsers(value);

      if (isBlank(value)) {
        setOptions();
      }
    }
  }, [handleSearchUsers]);

  const onSelect = useCallback((value, option) => {
    handleSearchUsers(option.name);
  }, [handleSearchUsers]);

  useEffect(() => {
    if (isBlank(keyword)) {
      setOptions();
    }
    setSearchInput(keyword);
  }, [keyword]);

  const suggestions = useMemo(() => (options || []).map(option => (
    <AutoComplete.Option
      key={option.id}
      value={option.name}
      name={option.name}
    >
      <div className='search-results-item'>
        <UserAvatar size={32} url={option?.image} alt={option.name} />
        <span className='item-name'>{option.name}</span>
      </div>
    </AutoComplete.Option>
  )), [options]);

  return (
    <div className={header}>
      <div className='search-group'>
        <SearchSvg className='icon-search' />
        <AutoComplete
          className='input-search'
          placeholder={t('common.placeholder_search')}
          onSearch={onSearch}
          onKeyDown={onKeyPress}
          onSelect={onSelect}
          popupClassName={searchResults}
          allowClear
          value={searchInput}
          onChange={val => setSearchInput(val)}
        >
          {suggestions}
        </AutoComplete>
      </div>
      <div className='header-right'>
        <div className='change-view-group'>
          <div
            onClick={() => setGridView(true)}
            className={`${gridView ? 'active' : ''} change-btn`}
          >
            <GridSvg />
          </div>
          <div
            onClick={() => setGridView(false)}
            className={`${gridView ? '' : 'active'} change-btn`}
          >
            <ListSvg />
          </div>
        </div>
        <div className='add-btn' onClick={() => toggleCreateModal()}>
          <PlusSvg width={12} height={12} />
          <div className='text'>{t('users.create_new')}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
