import { css } from '@emotion/css';
import { COLORS } from 'utils/constants';
import TopHeaderImage from 'images/landing-page/top.jpg';
import TopHeaderSPImage from 'images/landing-page/top-sp.jpg';

export const styles = {
  index: css`
    min-height: 100svh;
    background-color: ${COLORS.white};
    font-family: Noto Sans JP;

    .ant-layout-header {
      position: sticky;
      top: 0;
      z-index: 3;
      width: 100%;
      background: ${COLORS.dodgerBlue2};
      height: 68px;

      .menu-btn {
        width: 34px;
      }
      .menu-btn, .menu-sp {
        display: none;
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        line-height: 43px;
        text-align: left;

        path {
          stroke: ${COLORS.white};
        }
      }

      .menu-sp {
        width: 100%;
        .menu-items {
          position: absolute;
          width: 100%;
          top: 60px;
          left: 0;
          display: none;

          &.collapsed {
            display: block;
          }

          .item {
            background-color: ${COLORS.wildSand2};
            height: 59.5px;
            border-bottom: 1px solid ${COLORS.tundora}30;
            padding: 0 32px;
            cursor: pointer;
            color: ${COLORS.tundora};
            display: flex;
            align-items: center;

            &.active {
              color: ${COLORS.denim};
            }
          }

          .close-menu-btn {
            display: flex;
            background-color: ${COLORS.dodgerBlue2};
            height: 59.5px;
            padding: 0 32px;
            cursor: pointer;
            color: ${COLORS.white};
            align-items: center;
            gap: 10px;
            justify-content: center;
            cursor: pointer;
          }
        }
      }

      .header {
        display: flex;
        align-items: center;
        height: 100%;

        .logo {
          margin-right: auto;
          min-width: 213px;
        }
  
        .header-buttons {
          display: flex;
          gap: 25px;
          align-items: center;
          position: relative;
  
          .login-btn, .register-btn {
            display: flex;
            flex-direction: column;
            text-align: center;
            cursor: pointer;
  
            svg {
              margin: auto;
            }
  
            .text {
              font-size: 12px;
              font-weight: 900;
              line-height: 14px;
              letter-spacing: 0.04em;
              text-align: center;
              color: ${COLORS.white};
              margin-top: 10px;
            }
          }
  
          .contact-btn {
            color: ${COLORS.white};
            width: 124px;
            text-align: center;
            background-color: ${COLORS.denim};
            height: 34px;
            font-size: 12px;
            font-weight: 900;
            line-height: 14px;
            letter-spacing: 0.04em;
            padding: 10px;
            border-radius: 5px;
            margin-left: 30px;
            cursor: pointer;
          }
        }
      }
    }

    .ant-layout-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .background-text {
        font-size: 100px;
        font-weight: 800;
        line-height: 150px;
        letter-spacing: 0em;
        text-align: left;
        color: ${COLORS.white}42;
        pointer-events: none;
        position: absolute;
        font-family: Hiragino Kaku Gothic Std;
      }

      .top-header {
        position: relative;
        height: 340px;
        background: linear-gradient(90deg, #FFFFFF 24.63%, rgba(255, 255, 255, 0) 52.07%), url('${TopHeaderImage}');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .header-text {
          position: absolute;
          top: 0;
          padding: 37px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 46px;
          font-weight: 300;
          letter-spacing: 0em;
          text-align: left;
          color: ${COLORS.denim};

          .group {
            .text-1 {
              font-size: 60px;
              font-weight: 300;
            }
          }
        }
      }

      .usage-flow {
        background: ${COLORS.dodgerBlue2};
        min-height: 400px;
        padding: 40px 100px;

        .title {
          font-size: 32px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: 0em;
          text-align: center;
          color: ${COLORS.white};
          margin-top: 76px;
          z-index: 2;
          position: relative;
        }

        .steps {
          display: flex;
          gap: 55px;
          margin: 0 auto;
          width: fit-content;
          margin-top: 80px;
          flex-flow: wrap;

          .step-box {
            background-color: ${COLORS.wildSand2};
            width: 351px;
            height: 438px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 25px;
            position: relative;
            flex: auto;

            .step-circle {
              position: absolute;
              top: -31px;
              left: -31px;
              width: 63px;
              height: 63px;
              border-radius: 50%;
              background-color: ${COLORS.white};
              display: flex;
              align-items: center;

              .step-circle-number {
                text-align: center;
                width: 100%;
                font-size: 32px;
                font-weight: 700;
                line-height: 58px;
                letter-spacing: 0em;
                text-align: center;
                color: ${COLORS.dustyGray2};
              }
            }

            .step-image {
              min-height: 201px;
            }

            .step-title {
              font-size: 24px;
              font-weight: 700;
              line-height: 38px;
              letter-spacing: 0em;
              text-align: left;
              color: ${COLORS.denim};
              min-height: 76px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            .step-description {
              font-size: 16px;
              font-weight: 400;
              line-height: 29px;
              letter-spacing: 0em;
              text-align: left;
              color: ${COLORS.black};
            }
          }
        }

        .note {
          margin-top: 25px;
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;
          color: ${COLORS.white};
          font-family: Poppins;

          span {
            font-size: 33px;
            font-weight: 900;
            line-height: 56px;
            letter-spacing: 0em;
            text-align: left;
            color: ${COLORS.creamCan};
          }
        }

        .register-btn-ref {
          margin: auto;
          margin-top: 40px;
          padding: 25px;
          border-radius: 21px;
          background-color: ${COLORS.coral};
          max-width: 480px;
          font-size: 28px;
          font-weight: 600;
          letter-spacing: 0px;
          text-align: center;
          color: ${COLORS.white};
          cursor: pointer;
          box-shadow: 0px 4px 4px 0px #00000040;
          display: block;

          .text-small {
            font-size: 20px;
          }

          &:hover {
            background-color: ${COLORS.crusta};
          }
        }
      }

      .contact-form {
        background-color: ${COLORS.selago};
        min-height: 400px;
        padding: 40px 100px;

        .background-text {
          color: ${COLORS.frenchPass};
        }

        .title {
          font-size: 32px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: 0em;
          text-align: center;
          color: ${COLORS.denim};
          margin-top: 76px;
          z-index: 2;
          position: relative;
        }

        .ant-form {
          max-width: 896px;
          margin: auto;

          .description {
            font-size: 16px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: ${COLORS.black};
            margin-top: 75px;
          }

          .form-group {
            margin-top: 25px;

            .label {
              font-size: 18px;
              font-weight: 700;
              line-height: 32px;
              letter-spacing: 0em;
              text-align: left;
              color: ${COLORS.black};
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .required {
                background-color: ${COLORS.flushOrange};
                color: ${COLORS.white};
                padding: 1px 10px;
                font-size: 12px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 10px;
                height: 22px;

              }
            }

            input, textarea {
              min-height: 43px;
              border: 1px solid ${COLORS.silver4};
              border-radius: 6px;
            }
          }

          .policy-text {
            margin: 40px 0;
            padding: 0 10px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0em;
          }

          .submit-btn {
            margin: auto;
            padding: 25px;
            border-radius: 10px;
            background-color: ${COLORS.coral};
            width: 440px;
            font-size: 24px;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: center;
            color: ${COLORS.white};
            cursor: pointer;
            height: 80px;
            box-shadow: 0px 4px 4px 0px #00000040;
            border: none;
            margin-bottom: 40px;

            &:hover {
              background-color: ${COLORS.crusta};
              border: none;
            }

            &:disabled {
              background-color: ${COLORS.wildSand2};
              color: ${COLORS.dustyGray2};
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      color: ${COLORS.denim};
      padding: 13px 38px;
      background-color: ${COLORS.wildSand2};

      .company-info {
        display: flex;
        flex-flow: wrap;
        flex: auto;
        gap: 30px;

        .left-panel, center-panel {
          flex-flow: wrap;
        }

        .left-panel {
          display: flex;

          img {
            width: fit-content;
            height: 150px;
            margin-right: 20px;
          }

          .info {
            display: flex;
            flex-direction: column;
            padding: 20px 0px;

            .company-name {
              font-size: 22px;
              font-weight: 700;
              letter-spacing: 0em;
              text-align: left;
            }

            .address {
              margin-top: auto;
              font-size: 16px;
              font-weight: 400;
              line-height: 37px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
        }

        .center-panel {
          padding: 20px 0px;
          font-size: 16px;
          font-weight: 400;
          line-height: 29px;
          letter-spacing: 0em;
          text-align: left;
          flex: 1;
          min-width: fit-content;

          .phone-icon {
            margin-right: 5px;
          }

          .phone-text {
            font-size: 18px;
          }

          .phone-number {
            font-size: 44px;
            font-weight: 900;
            line-height: 50px;
            letter-spacing: 0em;
            text-decoration: none;
            color: ${COLORS.denim};
            pointer-events: none;
          }
        }

        .right-panel {
          display: flex;
          gap: 25px;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
          padding: 0px 0px;
          align-items: center;
          margin-left: auto;

          .item {
            cursor: pointer;
            color: ${COLORS.denim};
            padding-bottom: 5px;
          }
        }
      }
    }

    .thank-you {
      background-color: ${COLORS.selago};
      min-height: 400px;
      padding: 40px 100px;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: auto;
      flex: 1;

      .background-text {
        color: ${COLORS.frenchPass};
      }

      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: 0em;
        text-align: center;
        color: ${COLORS.denim};
        margin-top: 80px;
        z-index: 2;
        position: relative;
      }

      .thank-you-text {
        font-size: 36px;
        font-weight: 700;
        line-height: 52px;
        letter-spacing: 0em;
        text-align: center;
        margin: 50px 0 0 0;
      }

      .thank-you-image {
        img {
          margin: auto;
        }
      }

      .respond-text {
        margin: 70px auto;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 675px;
      }

      .back-btn {
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .privacy, .terms {
      background-color: ${COLORS.white};
      min-height: 400px;
      padding: 40px 40px;
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin: auto;
      max-width: 1160px;
      flex: 1;

      .background-text {
        color: ${COLORS.frenchPass};
        margin-left: 50px;
        margin-top: 45px;
      }

      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: 0em;
        text-align: center;
        color: ${COLORS.denim};
        z-index: 2;
        position: relative;
        margin-top: 100px;
        margin-bottom: 50px;
      }

      .ant-breadcrumb {
        .ant-breadcrumb-link, .ant-breadcrumb-separator, a {
          font-size: 12px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }

        .ant-breadcrumb-separator, a {
          color: ${COLORS.black};
        }

        .ant-breadcrumb-link {
          color: ${COLORS.denim};
        }
      }

      .privacy-content {
        z-index: 1;

        .sub-title {
          font-size: 36px;
          font-weight: 700;
          line-height: 52px;
          letter-spacing: 0em;
          text-align: center;
          margin-bottom: 30px;
        }

        .description {
          font-size: 15px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: center;
          margin-bottom: 30px;
        }

        .item {
          margin-bottom: 30px;

          .item-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 10px;
          }
          .item-content {
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
          }
        }

        .contact-info {
          font-size: 15px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: left;

          .company-name {
            font-size: 20px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }

    .terms {
      .title {
        margin-top: 100px;
        margin-bottom: 50px;
      }
      .background-text {
        white-space: pre;
        margin-top: 45px;
        margin-left: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center
      }

      .privacy-content {
        .description {
          text-align: justify;
        }
      }
    }

    @media (max-width: 767px) {
      .ant-layout-header {
        padding: 0px 18px;
        height: 60px;

        .header {
          .header-buttons {
            gap: 20px;

            .login-btn, .register-btn {
              display: none;
            }

            .contact-btn {
              margin-left: 0;
              font-size: 10px;
            }
          }
        }

        .menu-btn, .menu-sp {
          display: block;
        }
      }

      .ant-layout-content {
        .background-text {
          font-size: 30px;
          font-weight: 800;
          line-height: 45px;
          letter-spacing: 0em;
          white-space: break-spaces;
          left: unset;
        }

        .top-header {
          height: 300px;
          background: linear-gradient(90deg, #FFFFFF 24.63%, rgba(255, 255, 255, 0) 52.07%), url('${TopHeaderSPImage}');
          background-size: cover;
  
          .header-text {
            padding: 11px;

            span {
              display: block;
            }

            .group {
              .text-1 {
                font-size: 28px;
                font-weight: 300;
              }
              .text-2 {
                font-size: 26px;
                font-weight: 300;
              }
              .text-3 {
                font-size: 22px;
                font-weight: 300;
              }
              .text-4 {
                font-size: 22px;
                font-weight: 300;
              }
            }
          }
        }


        .usage-flow {
          padding: 40px 21px;
  
          .title {
            margin-top: 16px;
            font-size: 16px;
            line-height: 23px;
          }

          .register-btn-ref {
            font-size: 20px;
            font-weight: 600;
            line-height: 17px;
            padding: 16px;
            border-radius: 8px;
            margin: 40px 19px 0 19px;

            .text-small {
              font-size: 16px;
            }
          }

          .note {
            font-size: 20px;
            line-height: 30px;
            padding: 0px 19px;

            span {
              font-size: 24px;
              line-height: 36px;
            }
          }

          .steps {
            margin-top: 30px;
            padding: 0px 19px;

            .step-box {
              height: 360px;

              .step-circle {
                width: 40px;
                height: 40px;
                top: -20px;
                left: -20px;

                .step-circle-number {
                  font-size: 21px;
                  line-height: 21px;
                }
              }

              .step-image {
                min-height: 184px;
                height: 184px;

                img {
                  height: 184px;
                }
              }

              .step-title {
                font-size: 16px;
                line-height: 18px;
                min-height: 34px;
              }
            }
          }
        }

        .contact-form {
          padding: 40px 19px;

          .title {
            margin-top: 16px;
            font-size: 16px;
            line-height: 23px;
          }

          .ant-form {
            padding: 0px 20px;

            .description {
              margin-top: 20px;
              font-size: 16px;
              font-weight: 400;
              line-height: 29px;
              letter-spacing: 0em;
              text-align: justify;
            }

            .form-group {
              .label {
                font-size: 16px;
                line-height: 18px;
              }
            }

            .policy-text {
              font-size: 14px;
              line-height: 25px;
              text-align: left;
            }

            .submit-btn {
              font-size: 16px;
              font-weight: 600;
              line-height: 17px;
              margin-bottom: 0;
            }
          }
        }
      }

      .footer {
        display: block;
        padding: 0 0 30px;

        .company-info {
          flex-direction: column;
          gap: 0px;

          .left-panel, .center-panel {
            margin: auto;
            justify-content: center;
          }
          .left-panel {
            img {
              height: 110px;
              width: auto;
            }

            .info {
              padding: 18px 0px

              .company-name {
                font-size: 22px;
                font-weight: 700;
                line-height: 35px;
              }
  
              .address {
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
              }
            }
          }

          .center-panel {
            padding: 0;
            margin-bottom: 30px;

            .phone-icon {
              height: 15px;
            }

            .phone-text {
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              text-align: center;
            }

            .phone-number {
              font-size: 28px;
              font-weight: 900;
              line-height: 40px;
              letter-spacing: 0em;
              text-align: center;
              pointer-events: inherit;
            }

            .working-time {
              font-size: 12px;
              font-weight: 400;
              line-height: 19px;
              text-align: center;
            }
          }

          .right-panel {
            flex-direction: column;
            gap: 25px;
            font-size: 14px;
            text-align: left;
            align-items: flex-start;
            margin-left: unset;

            .item {
              cursor: pointer;
              padding-left: 28px;
              line-height: 14px;
              padding-bottom: 0;
            }
          }
        }
      }

      .thank-you {
        padding: 40px 19px;

        .title {
          margin-top: 16px;
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;
        }

        .thank-you-text {
          font-size: 20px;
          font-weight: 700;
          line-height: 29px;
          text-align: center;
        }

        .thank-you-image {
          img {
            margin: auto;
          }
        }

        .respond-text {
          margin: 40px 22px;
          font-size: 14px;
          font-weight: 400;
          line-height: 25px;
          text-align: left;
        }

        .back-btn {
          font-size: 14px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: center;
        }
      }

      .privacy, .terms {
        padding: 20px 20px;

        .title {
          margin-top: 33px;
          font-size: 16px;
          font-weight: 700;
          line-height: 23px;
          margin-bottom: 20px;
        }
        .background-text {
          margin-top: 40px;
          margin-left: 0;
          font-size: 30px;
          line-height: 45px;
        }
        .privacy-content {
          .sub-title {
            font-size: 22px;
            font-weight: 700;
            line-height: 32px;
          }

          .description {
            font-size: 14px;
          }

          .item {
            .item-title {
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;  
            }

            .item-content {
              font-size: 14px;
              font-weight: 400;
              line-height: 25px;
            }
          }

          .contact-info {
            font-size: 14px;
            line-height: 25px;

            .company-name {
              font-size: 16px;
              line-height: 28px;
              font-weight: 500;
            }
          }
        }
      }

      .terms {
        .title {
          margin-top: 78px;
        }
        .background-text {
          width: unset;
        }
      }
    }

    @media (max-width: 420px) {
      .ant-layout-header {
        padding: 18px 18px 18px 10px;
        .header {
          .logo {
            min-width: unset;
          }
        }
      }

      .ant-layout-content {
        .usage-flow {
          .steps {
            .step-box {
              width: 300px;
            }
          }
        }
      }
    }
  `,
};
